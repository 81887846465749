import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { DeliveryType, PagListResult } from 'src/models/types';

type DeliveryServicesParams = {
  id: string;
  country: string;
  name: string;
};

export const goDeliveryServicesSlice = createApi({
  reducerPath: 'goDelivery',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Delivery'],
  endpoints: (builder) => ({
    getDeliveryServices: builder.query<PagListResult<DeliveryType>, any>({
      query: (params: DeliveryServicesParams) => ({
        url: 'deliveryTypes',
        method: 'GET',
        params,
      }),
      providesTags: ['Delivery'],
    }),
  }),
});

export const { useGetDeliveryServicesQuery } = goDeliveryServicesSlice;
