import React from 'react';
import { Button } from '../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import cls from './SignInPage.module.scss';
import { useTranslation } from 'react-i18next';

const EndSessionPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'end-session-page' });
  const navigate = useNavigate();
  return (
    <div className={cls['end_session-container']}>
      <p>{t('title')}.</p>
      <p>{t('reasons-title')}:</p>
      <div className={cls['end_session-reasons']}>
        <span>{t('reason-1')}</span>
        <span>{t('reason-2')}</span>
        <span>{t('reason-3')}</span>
      </div>

      <Button onClick={() => navigate('/login')}>
        {t('login-to-crm')}
      </Button>
    </div>
  );
};

export default EndSessionPage;
