import {
  DatePicker,
  DateTimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as CalendarIcon } from 'src/assets/img/icons/calendar-icon.svg';
import React, { ReactElement, useState } from 'react';
import './DatepickerComponent.scss';
import dayjs from 'dayjs';
import { InputAdornment } from '@mui/material';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { useTranslation } from 'react-i18next';

export enum DatePickerTheme {
  ONLY_ICON = 'only-icon',
  TRANSPARENT = 'transparent',
  TRANSPARENT_LEFT_ICON = 'transparent-left-icon',
  FULL_BORDERED = 'full-bordered',
  FROM_TABLE = 'from-table',
}

export interface DatePickerComponentProps {
  theme?: DatePickerTheme;
  isDateTimePicker?: boolean;
  customIcon?: ReactElement;
  className?: string;
  placeholder?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  propsValue?: string;
  defaultValue?: any;
  onChange?: (val: string | null) => void;
  format?: string;
  isIconHidden?: boolean;
  maxDate?: string;
  minDate?: string;
  controlPicker?: {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export const DatePickerComponent = ({
  theme,
  isDateTimePicker,
  customIcon,
  className,
  placeholder,
  disableFuture,
  disablePast = false,
  propsValue,
  isIconHidden,
  onChange = (val: string | null) => {
    null;
  },
  defaultValue,
  format,
  controlPicker,
  maxDate,
  minDate,
}: DatePickerComponentProps) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const pickerFormat =
    format || (isDateTimePicker ? 'YYYY-MM-DD HH:mm' : 'DD.MM.YYYY');
  return (
    <div>
      {isDateTimePicker ? (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DateTimePicker
            ampm={false}
            format={pickerFormat}
            {...(theme === DatePickerTheme.FROM_TABLE
              ? {}
              : {
                  slotProps: {
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => setOpen(true)}
                          >
                            {customIcon !== undefined ? (
                              customIcon
                            ) : (
                              <CalendarIcon className="icon" />
                            )}
                          </InputAdornment>
                        ),
                      },
                    },
                  },
                })}
            className={`datepicker ${theme} ${className} ${isIconHidden && 'datepicker--hidden-icon'}`}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            value={
              propsValue
                ? dayjs(propsValue, pickerFormat)
                : defaultValue
                  ? dayjs(defaultValue, pickerFormat)
                  : null
            }
            label="Basic date picker"
            disableFuture={disableFuture}
            disablePast={disablePast}
            {...(maxDate ? { maxDate: dayjs(maxDate, pickerFormat) } : {})}
            {...(minDate ? { minDate: dayjs(minDate, pickerFormat) } : {})}
            onChange={(date) => {
              onChange?.(date?.format(pickerFormat) || null);
            }}
            {...(controlPicker
              ? controlPicker
              : {
                  open: open,
                  onOpen: () => setOpen(true),
                  onClose: () => setOpen(false),
                })}
          />
        </LocalizationProvider>
      ) : (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DatePicker
            className={`datepicker ${theme} ${className} ${
              placeholder && 'placeholder'
            } ${isIconHidden && 'datepicker--hidden-icon'}`}
            label={placeholder}
            format={pickerFormat}
            value={
              propsValue
                ? dayjs(propsValue, pickerFormat)
                : defaultValue
                  ? dayjs(defaultValue, pickerFormat)
                  : null
            }
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={(date) => {
              onChange?.(date?.format(pickerFormat) || null);
            }}
            {...(maxDate ? { maxDate: dayjs(maxDate, pickerFormat) } : {})}
            {...(minDate ? { minDate: dayjs(minDate, pickerFormat) } : {})}
            {...(theme === DatePickerTheme.FROM_TABLE
              ? {}
              : {
                  slotProps: {
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={() => setOpen(true)}
                          >
                            {customIcon !== undefined ? (
                              customIcon
                            ) : (
                              <CalendarIcon className="icon" />
                            )}
                          </InputAdornment>
                        ),
                      },
                    },
                  },
                })}
            {...(controlPicker
              ? controlPicker
              : {
                  open: open,
                  onOpen: () => setOpen(true),
                  onClose: () => setOpen(false),
                })}
          />
        </LocalizationProvider>
      )}
    </div>
  );
};
