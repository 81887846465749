import React, { useState } from 'react';
import { ReactComponent as LeftArrowIcon } from 'src/assets/img/icons/left-arrow-icon.svg';
import cls from './Sidebar.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import useNavigations, {
  NavigationItem,
  RouteItem,
} from 'src/hooks/routes/useNavigations';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { clearOperationData } from 'src/redux/slices/tableDataOperations';

const Sidebar = () => {
  const { navigations } = useNavigations();
  const [openedItems, setOpenedItems] = useState<number[]>([]);
  const toggleOpenedItems = (index: number) => {
    if (openedItems.includes(index)) {
      const openedItemsCopy = [...openedItems];
      openedItemsCopy.splice(
        openedItemsCopy.findIndex((item) => item === index),
        1
      );
      setOpenedItems(openedItemsCopy);
    } else {
      const openedItemsCopy = [...openedItems];
      openedItemsCopy.push(index);
      setOpenedItems(openedItemsCopy);
    }
  };
  // const routesCounters: { [key: string]: number } = {
  //   orders: 3,
  // };

  const dispatch = useTypedDispatch();
  const location = useLocation();

  const isActive = (path: string | string[]): boolean =>
    Array.isArray(path)
      ? path.some((p) => p === location.pathname)
      : path === location.pathname;

  const getActiveClass = (
    item: NavigationItem | RouteItem,
    className: string,
    index?: number
  ): string => {
    const paths =
      'routes' in item && item.routes
        ? item.routes.map((r: RouteItem) => r.path)
        : [item.path];
    const active =
      isActive(paths as string[]) || (index && openedItems.includes(index));
    return active ? cls[className] : '';
  };

  return (
    <div className={cls.sidebar}>
      {navigations.map((item, index) => (
        <div key={index} className={`${getActiveClass(item, 'active-page')}`}>
          {item.routes ? (
            item.disabled ? null : (
              <div
                className={`${cls['sidebar__item-wrapper']} ${getActiveClass(
                  item,
                  'active',
                  index
                )} ${item.className || ''}`}
              >
                <div
                  onClick={() => {
                    if (item.disabled) return;
                    toggleOpenedItems(index);
                  }}
                  key={index}
                  className={
                    cls[
                      item.disabled ? 'sidebar__item-disabled' : 'sidebar__item'
                    ]
                  }
                >
                  <div className={cls['sidebar__icon']}>{item.icon}</div>
                  <div className={cls['sidebar__title']}>
                    <span>{item.title}</span>
                    {/* {typeof item.id === 'string' && routesCounters[item.id] && (
                      <div className={cls['sidebar__counter']}>
                        {routesCounters[item.id]}
                      </div>
                    )} */}
                    <LeftArrowIcon
                      className={cls['sidebar__item-dropdown-arrow']}
                    />
                  </div>
                </div>
                <div className={`${cls['sidebar__item-dropdown']}`}>
                  {item.routes.map((route, routeIndex) =>
                    route.disabled ? null : (
                      <NavLink
                        to={route.disabled ? '' : route.path}
                        onClick={() => {
                          dispatch(clearOperationData());
                        }}
                        key={routeIndex}
                        className={`${
                          cls['sidebar__item-inner']
                        } ${getActiveClass(route, 'active-page-route')}`}
                      >
                        <div
                          className={
                            cls[route.disabled ? 'sidebar__title-disabled' : '']
                          }
                        >
                          {route.title}
                        </div>
                      </NavLink>
                    )
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              {item.disabled ? null : (
                <NavLink
                  to={item.disabled ? '' : (item.path as string)}
                  key={index}
                  className={`${cls['sidebar__item']} ${item.className || ''}`}
                >
                  <div className={cls['sidebar__icon']}>{item.icon}</div>
                  <div className={cls['sidebar__title']}>
                    <span>{item.title}</span>
                  </div>
                </NavLink>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
export default Sidebar;
