import React, { ReactNode } from 'react';
import cls from './Tooltip.module.scss';
import './Tooltip.scss';
import TooltipMui from '@mui/material/Tooltip';
import TooltipBody from 'src/components/common/TooltipBody';

export interface ITooltipProps {
  children?: ReactNode;
  title?: string;
  message?: string | ReactNode;
  isLeft?: boolean;
  className?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  dropdownClassName?: string;
  childrenClassName?: string;
  maxWidth?: number;
  customRef?: any;
  disabled?: boolean;
  open?: boolean;
}
const Tooltip = ({
  children,
  title,
  message,
  isLeft,
  className,
  dropdownClassName,
  childrenClassName,
  maxWidth,
  customRef,
  disabled,
  open,
  placement = 'bottom',
}: ITooltipProps) => (
  <TooltipMui
    placement={placement}
    open={open}
    PopperProps={{
      className: 'table-tooltip',
    }}
    title={
      (title || message) && <TooltipBody
        title={''}
        massage={
          <div
            className={`${cls['tooltip__dropdown']} tooltip__dropdown ${
              isLeft && cls['tooltip__dropdown--left']
            } ${dropdownClassName}`}
            style={maxWidth ? { maxWidth: maxWidth + 'px' } : {}}
          >
            {title && <div className={cls['tooltip__title']}>{title}</div>}
            {message && (
              <div className={cls['tooltip__message']}>{message}</div>
            )}
          </div>
        }
      />
    }
  >
    <div
      ref={customRef}
      className={`${
        cls['tooltip__wrapper']
      } tooltip__wrapper ${className} tooltip ${
        disabled && 'toolpit--disabled'
      }`}
    >
      <div
        className={`${cls['tooltip__children']} tooltip__children ${childrenClassName}`}
      >
        {children}
      </div>
    </div>
  </TooltipMui>
);

export default Tooltip;
