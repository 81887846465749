import React from 'react';

import CrmLoader from '../CRMLoader';
import cls from './PageDataLoader.module.scss'
const PageDataLoader = () => {

  return (
    <div className={cls['table-loading-wrapper']}>
      <div>
        <CrmLoader />
      </div>
    </div>
  );
};
export default PageDataLoader
