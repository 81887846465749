import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';

import {
  IRequestCreateConsigment,
  IRequestUpdateConsigment,
} from 'src/models/arrival';

export const arrivalApi = createApi({
  reducerPath: 'arrival',
  baseQuery,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    createConsigment: builder.mutation<any, IRequestCreateConsigment>({
      query: ({ body }) => ({
        url: '/consignments/',
        body,
        method: 'POST',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
    }),
    updateConsigment: builder.mutation<any, IRequestUpdateConsigment>({
      query: ({ id, body }) => ({
        url: `/consignments/${id}`,
        body,
        method: 'PUT',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
    }),
    deleteArrival: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/consignments/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
    }),
  }),
});

export const {
  useCreateConsigmentMutation,
  useUpdateConsigmentMutation,
  useDeleteArrivalMutation,
} = arrivalApi;
