import React, { useState } from 'react';
import cls from './HomePage.module.scss';
import { ReactComponent as UserGroupIcon } from 'src/assets/img/icons/user-group-icon.svg';
import { ReactComponent as UserOnlineIcon } from 'src/assets/img/icons/user-online-icon.svg';
import { ReactComponent as PowerBlackIcon } from 'src/assets/img/icons/power-black-icon.svg';
import { ReactComponent as DesktopIcon } from 'src/assets/img/icons/desktop.svg';
import { ReactComponent as PowerGrayIcon } from 'src/assets/img/icons/power-gray-icon.svg';
import TariffsPopup from './components/TariffsPopup/TariffsPopup';
import { useGetUsersQuery } from 'src/services/users/goUsers';
import banner from 'src/assets/img/homepage-banner.gif';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Pagination from 'src/components/Parts/Pagination/Pagination';
import showPaginationCheck from 'src/utils/helpers/showPaginationCheck';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';

const HomePage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });
  const { params, setParams } = useSearchQueryParams({});
  const { data } = useGetUsersQuery(params);

  const [showTarrifsPopup, setShowTarrifsPopup] = useState<boolean>(false);
  const isShowPagination = data?.meta && showPaginationCheck(data.meta);

  const onlineUsersAmount = data?.data.filter((user) => user.is_online).length;
  const offlineUsersAmount = data?.data.filter(
    (user) => !user.is_online
  ).length;

  return (
    <>
      <div className={cls.banner}>
        <img
          alt="discount banner"
          onClick={() => {
            setShowTarrifsPopup(true);
          }}
          src={banner}
        />
      </div>
      <div className={cls['wrapper']}>
        <div className="header-line">
          <div
            className={`header-line__divider ${cls['header-line__divider']}`}
          />
          <div className="header-line__data">
            <span>{t('users')} </span>
            <span className="ml-5">{'('}</span>
            <UserGroupIcon className="icon" />
            <span
              className={cls['header__data-text']}
            >{`${data?.data?.length})`}</span>
            <UserOnlineIcon className="icon" />
            <span className="text-success">{onlineUsersAmount}</span>
            <PowerBlackIcon className="icon ml-10" />
            <span className="text-gray">{offlineUsersAmount}</span>
          </div>
        </div>
        {data?.data.map((item, index) => {
          const isUserOnline = item?.is_online;

          return (
            <div key={index} className={cls['data-wrapper']}>
              <div className={cls['data-item']}>
                <div className={cls['column-1']}>
                  <div className="align-center">
                    {isUserOnline ? (
                      <UserOnlineIcon className="icon" />
                    ) : (
                      <PowerGrayIcon className="icon" />
                    )}
                    <span className={isUserOnline ? cls['text-colored'] : ''}>
                      {item.full_name}
                      <span className="text-gray ml-5">({item?.role})</span>
                    </span>
                  </div>
                </div>
                <div className={cls['column-2']}>
                  {item?.ip_address && (
                    <>
                      {isUserOnline ? (
                        <>
                          <DesktopIcon className="icon" />
                          <span className="fz-12">{item?.ip_address}</span>
                        </>
                      ) : (
                        <span className="fz-12 text-gray">
                          - {t('offline')} -
                        </span>
                      )}
                    </>
                  )}

                  {item?.country && (
                    <ReactCountryFlag countryCode={item?.country?.iso2} svg />
                  )}
                </div>
                <div className={`${cls['column-3']} fz-11`}>
                  {item?.last_online}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <TariffsPopup
        show={showTarrifsPopup}
        close={() => {
          setShowTarrifsPopup(false);
        }}
      />
      {isShowPagination && (
        <Pagination params={params} setParams={setParams} meta={data.meta} />
      )}
    </>
  );
};
export default HomePage;
