import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import * as I from '../../models/providers';

// import { Providers } from '../../models/types';

export const providersApi = createApi({
  reducerPath: 'providersApi',
  baseQuery: baseQuery,
  tagTypes: ['Provider'],
  endpoints: (builder) => ({
    getProvidersList: builder.query<I.IProvidersListResponse, any>({
      query: (params) => ({ url: '/providers', params }),
      transformResponse: (
        data: I.IProvidersListResponse,
        meta: FetchBaseQueryMeta
      ) => ({
        data: data.data.map((item, index) => ({
          ...item,
          // id: item.id.toString(),
        })),
      }),
      providesTags: ['Provider'],
    }),
    createProvider: builder.mutation<any, any>({
      query: (body) => ({
        url: '/providers/',
        body,
        method: 'POST',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['Provider'],
    }),
    updateProvider: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/providers/${id}`,
        body,
        method: 'PUT',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['Provider'],
    }),
    deleteProvider: builder.mutation({
      query: (body) => {
        return {
          url: '/providers/delete',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['Provider'],
    }),
    // deleteProvider: builder.mutation<any, { id: number }>({
    //   query: ({ id }) => ({
    //     url: `/providers/${id}`,
    //     method: 'DELETE',
    //   }),
    //   transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
    //     return data;
    //   },
    // }),
  }),
});

export const {
  useGetProvidersListQuery,
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
} = providersApi;
