import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';

export const notificationsSlice = createApi({
  reducerPath: 'notifications',
  baseQuery: baseQuery,
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    postNotification: builder.mutation({
      query: (params) => ({
        url: 'notifications',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Notifications'],
    }),
    postNotificationExtend: builder.mutation({
      query: (params) => ({
        url: `notifications/${params.id}/minutes`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Notifications'],
    }),
    postNotificationDone: builder.mutation({
      query: (params) => ({
        url: `notifications/${params.id}/done`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Notifications'],
    }),
    updateNotification: builder.mutation({
      query: ({ id, body }) => ({
        url: `notifications/${id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
    deleteNotification: builder.mutation({
      query: (id) => ({
        url: 'notifications/' + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useDeleteNotificationMutation,
  usePostNotificationMutation,
  useUpdateNotificationMutation,
  usePostNotificationExtendMutation,
  usePostNotificationDoneMutation,
} = notificationsSlice;
