import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPointIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      d="M7.333 7.933v3.4a.667.667 0 1 0 1.334 0v-3.4a3.333 3.333 0 1 0-1.334 0M8 2.667a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.807 6.946a.681.681 0 0 0-.28 1.334c1.513.3 2.14.84 2.14 1.053 0 .387-1.634 1.333-4.667 1.333S3.333 12.387 3.333 12c0-.213.627-.753 2.14-1.08a.681.681 0 0 0-.28-1.333C3.167 10.053 2 10.927 2 12c0 1.753 3.02 2.667 6 2.667s6-.914 6-2.667c0-1.073-1.167-1.947-3.193-2.387"
    />
  </svg>
);
export default SvgMapPointIcon;
