import { useEffect } from 'react';
import { toast } from 'react-toastify';

import useSounds from './useSounds';
import { useTypedSelector } from './useTypedSelector';
import selectors from '../redux/selectors';

const useNotificationSound = () => {
  const { playErrorSound, playInfoSound, playWarningSound } = useSounds();
  const userData = useTypedSelector(selectors.getUser);

  useEffect(() => {
    const userSounds = userData?.sounds;

    const originalToastError = toast.error;
    const originalToastInfo = toast.info;
    const originalToastWarning = toast.warning;
    if (userSounds) {
      toast.error = (msg, options) => {
        void playErrorSound();
        return originalToastError(msg, options);
      };

      toast.info = (msg, options) => {
        void playInfoSound();
        return originalToastInfo(msg, options);
      };

      toast.warning = (msg, options) => {
        void playWarningSound();
        return originalToastWarning(msg, options);
      };
    }

    return () => {
      toast.error = originalToastError;
      toast.info = originalToastInfo;
      toast.warning = originalToastWarning;
    };
  }, [userData]);
};

export default useNotificationSound;
