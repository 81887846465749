import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import {
  ApiToken,
  ApiTokenParams,
  SystemsSettings,
} from 'src/models/settings/systems';
import { ListResult } from 'src/models/types';

export const systemsSlice = createApi({
  reducerPath: 'systems',
  baseQuery: baseQuery,
  tagTypes: ['ApiTokens'],

  endpoints: (builder) => ({
    getApiTokens: builder.query<ListResult<ApiToken>, unknown>({
      query: () => 'crm/token',
      providesTags: ['ApiTokens'],
    }),

    postApiToken: builder.mutation<ApiTokenParams, any>({
      query: ({ body }) => ({
        url: 'crm/token',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ApiTokens'],
    }),
    generateApiToken: builder.mutation<{ data: string }, any>({
      query: () => ({
        url: 'crm/token/generate',
        method: 'GET',
      }),
    }),
    deleteApiKey: builder.mutation<unknown, number>({
      query: (id) => ({
        url: `crm/token/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ApiTokens'],
    }),
    updateApiKey: builder.mutation<any, { id: number; body: ApiTokenParams }>({
      query: ({ id, body }) => ({
        url: `crm/token/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ApiTokens'],
    }),
    updateSystems: builder.mutation<FormData, unknown>({
      query: (body) => ({
        url: 'crm',
        method: 'POST',
        body,
      }),
    }),

    getSystems: builder.query<SystemsSettings, unknown>({
      query: () => 'crm',
    }),
  }),
});

export const {
  useGetApiTokensQuery,
  useLazyGetApiTokensQuery,
  usePostApiTokenMutation,
  useUpdateSystemsMutation,
  useDeleteApiKeyMutation,
  useGetSystemsQuery,
  useUpdateApiKeyMutation,
  useGenerateApiTokenMutation,
} = systemsSlice;
