import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHeadBranchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={22}
    fill="none"
    viewBox="0 0 19 22"
    {...props}
  >
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="M7.044 20.318H1.567A.567.567 0 0 1 1 19.75v-3.589c0-.313.254-.566.567-.566h5.477c.313 0 .567.253.567.566v3.589a.567.567 0 0 1-.567.567ZM12.24 6.152H6.762a.567.567 0 0 1-.567-.567V1.996c0-.312.254-.566.567-.566h5.478c.313 0 .566.254.566.566v3.59a.567.567 0 0 1-.566.566ZM17.435 20.318h-5.478a.567.567 0 0 1-.566-.567v-3.589c0-.313.253-.566.566-.566h5.478c.313 0 .567.253.567.566v3.589a.567.567 0 0 1-.567.567Z"
    />
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="M4.305 15.596V12.29c0-1.043.845-1.888 1.889-1.888h6.61c1.044 0 1.89.845 1.89 1.888v3.306M9.5 10.402v-4.25"
    />
  </svg>
);
export default SvgHeadBranchIcon;
