import React, { FC, ReactNode } from 'react';
import MuiModal from '@mui/material/Modal';
import cls from './Modal.module.scss';
import { ModalProps as MuiModalProps } from '@mui/material/Modal/Modal';
import CloseModalIcon from 'src/assets/icons/mathSigns/CloseModalIcon';

export interface ModalProps {
  close: () => void;
  open: boolean;
}
interface Props extends ModalProps {
  children: ReactNode;
  width: string;
  title?: string;
  hideCloseIcon?: boolean;
}

const Modal: FC<Props & MuiModalProps> = ({
  open,
  close,
  children,
  title,
  width,
  hideCloseIcon,
}) => {
  const onClose = (event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };
  return (
    <MuiModal open={open} onClose={onClose}>
      <div
        className={cls['modal__wrapper']}
        style={{
          width,
          maxWidth: '100%',
        }}
        data-disable-enter-handler={open ? 1 : 0}
      >
        {title && <div className={cls['modal__header']}>{title}</div>}
        {!hideCloseIcon && (
          <CloseModalIcon
            className={cls['modal__close-icon']}
            onClick={close}
          />
        )}
        <div className={cls['modal__body']}>{children}</div>
      </div>
    </MuiModal>
  );
};
export default Modal;
