import React, { useState } from 'react';

import { ITableDataItem, IThConfig } from '../Table';
import TableTooltip from './TableTooltip';
import {getWidthStyles} from "../helpers";
interface IProps {
  tableDataItem: ITableDataItem;
  thConfig: IThConfig;
  thConfigIndex: number;
}
const TableDataElement = ({
  tableDataItem,
  thConfig,
  thConfigIndex,
}: IProps) => {
  // if (!tableDataItem.elements[thConfig?.columnId]?.element) {
  //   return null;
  // }

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleMouseEnter = () => setIsTooltipOpen(true);

  const handleMouseLeave = () => setIsTooltipOpen(false);
  
  const findedData = tableDataItem.elements[thConfig.columnId];
  let element = null;
  if(findedData?.element){
    element = findedData.element
  }else if(findedData?.text){
    element = findedData.text
  }
  const widthStyles = getWidthStyles(thConfig);
  return (
    <td
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={thConfig.columnId}
      style={widthStyles}
      {...(findedData?.className?{className:findedData.className}:{})}
    >
      {element}
      {isTooltipOpen && !findedData?.disableComputedTooltip && <TableTooltip
        message={element}
        cellData={findedData}
      />}
    </td>
  );
};

export default TableDataElement;
