import React from 'react';
import { Popup } from 'src/components/UI/Popup/Popup';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import cls from './ConfirmDeletePopup.module.scss';
import { ReactComponent as DeleteIcon } from 'src/assets/img/icons/confirm-delete-icon.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  close: () => void;
  onConfirm: () => void;
}

const ConfirmDeletePopup: React.FC<Props> = ({ show, close, onConfirm }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'confirm-delete-notification' });
  const { t:tg } = useTranslation('translation');

  return (
    <Popup
      className={cls['confirm-delete-popup']}
      show={show}
      close={close}
      title={t('title')}
      width="394px"
    >
      <div className={cls['content']}>
        <div className={cls['wrapper-delete-icon']}>
          <DeleteIcon />
        </div>

        <div className={cls['wrapper-text']}>
          {t('text')}
        </div>
      </div>

      <div className={cls['wrapper-action']}>
        <div className={cls['action']}>
          <Button
            onClick={onConfirm}
            className={cls['ok-btn']}
            theme={ButtonTheme.PRIMARY}
          >
            ОК
          </Button>
          <Button
            onClick={close}
            className={cls['cancel-btn']}
            theme={ButtonTheme.LINK}
          >
            {tg('cancel')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmDeletePopup;
