import { createApi } from '@reduxjs/toolkit/query/react';
import {ListResult, PagListResult} from 'src/models/types';
import Module, {CounterpartyListItem, CounterPartyUP} from 'src/models/settings/module';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';

export const goModulesSlice = createApi({
  reducerPath: 'goModules',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: [ 'NPModule', 'UPModule'],
  endpoints: (builder) => ({
    getNPModule: builder.query<PagListResult<Module>, any>({
      query: (params) => ({ url: 'novaPoshtaSettings', params }),
      providesTags: ['NPModule'],
    }),
    getUPModule: builder.query<PagListResult<CounterPartyUP>, any>({
      query: (params) => ({ url: 'ukrPoshtaSettings/', params }),
      providesTags: ['UPModule'],
    }),
    getCounterpartiesAll: builder.query<ListResult<CounterpartyListItem>, any>({
      query: (params) => ({ url: 'orders/contragents/all', params }),
    }),
  }),
});
export const { useGetNPModuleQuery, useGetUPModuleQuery, useGetCounterpartiesAllQuery } = goModulesSlice;
