import React from 'react';
import cls from './CreateNotificationPopup.module.scss';
import { useTranslation } from 'react-i18next';
import { IPopupPropsExport } from 'src/components/UI/Popup/Popup';
import { Input, InputType } from 'src/components/UI/Input/Input';
import {
  usePostNotificationMutation,
  useUpdateNotificationMutation,
} from 'src/services/notification';
import { Controller, useForm } from 'react-hook-form';
import { Notification } from 'src/models/notifications';
import {
  DatePickerComponent,
  DatePickerTheme,
} from 'src/components/UI/DatePickerComponent/DatePickerComponent';
import {
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from 'src/components/UI/Select/Select';
import { toast } from 'react-toastify';
import useNotificationsTranslate from 'src/hooks/useNotificationsTranslate';
import {
  useCreateNotificationEventMutation,
  useUpdateNotificationEventMutation,
} from 'src/services/pytonSSE';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import {
  onNotificationCreate,
  onNotificationUpdate,
} from 'src/redux/slices/notificationsSlice';
import CrmLoader from '../../UI/Loaders/CRMLoader';
import Modal from 'src/UI/Modal/Modal';
import useOrderCredentials from 'src/pages/CreateOrderPage/hooks/useOrderCredentials';
import IdListIcon from 'src/assets/icons/lists/IdListIcon';
import TabletMinusIcon from 'src/assets/icons/lists/TabletMinusIcon';
import HandsetIcon from 'src/assets/icons/tools/HandsetIcon';
import UserIcon from 'src/assets/icons/scins/UserIcon';
import ChatIcon from 'src/assets/icons/massages/ChatIcon';

export interface IProp extends IPopupPropsExport {
  currentItem: Notification | null;
}

const CreateNotificationPopup = ({ show, close, currentItem }: IProp) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-notification-popup',
  });
  const { orderId } = useOrderCredentials();
  const { notificationsList, notificationsTargetList } =
    useNotificationsTranslate();
  const userLogin = useTypedSelector(selectors.getUserName);
  const dispatch = useTypedDispatch();
  const isEdit = !!currentItem;
  const currentOrderId = currentItem?.order_id || orderId;
  const { register, handleSubmit, setValue, reset, watch, control } = useForm({
    defaultValues: {
      date_time: currentItem?.date_time,
      type: currentItem?.type,
      target: currentItem?.target,
      description:
        currentOrderId && !currentItem?.id
          ? `${t('order-id')}: ${currentOrderId}` +
            `${currentItem?.description || ''}`
          : currentItem?.description,
      order_id: currentOrderId,
    },
  });

  const watchAllFields = watch();

  const [postNotification, { isLoading }] = usePostNotificationMutation();
  const [postEvent] = useCreateNotificationEventMutation();
  const [updateNotification, { isLoading: isUpdateLoading }] =
    useUpdateNotificationMutation();
  const [updateEvent] = useUpdateNotificationEventMutation();

  const onSubmit = (data: any) => {
    if (!watchAllFields.date_time) {
      toast.error(t('date-field-required'));
      return;
    }
    const body = {
      ...data,
      type: data.type || notificationsList[0].value,
      target: data.target || notificationsTargetList[0].value,
    };
    if (isEdit) {
      updateNotification({ id: currentItem?.id, body })
        .unwrap()
        .then((res) => {
          updateEvent({
            notification_id: currentItem?.id,
            newUserAgentLogin: userLogin,
          });
          dispatch(onNotificationUpdate(res.data));
          toast.success(t('update-success-message'));
          reset();
          close();
        })
        .catch((e) => toast.error(e?.data?.message || 'error'));
    } else {
      postNotification(body)
        .unwrap()
        .then((res) => {
          postEvent({
            notification_id: res.data.id,
            newUserAgentLogin: userLogin,
          });
          dispatch(onNotificationCreate(res.data));
          toast.success(t('create-success-message'));
          reset();
          close();
        })
        .catch((e) => toast.error(e?.data?.message || 'error'));
    }
  };

  return (
    <Modal
      open={show}
      close={close}
      title={isEdit ? t('title-update') : t('title-create')}
      width="400px"
    >
      <form className={'column gap-10'} onSubmit={handleSubmit(onSubmit)}>
        {currentOrderId ? (
          <div className={cls['form-item']}>
            <span>
              {t('order-id')} <IdListIcon />
            </span>
            <Input readOnly register={register('order_id')} />
          </div>
        ) : null}

        <div
          style={{ gridTemplateColumns: '1.1fr 2.5fr' }}
          className={cls['form-item']}
        >
          <span>{t('completion-date')}</span>
          <Controller
            render={({ field }) => {
              return (
                <DatePickerComponent
                  isDateTimePicker
                  theme={DatePickerTheme.TRANSPARENT}
                  onChange={(value) => {
                    if (value) {
                      setValue('date_time', value);
                      field.onChange(value);
                    }
                  }}
                  propsValue={watchAllFields['date_time']}
                  customIcon={<TabletMinusIcon />}
                />
              );
            }}
            control={control}
            name="date_time"
          />
        </div>

        <div className={cls['form-item']}>
          <span>
            {t('type')} <HandsetIcon width={18} />
          </span>
          <Select
            setValueInForm={setValue}
            register={register('type')}
            controllerTheme={SelectControllerTheme.TRANSPARENT}
            dropdownTheme={SelectDropdownTheme.TOP_UNBORDERED}
            propsValue={watchAllFields.type}
            disablePortal={false}
            defaultValue={notificationsList[0].value}
            options={notificationsList.map(({ value, text }) => ({
              title: text,
              value,
            }))}
          />
        </div>

        <div className={cls['form-item']}>
          <span>
            {t('for')} <UserIcon />
          </span>
          <Select
            setValueInForm={setValue}
            register={register('target')}
            controllerTheme={SelectControllerTheme.TRANSPARENT}
            dropdownTheme={SelectDropdownTheme.TOP_UNBORDERED}
            propsValue={watchAllFields.target}
            disablePortal={false}
            defaultValue={notificationsTargetList[0].value}
            options={notificationsTargetList.map(({ value, text }) => ({
              title: text,
              value,
            }))}
          />
        </div>

        <div style={{ alignItems: 'start', marginTop:'10px' }} className={cls['form-item']}>
          <span>
            {t('description')} <ChatIcon />
          </span>
          <Controller
            render={({ field }) => (
              <Input {...field} type={InputType.TEXTAREA} />
            )}
            control={control}
            name="description"
          />
        </div>
        <div className=" mx-a ">
          {isLoading || isUpdateLoading ? (
            <CrmLoader size={30} />
          ) : (
            <button className={'submit-button'}>
              {isEdit ? t('button-update') : t('button-create')}
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};
export default CreateNotificationPopup;
