import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '50px' }}>
          <h1>Sorry.. there was an error</h1>
          <p style={{ marginTop: '50px' }}>
            Пожалуйста{' '}
            <button
              style={{ color: 'blue' }}
              onClick={() => window.location.reload()}
            >
              перезагрузите страницу
            </button>{' '}
            или вернитесь{' '}
            <button
              style={{ color: 'blue' }}
              onClick={() => window.history.back()}
            >
              назад
            </button>
          </p>
          <p style={{ marginTop: '50px' }}>{this.state.error?.message}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
