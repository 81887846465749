import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from '../../utils/baseQuery';
import { PagListResult } from '../../models/types';
import * as I from 'src/models/settings/histories';

export const goHistoriesSlice = createApi({
  reducerPath: 'goHistories',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getHistories: builder.query<PagListResult<I.IHistoryItem>, any>({
      query: (params) => ({
        url: 'histories',
        params,
      }),
    }),
    getActionMethods: builder.query<I.ActionMethodResponse, any>({
      query: (params) => ({
        url: 'histories/types',
        params,
      }),
    }),
    getOrderHistory: builder.query<PagListResult<I.IHistoryItem>, any>({
      query: ({ id, ...params }) => ({
        url: `histories/${id}/order`,
        params,
      }),
    }),
  }),
});

export const {
  useGetHistoriesQuery,
  useGetActionMethodsQuery,
  useGetOrderHistoryQuery,
} = goHistoriesSlice;
