import { createSlice } from '@reduxjs/toolkit';
import { Product } from '../../models/products';
import { Order } from '../../models/orders/orders';
import { Attribute } from '../../models/attributes';

type orderProductSliceActionTypes = {
  payload: {
    product: Product;
    local_id: string;
    count: number;
    price: number;
    attributes: Array<Attribute>;
    totalPrice: number;
    discount: {
      discountType: string;
      discountSum: number;
      discountPercent: number;
    };
  };
  type: string;
};
export interface ProductListItem {
  product: Product;
  local_id: string;
  count: number;
  price: number;
  attributes: Array<Attribute>;
  totalPrice: number;
  discount: {
    discountType: string;
    discountSum: number;
    discountPercent: number;
  };
}
export interface ProductItem {
  list: Array<ProductListItem>;
  totalCount: number;
  totalSum: number;
}
export interface OrderProduct {
  products: ProductItem;
  additionalProducts: ProductItem;
}

const initialState: OrderProduct = {
  products: { list: [], totalCount: 0, totalSum: 0 },
  additionalProducts: { list: [], totalCount: 0, totalSum: 0 },
};

export const orderProductSlice = createSlice({
  name: 'orderProduct',
  initialState,
  reducers: {
    addProducts(state: OrderProduct, action: { type: string; payload: Order }) {
      state.products.list =
        action.payload.main_products?.map(
          (item) =>
            ({
              product: item,
              local_id: item.local_id,
              count: item.amount,
              price: item.price,
              attributes: item.attributes || [],
              totalPrice: item.discount_percent
                ? item.amount * Number(item.price) -
                  item.amount *
                    Number(item.price) *
                    (Number(item.discount_percent) / 100)
                : item.amount * Number(item.price) -
                  item.amount * Number(item.discount_sum),
              discount: {
                discountType: !item?.discount_sum ? 'percent' : 'UAH',
                discountSum: item.discount_sum,
                discountPercent: item.discount_percent,
              },
            }) as any
        ) || [];
      state.products.totalSum = state.products.list.reduce((acc, item) => {
        return acc + item.totalPrice;
      }, 0);
      state.products.totalCount = state.products.list.reduce((acc, item) => {
        return acc + item.count;
      }, 0);
      state.additionalProducts.list =
        action.payload.cross_products?.map(
          (item) =>
            ({
              product: item,
              local_id: item.local_id,
              count: item.amount,
              price: item.price,
              attributes: item.attributes || [],
              totalPrice: item.discount_percent
                ? item.amount * Number(item.price) -
                  item.amount *
                    Number(item.price) *
                    (Number(item.discount_percent) / 100)
                : item.amount * Number(item.price) -
                  item.amount * Number(item.discount_sum),
              discount: {
                discountType: !item?.discount_sum ? 'percent' : 'UAH',
                discountSum: item.discount_sum,
                discountPercent: item.discount_percent,
              },
            }) as any
        ) || [];
      state.additionalProducts.totalSum = state.additionalProducts.list.reduce(
        (acc, item) => {
          return acc + item.totalPrice;
        },
        0
      );
      state.additionalProducts.totalCount =
        state.additionalProducts.list.reduce((acc, item) => {
          return acc + item.count;
        }, 0);
      return state;
    },

    addToOrder(state: OrderProduct, action: orderProductSliceActionTypes) {
      const product = action.payload;
      const newTotalPrice = product.discount.discountPercent
        ? product.count * Number(product.price) -
          product.count *
            Number(product.price) *
            (Number(product.discount.discountPercent) / 100)
        : product.count * Number(product.price) -
          product.count * Number(product.discount.discountSum);
      // const item = state?.products.list.find(
      //   (item) => item.product.id === action.payload.product.id
      // );
      // if (item?.product.id) return state;
      return {
        ...state,
        products: state.products.list.length
          ? {
              list: [
                ...state.products.list,
                {
                  ...product,
                  totalPrice: Number(product.totalPrice.toFixed(2)),
                },
              ],
              totalCount: state.products.totalCount + product.count,
              totalSum: state.products.totalSum + newTotalPrice,
            }
          : {
              list: [
                {
                  ...product,
                  totalPrice: Number(product.totalPrice.toFixed(2)),
                },
              ],
              totalCount: state.products.totalCount + product.count,
              totalSum: state.products.totalSum + newTotalPrice,
            },
      };
    },
    addAdditionalToOrder(
      state: OrderProduct,
      action: orderProductSliceActionTypes
    ) {
      const product = action.payload;
      const newTotalPrice = product.discount.discountPercent
        ? product.count * Number(product.price) -
          product.count *
            Number(product.price) *
            (Number(product.discount.discountPercent) / 100)
        : product.count * Number(product.price) -
          product.count * Number(product.discount.discountSum);
      // const item = state?.additionalProducts.list.find(
      //   (item) => item.product.id === action.payload.product.id
      // );
      // if (item?.product.id) return state;
      return {
        ...state,
        additionalProducts: state.additionalProducts.list.length
          ? {
              list: [
                ...state.additionalProducts.list,
                {
                  ...product,
                  totalPrice: Number(product.totalPrice.toFixed(2)),
                },
              ],
              totalCount: state.additionalProducts.totalCount + product.count,
              totalSum: state.additionalProducts.totalSum + newTotalPrice,
            }
          : {
              list: [
                {
                  ...product,
                  totalPrice: Number(product.totalPrice.toFixed(2)),
                },
              ],
              totalCount: state.additionalProducts.totalCount + product.count,
              totalSum: state.additionalProducts.totalSum + newTotalPrice,
            },
      };
    },
    cleanAdditionalProducts(state: OrderProduct) {
      return {
        ...state,
        additionalProducts: { list: [], totalCount: 0, totalSum: 0 },
      };
    },
    changePrice: (
      state: OrderProduct,
      action: {
        type: string;
        payload: { isMain: boolean; value: string | number; id: string };
      }
    ) => {
      const newPrice = Number(action.payload.value);
      (action.payload.isMain
        ? state.products.list
        : state.additionalProducts.list
      ).find((item, i) => {
        const currentProduct = action.payload.isMain
          ? state.products.list[i]
          : state.additionalProducts.list[i];
        const isPercent = currentProduct.discount.discountType === 'percent';
        const currentTotalPrice =
          currentProduct.discount.discountSum ||
          currentProduct.discount.discountPercent
            ? isPercent
              ? currentProduct.count * newPrice -
                currentProduct.count *
                  newPrice *
                  (Number(currentProduct.discount.discountPercent || 0) / 100)
              : currentProduct.count * newPrice -
                Number(currentProduct.discount.discountSum || 0) *
                  currentProduct.count
            : currentProduct.count * newPrice;

        const newTotalSum = action.payload.isMain
          ? state.products.totalSum -
            currentProduct.totalPrice +
            currentTotalPrice
          : state.additionalProducts.totalSum -
            currentProduct.totalPrice +
            currentTotalPrice;

        if (item.local_id === action.payload.id) {
          action.payload.isMain
            ? ((state.products.list[i].price = newPrice),
              (state.products.list[i].totalPrice = Number(
                currentTotalPrice.toFixed(2)
              )),
              (state.products.totalSum = Number(newTotalSum.toFixed(2))))
            : ((state.additionalProducts.list[i].price = Number(
                action.payload.value
              )),
              (state.additionalProducts.list[i].totalPrice = Number(
                currentTotalPrice.toFixed(2)
              )),
              (state.additionalProducts.totalSum = Number(
                newTotalSum.toFixed(2)
              )));

          return state;
        }
      });
    },
    changeCount: (
      state: OrderProduct,
      action: {
        type: string;
        payload: { isMain: boolean; value: string | number; id: string };
      }
    ) => {
      const newCount = Number(action.payload.value);
      (action.payload.isMain
        ? state.products.list
        : state.additionalProducts.list
      ).find((item, i) => {
        const currentProduct = action.payload.isMain
          ? state.products.list[i]
          : state.additionalProducts.list[i];
        const isPercent = currentProduct.discount.discountType === 'percent';
        const currentTotalPrice =
          currentProduct.discount.discountSum ||
          currentProduct.discount.discountPercent
            ? isPercent
              ? currentProduct.price * newCount -
                currentProduct.price *
                  newCount *
                  (Number(currentProduct.discount.discountPercent || 0) / 100)
              : currentProduct.price * newCount -
                Number(currentProduct.discount.discountSum || 0) * newCount
            : currentProduct.price * newCount;

        const newTotalSum = action.payload.isMain
          ? state.products.totalSum -
            currentProduct.totalPrice +
            currentTotalPrice
          : state.additionalProducts.totalSum -
            state.additionalProducts.list[i].totalPrice +
            currentTotalPrice;

        if (item.local_id === action.payload.id) {
          action.payload.isMain
            ? ((state.products.list[i].count = newCount),
              (state.products.list[i].totalPrice = Number(
                currentTotalPrice.toFixed(2)
              )),
              (state.products.totalSum = Number(newTotalSum.toFixed(2))))
            : ((state.additionalProducts.list[i].count = newCount),
              (state.additionalProducts.list[i].totalPrice = Number(
                currentTotalPrice.toFixed(2)
              )),
              (state.additionalProducts.totalSum = Number(
                newTotalSum.toFixed(2)
              )));

          return state;
        }
      });
    },
    addDiscount: (
      state: OrderProduct,
      action: {
        type: string;
        payload: {
          isMain: boolean;
          discount: string | number;
          id: string;
        };
      }
    ) => {
      (action.payload.isMain
        ? state.products.list
        : state.additionalProducts.list
      ).find((item, i) => {
        if (item.local_id === action.payload.id) {
          const currentProduct = action.payload.isMain
            ? state.products.list[i]
            : state.additionalProducts.list[i];
          const isPercent = currentProduct.discount.discountType === 'percent';
          const currentTotalPrice = action.payload.discount
            ? isPercent
              ? currentProduct.price * currentProduct.count -
                currentProduct.price *
                  currentProduct.count *
                  (Number(action.payload.discount || 0) / 100)
              : currentProduct.price * currentProduct.count -
                Number(action.payload.discount || 0) * currentProduct.count
            : currentProduct.price * currentProduct.count;

          const newTotalSum = action.payload.isMain
            ? state.products.totalSum -
              currentProduct.totalPrice +
              currentTotalPrice
            : state.additionalProducts.totalSum -
              state.additionalProducts.list[i].totalPrice +
              currentTotalPrice;

          action.payload.isMain
            ? ((state.products.list[i] = {
                ...currentProduct,
                totalPrice: Number(currentTotalPrice.toFixed(2)),
                discount: isPercent
                  ? {
                      ...currentProduct.discount,
                      discountPercent: Number(
                        Number(action.payload.discount) <= 100
                          ? action.payload.discount
                          : 100
                      ),
                    }
                  : {
                      ...currentProduct.discount,
                      discountSum: Number(action.payload.discount),
                    },
              }),
              (state.products.totalSum = Number(newTotalSum.toFixed(2))))
            : ((state.additionalProducts.list[i] = {
                ...currentProduct,
                totalPrice: Number(currentTotalPrice.toFixed(2)),
                discount: isPercent
                  ? {
                      ...currentProduct.discount,
                      discountPercent: Number(
                        Number(action.payload.discount) <= 100
                          ? action.payload.discount
                          : 100
                      ),
                    }
                  : {
                      ...currentProduct.discount,
                      discountSum: Number(action.payload.discount),
                    },
              }),
              (state.additionalProducts.totalSum = Number(
                newTotalSum.toFixed(2)
              )));

          return state;
        }
      });
    },
    changeDiscountType: (
      state: OrderProduct,
      action: {
        type: string;
        payload: {
          isMain: boolean;
          discountType: string;
          id: string;
        };
      }
    ) => {
      (action.payload.isMain
        ? state.products.list
        : state.additionalProducts.list
      ).find((item, i) => {
        if (item.local_id === action.payload.id) {
          const currentProduct = action.payload.isMain
            ? state.products.list[i]
            : state.additionalProducts.list[i];
          const isPercent = action.payload.discountType === 'percent';

          const currentTotalPrice =
            currentProduct.discount.discountPercent ||
            currentProduct.discount.discountSum
              ? isPercent
                ? currentProduct.price * currentProduct.count -
                  currentProduct.price *
                    currentProduct.count *
                    (Number(currentProduct.discount.discountPercent || 0) / 100)
                : currentProduct.price * currentProduct.count -
                  Number(currentProduct.discount.discountSum || 0) *
                    currentProduct.count
              : currentProduct.price * currentProduct.count;

          const newTotalSum = action.payload.isMain
            ? state.products.totalSum -
              currentProduct.totalPrice +
              currentTotalPrice
            : state.additionalProducts.totalSum -
              state.additionalProducts.list[i].totalPrice +
              currentTotalPrice;

          action.payload.isMain
            ? ((state.products.list[i] = {
                ...currentProduct,
                totalPrice: Number(currentTotalPrice.toFixed(2)),
                discount: {
                  ...currentProduct.discount,
                  discountType: action.payload.discountType,
                },
              }),
              (state.products.totalSum = Number(newTotalSum.toFixed(2))))
            : ((state.additionalProducts.list[i] = {
                ...currentProduct,
                totalPrice: Number(currentTotalPrice.toFixed(2)),
                discount: {
                  ...currentProduct.discount,
                  discountType: action.payload.discountType,
                },
              }),
              (state.additionalProducts.totalSum = Number(
                newTotalSum.toFixed(2)
              )));

          return state;
        }
      });
    },
    removeItem: (state: OrderProduct, action) => {
      const itemForRemove = state?.products.list.find(
        (item) => item.local_id === action.payload
      );
      const stateWithoutItem = state?.products.list.filter(
        (item) => item.local_id !== action.payload
      );
      return {
        ...state,
        products: {
          list: stateWithoutItem,
          totalSum: state.products.totalSum - (itemForRemove?.totalPrice || 0),
          totalCount: state.products.totalCount - (itemForRemove?.count || 0),
        },
      };
    },
    removeAdditionalItem: (state: OrderProduct, action) => {
      const itemForRemove = state?.additionalProducts.list.find(
        (item) => item.local_id === action.payload
      );
      const stateWithoutItem = state?.additionalProducts.list.filter(
        (item) => item.local_id !== action.payload
      );
      return {
        ...state,
        additionalProducts: {
          list: stateWithoutItem,
          totalSum:
            state.additionalProducts.totalSum -
            (itemForRemove?.totalPrice || 0),
          totalCount:
            state.additionalProducts.totalCount - (itemForRemove?.count || 0),
        },
      };
    },
    resetOrderProducts() {
      return initialState;
    },
  },
});
export const orderProductReducer = orderProductSlice.reducer;
export const {
  addToOrder,
  removeItem,
  addAdditionalToOrder,
  removeAdditionalItem,
  resetOrderProducts,
  changePrice,
  addDiscount,
  changeCount,
  changeDiscountType,
  addProducts,
  cleanAdditionalProducts,
} = orderProductSlice.actions;
