import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang} from '../utils/baseQuery';
import {GoPagList} from "../models/types";
import {OrderStatistic} from "../models/statistic";

// import { Providers } from '../../models/types';

export const statisticApi = createApi({
  reducerPath: 'statisticApi',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    statisticQuery: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/statistics/',
        body,
        method: 'POST'
      }),
    }),
    statisticOrdersQuery: builder.mutation<GoPagList<OrderStatistic>, any>({
      query: ({ body, params }) => ({
        url: '/statistics/orders',
        body: {
          ...body,
          ...(params ? { ...params } : {}),
        },
        method: 'POST',
      }),
    }),
    statisticProductsQuery: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/statistics/products',
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useStatisticQueryMutation,
  useStatisticOrdersQueryMutation,
  useStatisticProductsQueryMutation,
} = statisticApi;
