import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { GoPagList } from 'src/models/types';
import {AutomationData, Cashier, CheckboxPayment} from 'src/models/settings/settings';

export const checkboxSlice = createApi({
  reducerPath: 'checkbox',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Checkbox', 'Cashiers'],
  endpoints: (builder) => ({
    getCashiers: builder.query<GoPagList<Cashier>, any>({
      query: (params) => ({
        url: '/api/checkbox/cashiers/list',
        method: 'GET',
        params,
      }),
      providesTags: ['Cashiers'],
    }),
    postCashiers: builder.mutation<any, any>({
      query: (params) => ({
        url: '/api/checkbox/cashiers/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Cashiers'],
    }),
    postShiftToggle: builder.mutation<any, any>({
      query: ({ id, action }) => ({
        url: `/api/checkbox/shift/${id}/${action}`,
        method: 'GET',
      }),
    }),
    getAutomation: builder.query<AutomationData[], any>({
      query: () => ({
        url: '/api/checkbox/settings',
        method: 'GET',
      }),
      providesTags: ['Checkbox'],
    }),
    postAutomation: builder.mutation<any, any>({
      query: (params) => ({
        url: '/api/checkbox/settings/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Checkbox'],
    }),
    getPayments: builder.query<CheckboxPayment[], any>({
      query: () => ({
        url: '/api/checkbox/payments',
        method: 'GET',
      }),
      providesTags: ['Checkbox'],
    }),
    postPayments: builder.mutation<any, any>({
      query: (params) => ({
        url: '/api/checkbox/payments/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Checkbox'],
    }),
  }),
});

export const {
  useGetCashiersQuery,
  usePostCashiersMutation,
  usePostShiftToggleMutation,
  useGetAutomationQuery,
  usePostAutomationMutation ,
  usePostPaymentsMutation,
  useGetPaymentsQuery
} = checkboxSlice;
