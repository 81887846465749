import * as I from '../models/IAuth';
import { baseQuery } from '../utils/baseQuery';
import { setAuthTokens } from '../utils/helpers/authHelper';
import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    login: builder.mutation<I.IAuthResponse, I.IAuthLoginBody>({
      query: (body) => ({
        url: '/login/',
        method: 'POST',
        body,
      }),
      transformResponse: (
        data: { data: I.IAuthResponse },
        meta: FetchBaseQueryMeta | undefined
      ) => {
        setAuthTokens(
          {
            accessToken: data.data.token,
          },
          true
        );
        return {
          ...data.data,
          status: meta?.response?.status,
        } as I.IAuthResponse;
      },
    }),
    logout: builder.mutation<unknown, unknown>({
      query: () => ({
        url: '/logout/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLogoutMutation, useLoginMutation } = authApi;
