import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from '../../utils/baseQuery';
import { Permission } from '../../models/settings/permissions';

export const goPermissionsSlice = createApi({
  reducerPath: 'goPermissions',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getUserPermissions: builder.query<Array<Permission>, any>({
      query: (id) => ({
        url: `permissions/${id}/user`,
        method: 'GET',
        params: {list:true}
      }),
    }),
    getRolePermissions: builder.query<Array<Permission>, any>({
      query: (id) => ({
        url: `permissions/${id}/role`,
        method: 'GET',
        params: { list: true },
      }),
    }),
  }),
});

export const { useGetUserPermissionsQuery, useLazyGetUserPermissionsQuery,useGetRolePermissionsQuery } =
  goPermissionsSlice;
