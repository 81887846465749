import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { Office, PagListResult } from '../../models/types';

type OfficesParams = {
  name: string;
  description: string;
  id: string;
};

export const officeSlice = createApi({
  reducerPath: 'offices',
  baseQuery: baseQuery,
  tagTypes: ['Office'],
  endpoints: (builder) => ({
    getOffices: builder.query<PagListResult<Office>, any>({
      query: (params) => ({
        url: 'offices',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Office'],
    }),
    postOffice: builder.mutation({
      query: (params: OfficesParams) => ({
        url: 'offices',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Office'],
    }),
    updateOffice: builder.mutation({
      query: (params) => ({
        url: `offices/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Office'],
    }),
    deleteOffice: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'offices/delete',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Office'],
    }),
  }),
});

export const {
  useGetOfficesQuery,
  useDeleteOfficeMutation,
  usePostOfficeMutation,
  useUpdateOfficeMutation,
} = officeSlice;
