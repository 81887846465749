import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { GoPagList } from 'src/models/types';
import {
  OrderScanType,
  ScanOrderInfoData, ScanType
} from 'src/models/settings/scanner';
export const goScannerSlice = createApi({
  reducerPath: 'goScanner',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Scanner', 'OrderScan'],
  endpoints: (builder) => ({
    getScanTypes: builder.query<GoPagList<ScanType>, any>({
      query: () => ({
        url: '/scantype/',
        method: 'GET',
      }),
      providesTags: ['Scanner'],
    }),
    postScanTypes: builder.mutation<any, any>({
      query: (params) => ({
        url: '/scantype/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Scanner'],
    }),

    getScanOrders: builder.query<GoPagList<OrderScanType>, any>({
      query: () => ({
        url: '/scan/',
        method: 'GET',
      }),
      providesTags: ['OrderScan'],
    }),
    postScanOrders: builder.mutation<any, any>({
      query: (params) => ({
        url: '/scan/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['OrderScan'],
    }),
    scanOrderInfo: builder.mutation<{ data: ScanOrderInfoData }, any>({
      query: (body) => ({
        url: '/orders/scan',
        method: 'POST',
        body,
      }),
    }),
    orderScan: builder.mutation<any, any>({
      query: (body) => ({
        url: '/scan/order',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetScanTypesQuery,
  usePostScanTypesMutation,
  useGetScanOrdersQuery,
  usePostScanOrdersMutation,
  useScanOrderInfoMutation,
  useOrderScanMutation
} = goScannerSlice;
