import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';

export const countriesSlice = createApi({
  reducerPath: 'countries',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    postCountry: builder.mutation({
      query: (id) => ({
        url: `countries/${id}/`,
        method: 'POST',
      }),
    }),
    deleteCountry: builder.mutation({
      query: (id: number) => ({
        url: `countries/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
});
export const { useDeleteCountryMutation, usePostCountryMutation } =
  countriesSlice;
