import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BaseQueryFn,
  QueryActionCreatorResult,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import { SearchQueryParams } from './useSearchQueryParams';
import { useTypedDispatch } from './useTypedDispatch';
import { setSelectedRows } from 'src/redux/slices/selectedRows';

export interface IUseTableReturned {
  tableSelectedRowsId: Array<string | number>;
  setTableSelectedRowsId: Dispatch<SetStateAction<Array<string | number>>>;
  toggleTableSelectedRowsId: (
    value: string | number,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  hasIdInSelected: (value: string | number) => boolean;
  setTableDataIds: (value: any[]) => void;
  refetchData: () => void;
  removeSelectedRowId: (value: string | number) => void;
  deleteItems: (value?: number) => void;
  searchHandler: any;
  setFiltersDataHandler: any;
  setParams?: (queryObject: SearchQueryParams) => void;
}
interface IProps {
  refetch?: () => QueryActionCreatorResult<
    QueryDefinition<unknown, BaseQueryFn<unknown>, string, unknown>
  >;
  deleteItemQuery?: any;
  deleteItemsArrayQuery?: any;
  setParams?: (queryObject: SearchQueryParams) => void;
  params?: SearchQueryParams;
}

export const useTable = (props?: IProps): IUseTableReturned => {
  const dispatch = useTypedDispatch();

  const searchHandler = useRef<any>(() => {
    null;
  });
  const setFiltersDataHandler = useRef<any>(() => {
    null;
  });
  const [tableDataIds, setTableDataIds] = useState<string[]>([]);

  const [tableSelectedRowsId, setTableSelectedRowsId] = useState<
    Array<string | number>
  >([]);
  const lastSelectedValue = useRef<any>(null);
  const toggleTableSelectedRowsId = useCallback(
    (
      value: string | number,
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
    ) => {
      setTableSelectedRowsId((tableSelectedRowsIdPrev) => {
        let copy = [...tableSelectedRowsIdPrev];
        if (event.altKey) {
          if (copy.includes(value)) {
            copy.splice(
              copy.findIndex((item) => item === value),
              1
            );
          } else {
            copy.push(value);
          }
        } else if (event.shiftKey && lastSelectedValue.current) {
          const tableRowsId = [...tableDataIds];

          const valueIndex = tableRowsId.findIndex((item) => item === value);
          const lastSelectedValueIndex = tableRowsId.findIndex(
            (item) => item === lastSelectedValue.current
          );
          const res: Array<string | number> = [];
          if (lastSelectedValueIndex > valueIndex) {
            for (
              let index = lastSelectedValueIndex;
              index >= valueIndex;
              index--
            ) {
              res.push(tableRowsId[index]);
            }
          } else if (lastSelectedValueIndex < valueIndex) {
            for (
              let index = lastSelectedValueIndex;
              index <= valueIndex;
              index++
            ) {
              res.push(tableRowsId[index]);
            }
          } else {
            res.push(value);
          }
          copy = res;
        } else {
          if (copy.includes(value)) {
            copy = [];
            lastSelectedValue.current = null;
          } else {
            copy = [value];
            lastSelectedValue.current = value;
          }
        }
        return copy;
      });
    },
    [tableDataIds]
  );
  useEffect(() => {
    lastSelectedValue.current = null
  }, [tableDataIds]);
  useEffect(() => {
    dispatch(setSelectedRows(tableSelectedRowsId));
  }, [tableSelectedRowsId]);
  if (window.getSelection) {
    window?.getSelection()?.removeAllRanges();
  } else {
    // старый IE
    document?.getSelection()?.empty();
  }

  const removeSelectedRowId = (value?: string | number) => {
    if (!value) {
      setTableSelectedRowsId([]);
      return;
    }
    const tableSelectedRowsIdCopy = [...tableSelectedRowsId];
    tableSelectedRowsIdCopy.splice(
      tableSelectedRowsIdCopy.findIndex((item) => item === value),
      1
    );
    setTableSelectedRowsId(tableSelectedRowsIdCopy);
  };
  const hasIdInSelected = (value: string | number) =>
    tableSelectedRowsId.includes(value);

  const refetchData = () => {
    if (props?.refetch) {
      props.refetch();
    }
  };

  const deleteItems = useCallback(
    async (value?: number) => {
      if (!props?.deleteItemQuery && !props?.deleteItemsArrayQuery) return null;
      let deleteArray: Array<number | string> = [];
      if (tableSelectedRowsId.length) {
        deleteArray = tableSelectedRowsId;
      } else if (value) {
        deleteArray = [value];
      }
      if (props.deleteItemsArrayQuery) {
        const res: any[] = [];
        for (const rowId of deleteArray) {
          res.push(rowId);
          removeSelectedRowId(rowId);
        }
        await props.deleteItemsArrayQuery({ body: { ids: res } });
      } else {
        for (const rowId of deleteArray) {
          await props.deleteItemQuery({ id: rowId as number }).then(() => {
            removeSelectedRowId(rowId);
          });
        }
      }
      refetchData();
    },
    [tableSelectedRowsId]
  );
  return {
    tableSelectedRowsId,
    setTableSelectedRowsId,
    toggleTableSelectedRowsId,
    hasIdInSelected,
    setTableDataIds,
    refetchData,
    removeSelectedRowId,
    deleteItems,
    searchHandler,
    setFiltersDataHandler,
    setParams: props?.setParams
  };
};
