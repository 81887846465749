const getBrowserVersion = () => {
  const userAgent = navigator.userAgent;
  const browserName =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  if (browserName[0] && /trident/i.test(browserName[0])) {
    const rv = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return `IE ${rv[1] || ''}`;
  }

  const version = browserName[2] ? browserName[2] : null;
  const browser = browserName[1] ? browserName[1] : null;

  if (browser === null || version === null) {
    return 'Unknown Browser';
  }

  return `${browser} ${version}`;
};
export default getBrowserVersion;
