import React, { FC } from 'react';
import cls from './TablePagination.module.scss';
import { Meta, MetaPost } from 'src/models/types';
import { SearchQueryParams } from 'src/hooks/useSearchQueryParams';
import { ReactComponent as LeftIcon } from 'src/assets/img/icons/pagination/left-arrow-icon.svg';
import { ReactComponent as RightIcon } from 'src/assets/img/icons/pagination/right-arrow-icon.svg';
import { useTranslation } from 'react-i18next';

type PaginationProps = {
  meta: Meta | MetaPost;
  setParams: (params: any) => void;
  params: SearchQueryParams;
  className?: string;
};
const perPageList = [25, 50, 100, 200, 400];
const Pagination: FC<PaginationProps> = ({
  meta,
  setParams,
  params,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pagination',
  });
  return (
    <div
      className={`${cls['wrapper']} table-pagination${
        className ? ` ${className}` : ''
      }`}
    >
      <div>
        {t('show')}:{' '}
        <select
          onChange={(e) =>
            setParams({
              ...params,
              paginate: Number(e.target.value),
            })}
          defaultValue={params.paginate || 25}
        >
          {perPageList.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
      <div>
        {' '}
        <button
          type="button"
          disabled={meta.current_page === 1}
          onClick={() =>
            setParams({
              ...params,
              page: 1,
            })}
        >
          {t('first')}
        </button>
        <button
          type="button"
          disabled={meta.current_page === 1}
          onClick={() =>
            setParams({
              ...params,
              page: meta.current_page - 1,
            })}
        >
          <LeftIcon />
        </button>
        <span>{meta.current_page}</span>
        <button
          type="button"
          disabled={meta.current_page === meta.last_page}
          onClick={() =>
            setParams({
              ...params,
              page: meta.current_page + 1,
            })}
        >
          <RightIcon />
        </button>
        <button
          type="button"
          disabled={meta.current_page === meta.last_page}
          onClick={() =>
            setParams({
              ...params,
              page: meta.last_page,
            })}
        >
          {t('last')}
        </button>
      </div>
    </div>
  );
};
export default Pagination;
