import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAgentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={15}
    fill="none"
    viewBox="0 0 13 15"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="m7.787 12.857-.803-3.75.803-1.071L8.86 7.5zm-2.143 0L4.573 7.5l1.071.536.804 1.071zM4.305 4.403a.2.2 0 0 1 .034-.05q.083-.067.803-.067.586 0 1.398.159.06.017.176.017a.7.7 0 0 0 .176-.017 7.4 7.4 0 0 1 1.397-.16q.72 0 .804.068a.2.2 0 0 1 .034.05q-.018.15-.034.226a.2.2 0 0 1-.063.054.15.15 0 0 0-.063.088q-.016.034-.062.172-.046.139-.059.172a3 3 0 0 1-.134.284l-.075.117a.4.4 0 0 1-.1.113q-.046.03-.118.08a.4.4 0 0 1-.146.067q-.076.017-.172.033a1 1 0 0 1-.205.017q-.3 0-.494-.105a.65.65 0 0 1-.272-.25 1.5 1.5 0 0 1-.121-.29 1.4 1.4 0 0 0-.097-.246q-.054-.105-.146-.105h-.1q-.092 0-.147.105t-.096.247a1.5 1.5 0 0 1-.122.288.65.65 0 0 1-.272.251q-.192.105-.493.105-.109 0-.206-.017l-.171-.033a.4.4 0 0 1-.147-.067l-.117-.08a.4.4 0 0 1-.1-.113L4.719 5.4a2.77 2.77 0 0 1-.134-.285l-.058-.171a2 2 0 0 0-.063-.172.15.15 0 0 0-.063-.088.2.2 0 0 1-.062-.054 2 2 0 0 1-.034-.226M.823 11.761q0 1.013.611 1.59t1.624.578h7.316q1.012 0 1.624-.578.61-.577.61-1.59 0-.51-.037-.988a8 8 0 0 0-.16-1.05 6 6 0 0 0-.313-1.034 3.4 3.4 0 0 0-.532-.867 2.4 2.4 0 0 0-.782-.623l.753-1.842H9.746q.184-.535.184-1.071a3 3 0 0 0-.017-.268q1.624-.334 1.624-.804 0-.477-1.758-.828a7 7 0 0 0-.43-1.122q-.29-.603-.59-.954Q8.49 0 8.121 0q-.251 0-.703.26t-.703.26q-.252 0-.703-.26Q5.56 0 5.31 0q-.369 0-.637.31-.3.351-.59.954a7 7 0 0 0-.43 1.122q-1.76.351-1.759.828 0 .469 1.624.804-.059.677.168 1.34H1.894l.687 1.883a2.5 2.5 0 0 0-.9.807 3.7 3.7 0 0 0-.548 1.202q-.176.67-.243 1.268a11 11 0 0 0-.067 1.243"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M12.61 15H0V0h12.61z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAgentIcon;
