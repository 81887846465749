import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M7.207 4.028a1.378 1.378 0 1 1 2.755 0 4.82 4.82 0 0 1 2.755 4.132v2.067a2.76 2.76 0 0 0 1.377 2.066H3.074a2.76 2.76 0 0 0 1.378-2.066V8.16a4.82 4.82 0 0 1 2.755-4.132M6.52 12.293v.689a2.066 2.066 0 0 0 4.132 0v-.689"
    />
  </svg>
);
export default SvgBellIcon;
