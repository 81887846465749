import React, { FC } from 'react';
import cls from './ProgramInfoPopup.module.scss';
import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckSuccessIcon } from 'src/assets/img/icons/check-success-icon.svg';

import { Button, ButtonTheme } from 'src/components/UI/Button/Button';

const ProgramInfoPopup: FC<IPopupPropsExport> = ({ show, close }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'program-info-popup',
  });

  return (
    <Popup show={show} close={close} title={t('title')} width="350px">
      <div className={cls['wrapper']}>
        <div className="columns">
          <div className="columns__item">
            <div className="columns__label">{t('version')}:</div>
            <div className="columns__value">LP-CRM v.3.55 Standart</div>
          </div>
          <div className="columns__item">
            <div className="columns__label">{t('license')}:</div>
            <div className="columns__value">Commercial</div>
          </div>
          <div className="columns__item">
            <div className="columns__label">{t('registration')}:</div>
            <div className="columns__value align-center">
              <CheckSuccessIcon className="icon" />
              <span>Registered</span>
            </div>
          </div>
          <div className="columns__item mt-18">
            <div className="columns__label">{t('company')}:</div>
            <div className="columns__value">
              <div>ФОП Луценко О. В.</div>
              <div>ФОП Самойлов В. Ю.</div>
              <div>ФОП Петранговська Л. Є.</div>
            </div>
          </div>
        </div>
        <div className="flex-center mt-25">
          <Button theme={ButtonTheme.LINK}>{t('button')}</Button>
        </div>
      </div>
    </Popup>
  );
};
export default ProgramInfoPopup;
