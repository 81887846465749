import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { PagListResult } from 'src/models/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IArrivalItem } from 'src/models/arrival';

export const goConsignments = createApi({
  reducerPath: 'goConsignments',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Consignments'],
  endpoints: (builder) => ({
    getConsignments: builder.query<PagListResult<IArrivalItem>, any>({
      query: (params: any) => ({
        url: 'consignments',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetConsignmentsQuery } = goConsignments;
