import React from 'react';
import cls from '../TariffsPopup.module.scss';

import {ReactComponent as TarrifsPointButton} from 'src/assets/img/icons/tarrifs-point-icon.svg'
export interface IProp {
    item: any,
    onClick?: ()=>void|null,
    status?: string
}



const TarrifsCard = ({ item, onClick, status }: IProp) => {
  return (
    <div className={`${cls["card"]}`} key={item.id} style={ onClick ? {cursor:"pointer"}:{} } onClick={onClick}>

        {status && <div className={`${cls["card__status"]}`}>{status}</div>}
        <div className={`${cls["card__title"]}`}>{item.title}</div>
        <div className={`${cls["card__wrapper"]}`}>
            <div className={`${cls["card__inner"]}`}>
            <div className={`${cls["card__list"]}`}>
                <div className={`${cls["card__list-item"]}`}>
                <TarrifsPointButton className={`${cls["card__list-item-icon"]}`}></TarrifsPointButton>
                <span>{item.descriptions[0]}</span>
                </div>
                <div className={`${cls["card__list-item"]}`}>
                <TarrifsPointButton className={`${cls["card__list-item-icon"]}`}></TarrifsPointButton>
                <span>{item.descriptions[1]}</span>
                </div>
            </div>
            <div className={`${cls["card__prices"]}`}>
                <div className={`${cls["card__price-normal"]}`}>{item.normal_price}</div>
                <div className={`${cls["card__price-discount"]}`}>{item.discount_price}</div>
            </div>
            </div>
        </div>
    </div>
  );
};
export default TarrifsCard
