import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './UserDropdown.module.scss';
import { ReactComponent as SettignsIcon } from 'src/assets/img/icons/settings-dropdown-icon.svg';
import { ReactComponent as PowerIcon } from 'src/assets/img/icons/power-icon.svg';
import {
  Dropdown,
  IDropdownPropsExport,
} from 'src/components/UI/Dropdown/Dropdown';
import UserSettingsPopup from '../UserSettingsPopup/UserSettingsPopup';
import { useLogoutMutation } from 'src/services/Auth';
import { clearStore } from 'src/redux/Auth';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import getBrowserVersion from 'src/utils/helpers/getBrowserVersion';
import getOC from 'src/utils/helpers/getOC';
import { getAuthTokens } from 'src/utils/helpers/authHelper';
import { PAGES_ENUM } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSounds from 'src/hooks/useSounds';
import useEnumTranslate from 'src/hooks/translate/useEnumTranslate';

interface IProps extends IDropdownPropsExport {}

const UserDropdown = ({ show, close }: IProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'user-dropdown' });
  const { languageTypesMap } = useEnumTranslate();

  const [showSettingsPopup, setShowSettingsPopup] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation({});
  const userData = useTypedSelector(selectors.getUser);
  const isAuth = getAuthTokens().accessToken;

  const { playLogoutSound } = useSounds();

  const logoutHandler = async () => {
    await logout({})
      .unwrap()
      .then(() => {
        playLogoutSound();
        dispatch(clearStore());
        close();
        return navigate(PAGES_ENUM.SIGN_IN);
      })
      .catch((e) => toast.error(e?.data?.message || 'logout fail'));
  };

  return (
    <>
      <Dropdown show={show} close={close} positionAbsolute={true}>
        <div className={`${cls['user-dropdown']} dropdown`}>
          <div className={cls.title}>{userData?.full_name}</div>
          <div className={cls.entries}>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('login')}</div>
              <div className={`${cls['entry-value']} fw-600`}>
                {userData?.login}
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('group')}</div>
              <div className={`${cls['entry-value']}`}>{userData?.role}</div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('country')}</div>
              <div className={`${cls['entry-value']}`}>
                <span>
                  {(userData?.country && userData?.country?.name?.ua) ||
                    'Украина'}
                </span>
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('department')}</div>
              <div className={`${cls['entry-value']}`}>
                {userData?.offices?.length
                  ? userData?.offices.map((item) => item.name).join(', ')
                  : 'no offices'}
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('browser')}</div>
              <div className={`${cls['entry-value']}`}>
                <span>{getBrowserVersion()}</span>
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('os')}</div>
              <div className={`${cls['entry-value']}`}>
                <span>{getOC()}</span>
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('ip')}</div>
              <div className={`${cls['entry-value']}`}>
                <span>{userData?.ip_address} </span>
                {/*<UkraineFlag className="icon" />*/}
                <span className="ml-5">
                  {' '}
                  {(userData?.country && userData?.country?.name?.ua) ||
                    'Украина'}
                </span>
              </div>
            </div>
            <div className={cls.entry}>
              <div className={cls['entry-title']}>{t('language')}</div>
              <div className={`${cls['entry-value']}`}>
                <span>
                  {[userData?.crm_language]} (
                  {languageTypesMap[userData?.crm_language || 'ua']})
                </span>
              </div>
            </div>
          </div>
          <div className={cls.divider} />
          <div className={cls.actions}>
            <div
              className={cls.action}
              onClick={() => {
                setShowSettingsPopup(true);
              }}
            >
              <SettignsIcon className="icon" />
              <span>{t('settings')}</span>
            </div>
            {isAuth && (
              <div className={cls.action} onClick={logoutHandler}>
                <PowerIcon className="icon" />
                <span>{t('quit')}</span>
              </div>
            )}
          </div>
        </div>
      </Dropdown>
      {showSettingsPopup && (
        <UserSettingsPopup
          show={showSettingsPopup}
          close={() => {
            setShowSettingsPopup(false);
          }}
        />
      )}
    </>
  );
};
export default UserDropdown;
