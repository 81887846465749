import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { ProductProducer } from '../../models/products/productProducers';
import { ProducerTypeEnum } from '../../models/enums';
import { PagListResult } from '../../models/types';

type ProductProducersParams = {
  name?: string;
  id?: string;
  description?: string;
  type?: ProducerTypeEnum;
};

export const productProducersSlice = createApi({
  reducerPath: 'productsProducers',
  baseQuery: baseQuery,
  tagTypes: ['Producers'],
  endpoints: (builder) => ({
    getProductProducers: builder.query<PagListResult<ProductProducer>, any>({
      query: (params: ProductProducersParams) => ({
        url: 'producers',
        method: 'GET',
        params,
      }),
      providesTags: ['Producers'],
    }),
    postProductProducers: builder.mutation({
      query: (params) => ({
        url: 'producers',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Producers'],
    }),
    updateProductProducers: builder.mutation({
      query: ({ id, params }) => ({
        url: `producers/${id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Producers'],
    }),
    deleteProductProducer: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'producers/delete',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Producers'],
    }),
  }),
});

export const {
  useGetProductProducersQuery,
  useLazyGetProductProducersQuery,
  usePostProductProducersMutation,
  useUpdateProductProducersMutation,
  useDeleteProductProducerMutation,
} = productProducersSlice;
