import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authApi } from '../services/Auth';
import { authSlice } from './Auth';
import { orderStatusesApi } from '../services/orders/orderStatuses';
import { clientsApi } from 'src/services/Clients';
import { writeoffApi } from 'src/services/warehouse/writeOff';
import { productCategoriesSlice } from '../services/products/productCategories';
import { productProducersSlice } from '../services/products/productProducers';
import { countriesSlice } from '../services/settings/country/countries';
import { attributeCategoriesSlice } from '../services/products/attributesCategories';
import { attributesSlice } from '../services/products/attributes';
import { productsSlice } from '../services/products/products';
import { colorsSlice } from '../services/colors';
import { currencySlice } from '../services/settings/currency';
import { providersApi } from '../services/warehouse/providers';
import { arrivalApi } from '../services/warehouse/arrival';
import { providersSlice } from '../services/products/providers';
import { officeSlice } from '../services/settings/offices';
import { registriesSlice } from '../services/sending/registry';
import { paymentSlice } from '../services/settings/payments';
import { historiesSlice } from '../services/settings/history';
import { movementApi } from 'src/services/movements/movements';
import { smsApi } from 'src/services/sms/sms';
import { modulesSlice } from '../services/settings/models';
import { deliveryServicesSlice } from '../services/sending/delivery';
import { invoicesSlice } from '../services/sending/invoices';
import { filesSlice } from '../services/files';
import { blockedIpSlice } from 'src/services/settings/blockedIp';
import { rolesSlice } from '../services/settings/userGroups';
import { permissionsSlice } from '../services/settings/permissions';
import { usersSlice } from '../services/users/users';
import { ordersApi } from '../services/orders/orders';
import { orderProductSlice } from './slices/orderProduct';
import { notificationsSlice } from '../services/notification';
import { partnersApi } from '../services/partners';
import { systemsSlice } from 'src/services/settings/systems';
import { statisticApi } from 'src/services/statistic';
import { tableDataOperationsSlice } from './slices/tableDataOperations';
import { selectedRowsSlice } from './slices/selectedRows';
import { uniTalkSlice } from '../services/settings/uniTalk';
import { upLocSlice } from '../services/settings/upLoc';
import { npLocSlice } from '../services/settings/npLoc';
import { goPermissionsSlice } from '../services/settings/goPermissions';
import { goOrdersApi } from '../services/orders/goOrders';
import { goProductsSlice } from '../services/products/goProducts';
import { goCountriesSlice } from '../services/settings/country/goCountry';
import { fixStateResetSlice } from './slices/fixStateReset';
import { goMovementApi } from '../services/movements/goMovements';
import { goStatuses } from '../services/orders/goStatuses';
import { goConsignments } from '../services/warehouse/goConsignments';
import { goUsers } from '../services/users/goUsers';
import { goOfficeSlice } from '../services/settings/goOffices/goOffices';
import { goPaymentSlice } from '../services/settings/goPayments/goPayments';
import { goDeliveryServicesSlice } from '../services/sending/go/goDelivery';
import { goModulesSlice } from '../services/settings/goModels/goModels';
import { ordersSlice } from './slices/ordersSlce';
import { goInvoicesServicesSlice } from '../services/sending/go/goInvoices';
import { goScannerSlice } from '../services/settings/scanner/goScanner';
import { pythonSSE, pythonSSEOrders } from '../services/pytonSSE';
import { notifications } from './slices/notificationsSlice';
import { goHistoriesSlice } from '../services/settings/goHistory';
import { checkboxSlice } from '../services/settings/checkbox/checkbox';
import { goSms } from '../services/sms/goSms';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [tableDataOperationsSlice.name]: tableDataOperationsSlice.reducer,
  [selectedRowsSlice.name]: selectedRowsSlice.reducer,
  [fixStateResetSlice.name]: fixStateResetSlice.reducer,
  [orderProductSlice.name]: orderProductSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [notifications.name]: notifications.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [goSms.reducerPath]: goSms.reducer,
  [checkboxSlice.reducerPath]: checkboxSlice.reducer,
  [notificationsSlice.reducerPath]: notificationsSlice.reducer,
  [orderStatusesApi.reducerPath]: orderStatusesApi.reducer,
  [goHistoriesSlice.reducerPath]: goHistoriesSlice.reducer,
  [goStatuses.reducerPath]: goStatuses.reducer,
  [goScannerSlice.reducerPath]: goScannerSlice.reducer,
  [goInvoicesServicesSlice.reducerPath]: goInvoicesServicesSlice.reducer,
  [productCategoriesSlice.reducerPath]: productCategoriesSlice.reducer,
  [productProducersSlice.reducerPath]: productProducersSlice.reducer,
  [countriesSlice.reducerPath]: countriesSlice.reducer,
  [attributeCategoriesSlice.reducerPath]: attributeCategoriesSlice.reducer,
  [attributesSlice.reducerPath]: attributesSlice.reducer,
  [colorsSlice.reducerPath]: colorsSlice.reducer,
  [pythonSSE.reducerPath]: pythonSSE.reducer,
  [pythonSSEOrders.reducerPath]: pythonSSEOrders.reducer,
  [productsSlice.reducerPath]: productsSlice.reducer,
  [currencySlice.reducerPath]: currencySlice.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [writeoffApi.reducerPath]: writeoffApi.reducer,
  [arrivalApi.reducerPath]: arrivalApi.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [providersSlice.reducerPath]: providersSlice.reducer,
  // [writeoffApi.reducerPath]: writeoffApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [registriesSlice.reducerPath]: registriesSlice.reducer,
  [officeSlice.reducerPath]: officeSlice.reducer,
  [goOfficeSlice.reducerPath]: goOfficeSlice.reducer,
  [goConsignments.reducerPath]: goConsignments.reducer,
  [paymentSlice.reducerPath]: paymentSlice.reducer,
  [goPaymentSlice.reducerPath]: goPaymentSlice.reducer,
  [historiesSlice.reducerPath]: historiesSlice.reducer,
  [movementApi.reducerPath]: movementApi.reducer,
  [goMovementApi.reducerPath]: goMovementApi.reducer,
  [smsApi.reducerPath]: smsApi.reducer,
  [modulesSlice.reducerPath]: modulesSlice.reducer,
  [goModulesSlice.reducerPath]: goModulesSlice.reducer,
  [deliveryServicesSlice.reducerPath]: deliveryServicesSlice.reducer,
  [goDeliveryServicesSlice.reducerPath]: goDeliveryServicesSlice.reducer,
  [invoicesSlice.reducerPath]: invoicesSlice.reducer,
  [goProductsSlice.reducerPath]: goProductsSlice.reducer,
  [goCountriesSlice.reducerPath]: goCountriesSlice.reducer,
  [filesSlice.reducerPath]: filesSlice.reducer,
  [blockedIpSlice.reducerPath]: blockedIpSlice.reducer,
  [permissionsSlice.reducerPath]: permissionsSlice.reducer,
  [goPermissionsSlice.reducerPath]: goPermissionsSlice.reducer,
  [rolesSlice.reducerPath]: rolesSlice.reducer,
  [usersSlice.reducerPath]: usersSlice.reducer,
  [goUsers.reducerPath]: goUsers.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [goOrdersApi.reducerPath]: goOrdersApi.reducer,
  [systemsSlice.reducerPath]: systemsSlice.reducer,
  [statisticApi.reducerPath]: statisticApi.reducer,
  [uniTalkSlice.reducerPath]: uniTalkSlice.reducer,
  [upLocSlice.reducerPath]: upLocSlice.reducer,
  [npLocSlice.reducerPath]: npLocSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      orderStatusesApi.middleware,
      goStatuses.middleware,
      goInvoicesServicesSlice.middleware,
      productCategoriesSlice.middleware,
      productProducersSlice.middleware,
      countriesSlice.middleware,
      goHistoriesSlice.middleware,
      pythonSSE.middleware,
      pythonSSEOrders.middleware,
      attributeCategoriesSlice.middleware,
      attributesSlice.middleware,
      colorsSlice.middleware,
      goSms.middleware,
      productsSlice.middleware,
      goScannerSlice.middleware,
      goProductsSlice.middleware,
      goCountriesSlice.middleware,
      checkboxSlice.middleware,
      currencySlice.middleware,
      clientsApi.middleware,
      writeoffApi.middleware,
      arrivalApi.middleware,
      providersApi.middleware,
      providersSlice.middleware,
      officeSlice.middleware,
      goOfficeSlice.middleware,
      registriesSlice.middleware,
      goConsignments.middleware,
      paymentSlice.middleware,
      goPaymentSlice.middleware,
      goPermissionsSlice.middleware,
      historiesSlice.middleware,
      movementApi.middleware,
      goMovementApi.middleware,
      smsApi.middleware,
      partnersApi.middleware,
      modulesSlice.middleware,
      goModulesSlice.middleware,
      deliveryServicesSlice.middleware,
      goDeliveryServicesSlice.middleware,
      invoicesSlice.middleware,
      filesSlice.middleware,
      blockedIpSlice.middleware,
      notificationsSlice.middleware,
      rolesSlice.middleware,
      permissionsSlice.middleware,
      usersSlice.middleware,
      goUsers.middleware,
      ordersApi.middleware,
      goOrdersApi.middleware,
      systemsSlice.middleware,
      statisticApi.middleware,
      upLocSlice.middleware,
      npLocSlice.middleware,
      uniTalkSlice.middleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
