import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { ListResult, PagListResult, SettingModule } from 'src/models/types';
import Module, {
  CabinetStatusItem,
  CounterAgentNP,
  CounterPartyContact,
  CounterPartyUP,
  EnumUserType,
  PostContactByCounterparty,
} from 'src/models/settings/module';

type NPParams = {
  counterparty_id: string;
  sender_id: string;
  city: string;
  cargo_type: string;
  service_type: string;
  address: string;
  building: number;
  flat: number;
};
export interface UPParams {
  sender_token: string | null;
  sender_info: string | null;
  name: string | null;
  limit: string | null;
  user_type: string | null | EnumUserType;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  phone: string | null;
  bill: string | null;
  region: string | null;
  neighborhood: string | null;
  city: string | null;
  street: string | null;
  house: string | null;
  flat: string | null;
  zip_code: string | null;
  delivery_type: string | null;
  api_key: string | null;
  tracking_key: string | null;
  payer_for_payment: string | null;
  refusal: string | null;
  warning: boolean;
  review: boolean;
  sms: boolean;
}

export const modulesSlice = createApi({
  reducerPath: 'modules',
  baseQuery: baseQuery,
  tagTypes: ['Modules', 'CabinetListStatuses', 'NPModule', 'UPModule'],
  endpoints: (builder) => ({
    getModules: builder.query<ListResult<SettingModule>, any>({
      query: () => 'apis',
      providesTags: ['Modules'],
    }),

    getNPModule: builder.query<PagListResult<Module>, any>({
      query: (params) => ({ url: 'novaPoshtaSettings', params }),
      providesTags: ['NPModule'],
    }),

    getUPModule: builder.query<PagListResult<CounterPartyUP>, any>({
      query: (params) => ({ url: 'ukrPoshtaSettings/', params }),
      providesTags: ['UPModule'],
    }),

    postNPModule: builder.mutation({
      query: (params: NPParams) => ({
        url: 'novaPoshtaSettings/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['NPModule'],
    }),
    updateNPModule: builder.mutation({
      query: ({ params, id }) => ({
        url: `novaPoshtaSettings/${id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['NPModule'],
    }),

    deleteCounterparty: builder.mutation({
      query: (id) => ({
        url: `novaPoshtaSettings/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NPModule'],
    }),

    postUPModule: builder.mutation({
      query: (params: UPParams) => ({
        url: 'ukrPoshtaSettings/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UPModule'],
    }),

    updateUPModule: builder.mutation({
      query: ({ params, id }) => ({
        url: `ukrPoshtaSettings/${id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['UPModule'],
    }),

    deleteCounterpartyUP: builder.mutation({
      query: (id) => ({
        url: `ukrPoshtaSettings/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UPModule'],
    }),

    updateModuleActivity: builder.mutation<ListResult<any>, any>({
      query: (params) => ({
        url: `apis/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Modules'],
    }),

    postTurboSmsApiKey: builder.mutation<any, any>({
      query: (api_key) => ({
        url: 'api/turbo/api-key',
        method: 'POST',
        body: { api_key },
      }),
      invalidatesTags: ['Modules'],
    }),

    // NOVA POSHTA

    getCounterparties: builder.query<ListResult<any>, any>({
      query: ({ apiKey }) => ({
        url: `api/np/counterparties/Sender/${apiKey}`,
        method: 'POST',
      }),
    }),

    getCounterAgentList: builder.query<ListResult<CounterAgentNP>, any>({
      query: () => ({
        url: 'novaPoshtaSettings',
        method: 'GET',
      }),
    }),

    getContactsByCounterparty: builder.query<
      ListResult<CounterPartyContact>,
      any
    >({
      query: ({ id, apiKey }) => ({
        url: `api/np/contacts/${id}/${apiKey}`,
        method: 'POST',
      }),
    }),

    postContactNpByCounterparty: builder.mutation({
      query: (body: PostContactByCounterparty) => ({
        url: 'api/np/contact',
        method: 'POST',
        body: body,
      }),
    }),
    getCargos: builder.query<
      ListResult<{ Description: string; Ref: string }>,
      any
    >({
      query: ({ apiKey }) => ({
        url: `api/np/cargos/${apiKey}`,
        method: 'POST',
      }),
    }),

    getNPTrackingStatuses: builder.query<ListResult<any>, any>({
      query: () => 'api/np/statuses',
    }),

    getCabinetListStatuses: builder.query<
      PagListResult<CabinetStatusItem>,
      any
    >({
      query: (params) => ({
        url: '/api/poststatuses',
        method: 'GET',
        params,
      }),
      providesTags: ['CabinetListStatuses'],
    }),

    deleteCabinetStatus: builder.mutation({
      query: (id) => ({
        url: `api/poststatuses/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CabinetListStatuses'],
    }),

    postCabinetStatus: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/poststatuses/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CabinetListStatuses'],
    }),

    updateCabinetToggleStatus: builder.query({
      query: (type) => ({
        url: `api/poststatuses/${type}/toggle`,
        method: 'GET',
      }),
    }),
  }),
});
export const {
  useGetModulesQuery,
  usePostNPModuleMutation,
  useGetCounterpartiesQuery,
  useGetCargosQuery,
  usePostUPModuleMutation,
  useUpdateModuleActivityMutation,
  useGetNPModuleQuery,
  useGetUPModuleQuery,
  useUpdateUPModuleMutation,
  useUpdateNPModuleMutation,
  useGetContactsByCounterpartyQuery,
  usePostContactNpByCounterpartyMutation,
  useGetNPTrackingStatusesQuery,
  useGetCabinetListStatusesQuery,
  useDeleteCabinetStatusMutation,
  usePostCabinetStatusMutation,
  useLazyUpdateCabinetToggleStatusQuery,
  useDeleteCounterpartyMutation,
  useDeleteCounterpartyUPMutation,
  usePostTurboSmsApiKeyMutation,
} = modulesSlice;
