import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import ProductCategory, {
  CategoryParams,
} from '../../models/products/productCategory';
import { PagListResult } from '../../models/types';

type AttributeCategoriesParams = {
  name?: string;
  id?: string;
  is_active?: boolean;
};

export const attributeCategoriesSlice = createApi({
  reducerPath: 'attributeCategories',
  baseQuery: baseQuery,
  tagTypes: ['attributeCategories'],
  endpoints: (builder) => ({
    getAttributeCategories: builder.query<PagListResult<ProductCategory>, any>({
      query: (params: AttributeCategoriesParams) => ({
        url: 'attributeCategories',
        method: 'GET',
        params,
      }),
      providesTags: ['attributeCategories'],
    }),
    postAttributeCategory: builder.mutation({
      query: (params: CategoryParams) => ({
        url: 'attributeCategories',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['attributeCategories'],
    }),
    updateAttributeCategory: builder.mutation({
      query: ({ id, params }) => ({
        url: `attributeCategories/${id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['attributeCategories'],
    }),
    deleteAttributeCategory: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'attributeCategories/delete/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['attributeCategories'],
    }),
  }),
});

export const {
  useGetAttributeCategoriesQuery,
  useLazyGetAttributeCategoriesQuery,
  usePostAttributeCategoryMutation,
  useUpdateAttributeCategoryMutation,
  useDeleteAttributeCategoryMutation,
} = attributeCategoriesSlice;
