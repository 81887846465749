import { createApi } from '@reduxjs/toolkit/query/react';
import { Color, ListResult } from '../models/types';
import { baseQuery } from '../utils/baseQuery';

export const colorsSlice = createApi({
  reducerPath: 'colors',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getColors: builder.query<ListResult<Color>, any>({
      query: () => 'colors',
    }),
  }),
});

export const { useGetColorsQuery } = colorsSlice;
