import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
// import { ListResult } from '../../models/types';
import { Permission } from '../../models/settings/permissions';

export const permissionsSlice = createApi({
  reducerPath: 'permissions',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    // getRolePermissions: builder.query<ListResult<Permission>, any>({
    //   query: (id) => ({
    //     url: `permissions/${id}/role`,
    //     method: 'GET',
    //     params: { list: true },
    //   }),
    // }),
    getAllPermissions: builder.query<{ data: Array<Permission> }, any>({
      query: () => ({
        url: 'permissions/',
        method: 'GET',
        params: { list: true },
      }),
    }),
    postPermissions: builder.mutation({
      query: (params) => ({
        url: `permissions/${params.id}/role`,
        method: 'POST',
        body: params,
      }),
    }),
    postUserPermissions: builder.mutation({
      query: (params) => ({
        url: `permissions/${params.id}/user`,
        method: 'POST',
        body: params,
      }),
    }),
    deletePermissions: builder.mutation({
      query: (id: number) => ({
        url: `permissions/${id}/role`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  // useGetRolePermissionsQuery,
  useGetAllPermissionsQuery,
  usePostUserPermissionsMutation,
  usePostPermissionsMutation,
  useDeletePermissionsMutation,
} = permissionsSlice;
