import React from 'react';
import { ITableDataItem, baseRowHeight } from '../Table';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
interface IProps {
  props?: any,
  toggleTableSelectedRowsId?: any,
  rowHeight?: 'disabled' | number,
  tableDataSorted: ITableDataItem[]
}
const TableDataRow = function TableDataRow({
  props,
  toggleTableSelectedRowsId,
  rowHeight,
  tableDataSorted
}: IProps){
  const selectedRows = useTypedSelector(selectors.getSelectedRows);

  const tableDataItem = tableDataSorted[props['data-index']];
  const tableDataStyle = rowHeight !== 'disabled' ? {height: rowHeight || baseRowHeight, ...(tableDataItem?.data?.style||{})} : {...(tableDataItem?.data?.style)||{}};
  
  if(!tableDataSorted.length){
    return (
      <tr  {...props}  className="table__tr-no-data" />  
    )
  }
  return (
    <tr 
      {...props} 
      onDoubleClick={() => {
        tableDataItem.data.onDoubleClick
          ? tableDataItem.data.onDoubleClick()
          : null;
      }}
      onClick={(event) => {
        tableDataItem.data.onClick
          ? tableDataItem.data.onClick()
          : toggleTableSelectedRowsId(
              tableDataItem.data.id,
              event
            );
      }}
      className={`table__tr-data table__tr-data--first 
      ${
        tableDataItem.data?.isDisabled && 'table__tr-data--disabled'
      }
      ${
        selectedRows.includes(
          tableDataItem.data.id
        ) && 'table__tr-data--selected'
      }
      ${tableDataItem.data?.className||''}
      `}
      style={tableDataStyle}
      />
  );
}
    
    



export default TableDataRow;
