import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { ListResult } from 'src/models/types';
import { City, NPDepartment, Street } from 'src/models/posts/np';

export const npLocSlice = createApi({
  reducerPath: 'npLoc',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: [],
  endpoints: (builder) => ({
    getNPCities: builder.query<ListResult<City>, any>({
      query: (params) => ({
        url: params.key ? `api/np/cities/${params.key}` : 'api/np/cities',
        method: 'POST',
        body: params,
      }),
    }),
    getNPStreets: builder.query<ListResult<Street>, any>({
      query: (params) => ({
        url: params.key ? `api/np/streets/${params.key}` : 'api/np/streets',
        method: 'POST',
        body: params,
      }),
    }),
    getNPWarehouses: builder.query<ListResult<NPDepartment>, any>({
      query: (params) => ({
        url: params.key
          ? `api/np/warehouses/${params.key}`
          : 'api/np/warehouses',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});
export const {
  useGetNPCitiesQuery,
  useGetNPStreetsQuery,
  useGetNPWarehousesQuery,
} = npLocSlice;
