import React, { FC } from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';

const HeaderContainer: FC = () => {
  const location = useLocation();
  return ![
    '/login',
    '/registration',
    '/session-over',
    '/reset-password',
    '/users/password',
  ].includes(location.pathname) ? (
    <Header />
  ) : null;
};

export default HeaderContainer;
