import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { DeliveryType } from 'src/models/types';

export const deliveryServicesSlice = createApi({
  reducerPath: 'delivery',
  baseQuery: baseQuery,
  tagTypes: ['Delivery'],
  endpoints: (builder) => ({
    getDeliveryService: builder.query<{ data: DeliveryType }, any>({
      query: (id) => `deliveryTypes/${id}/`,
    }),
    postDeliveryService: builder.mutation({
      query: (params: any) => ({
        url: 'deliveryTypes',
        method: 'POST',
        body: params,
      }),
    }),
    updateDeliveryService: builder.mutation({
      query: ({ id, params }) => ({
        url: `deliveryTypes/${id}/`,
        method: 'PUT',
        body: params,
      }),
    }),
    deleteDeliveryService: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'deliveryTypes/delete',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useDeleteDeliveryServiceMutation,
  useUpdateDeliveryServiceMutation,
  usePostDeliveryServiceMutation,
} = deliveryServicesSlice;
