import { createSlice } from '@reduxjs/toolkit';
import { Notification } from 'src/models/notifications';

const initialState = {
  notifications: [] as Array<Notification>,
  newNotifications: [] as Array<Notification>,
  isLogout: false,
};
type notificationActionTypes = {
  payload: Notification;
  type: string;
};
type doneActionTypes = {
  payload: number | null | undefined;
  type: string;
};
export const notifications = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    handleLogout: (state) => {
      return {
        ...state,
        isLogout: true,
      };
    },
    setNotifications: (
      state,
      action: { type: string; payload: Array<Notification> }
    ) => {
      return {
        ...state,
        notifications: action.payload,
        isLogout: false,
      };
    },
    onNotificationCreate: (state, action: notificationActionTypes) => {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    },
    onNotificationUpdate: (state, action: notificationActionTypes) => {
      return {
        ...state,
        notifications: state.notifications.map((i) => {
          return i.id === action.payload.id ? action.payload : i;
        }),
      };
    },
    onNotificationDone: (state, action: doneActionTypes) => {
      return {
        ...state,
        notifications: state.notifications.map((i) => {
          return i.id === action.payload ? { ...i, sent: true } : i;
        }),
      };
    },
    onNotificationDelete: (
      state,
      action: { type: string; payload: number | null }
    ) => {
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => action.payload !== item.id
        ),
      };
    },

    setNewNotification: (
      state,
      action: { type: string; payload: Notification }
    ) => {
      return {
        ...state,
        notifications: state.notifications.map((i) => {
          return i.id === action.payload.id ? action.payload : i;
        }),
        newNotifications: [action.payload, ...state.newNotifications],
      };
    },
    closeNewNotification: (
      state,
      action: { type: string; payload: number }
    ) => {
      return {
        ...state,
        newNotifications: state.newNotifications.filter(
          (item) => action.payload !== item.id
        ),
      };
    },
  },
});
export const notificationsReducer = notifications.reducer;
export const {
  handleLogout,
  setNotifications,
  onNotificationDelete,
  onNotificationUpdate,
  onNotificationCreate,
  setNewNotification,
  closeNewNotification,
  onNotificationDone,
} = notifications.actions;
