import { useCallback, useEffect, useRef } from 'react';
import selectors from '../redux/selectors';
import { useTypedSelector } from './useTypedSelector';
import volumeHandler from '../utils/helpers/sound/volumeHandler';

const useSounds = () => {
  const user = useTypedSelector(selectors.getUser);
  const soundPlayer = useRef<any>(new Audio());
  const playSound = useCallback(
    (soundFile: string, volumeKey: string, volume?: number) => {
      if (!soundPlayer.current) return;
      if (user?.muted_sound) {
        soundPlayer.current.volume = 0;
      } else {
        soundPlayer.current.src = process.env.PUBLIC_URL + soundFile;
        soundPlayer.current.volume = volume
          ? volume
          : volumeHandler(user.sounds?.[volumeKey]);

        soundPlayer.current.autoplay = true;
      }
    },
    [user, soundPlayer.current]
  );

  useEffect(() => {
    return () => {
      soundPlayer.current = null;
    };
  }, []);

  return {
    playLoginSound: (volume: number) =>
      playSound('/sound/LogON.mp3', 'login_volume', volume),
    playLogoutSound: () => playSound('/sound/LogOFF.mp3', 'logout_volume'),
    playBlockAccessSound: () =>
      playSound('/sound/ACCESS_LOCKED.mp3', 'block_access_volume'),
    playNewOrderSound: () =>
      playSound('/sound/NewOrder.mp3', 'new_order_volume'),
    playToggleSound: () =>
      playSound('/sound/BUTTON_SWITCH.mp3', 'toggle_volume'),
    playErrorSound: () => playSound('/sound/ERROR.mp3', 'error_volume'),
    playInfoSound: () => playSound('/sound/INFO.mp3', 'info_volume'),
    playWarningSound: () => playSound('/sound/WARNING.mp3', 'warning_volume'),
    playConfirmSound: () => playSound('/sound/CONFIRM.mp3', 'approve_volume'),
    playAlertSound: () =>
      playSound('/sound/ALARM_CLOCK.mp3', 'reminder_volume'),
  };
};

export default useSounds;
