import React, { FC, ReactNode, Suspense } from 'react';
import cls from './Layout.module.scss';
import Sidebar from '../Parts/Sidebar/Sidebar';
import MainLoader from '../UI/Loaders/MainLoader';

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => (
  <main className={cls['wrapper']}>
    <Sidebar />
    <Suspense fallback={<MainLoader withoutTitle />}>
      <div className={`${cls['container']} main-container`}>{children}</div>
    </Suspense>
  </main>
);
export default AppLayout;
