import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_KEY || 'accessTokenCrm';

export interface AuthTokens {
  accessToken: string;
}

export const cookieOptions = {
  domain:
    process.env.NODE_ENV !== 'development'
      ? process.env.REACT_APP_DOMAIN
      : 'localhost',
  path: '/',
};

export const setAuthTokens = (
  tokens: AuthTokens,
  isTimeBased?: boolean
): boolean => {
  if (!tokens) {
    return false;
  }
  const { accessToken } = tokens;
  if (accessToken) {
    Cookies.set(
      ACCESS_TOKEN_KEY,
      accessToken,
      !isTimeBased ? cookieOptions : { ...cookieOptions }
    );
    return true;
  }
  return false;
};

export const getAuthTokens = (): AuthTokens => {
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  if (accessToken) {
    return { accessToken };
  }
  return { accessToken: '' };
};
export const removeAuthTokens = () => {
  Cookies.remove(ACCESS_TOKEN_KEY, cookieOptions);
};
