import React, { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';

type FlagProps = {
  iso2: string;
  nameEn?: string;
  emoji: any;
};

const CountryFlag: FC<FlagProps> = ({ iso2, nameEn, emoji }) => {
  return nameEn === 'All' ? (
    <span style={{ fontSize: '18px' }}> {'🌍'} </span>
  ) : (
    <ReactCountryFlag
      style={{ minWidth: '20px', marginLeft: '3px' }}
      countryCode={iso2}
      svg
    />
  );
};

export default CountryFlag;
