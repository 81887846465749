import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M5.999 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333M2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.668 2.086a2.667 2.667 0 0 1 0 5.167M14 14.002v-1.334a2.67 2.67 0 0 0-2-2.566"
    />
  </svg>
);
export default SvgUsersIcon;
