import React, { ReactNode } from 'react';
import cls from './Checkbox.module.scss';
export enum ICheckboxTypes {
  CIRCLE = "circle",
  SQUARE = "square",
}
interface IProps{
  textElement?: ReactNode,
  type: ICheckboxTypes
}
export const Checkbox = ( {textElement, type} :IProps) => (
  <label className={`${cls['wrapper']} ${cls[type]}`}>
    <div className={cls['container']}>
      <input type="checkbox" />
      {   
        type===ICheckboxTypes.CIRCLE
        ?<svg
          className={cls['checkmark']}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2182_143918)">
            <path
              d="M13.6093 3.37114L6.14034 11.7854L1.63104 8.17486C1.42959 8.0044 1.11967 8.03539 0.94922 8.25233C0.778765 8.45378 0.809757 8.7637 1.0267 8.93415L5.8924 12.8236C5.98538 12.9011 6.09385 12.9321 6.20232 12.9321C6.34178 12.9321 6.46575 12.8701 6.57422 12.7616L14.3531 4.00647C14.5391 3.80502 14.5081 3.4951 14.3067 3.32465C14.1052 3.1387 13.7953 3.16969 13.6093 3.37114Z"
              fill="#0C4B91"
            />
          </g>
          <defs>
            <clipPath id="clip0_2182_143918">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(0.160156 0.860352)"
              />
            </clipPath>
          </defs>
        </svg>
        :<svg className={cls['checkmark']} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.19959 5.26301L1.28033 3.4717C0.987435 3.19833 0.512562 3.19833 0.219669 3.4717C-0.0732231 3.74506 -0.0732231 4.18828 0.219669 4.46164L2.71966 6.79497C3.03151 7.08603 3.54382 7.06434 3.82616 6.74812L8.82615 1.14814C9.09132 0.85115 9.04833 0.409756 8.73012 0.162262C8.41191 -0.0852319 7.93899 -0.0451052 7.67382 0.251888L3.19959 5.26301Z" fill="#78B830" />
        </svg>
      }
    </div>
    {textElement && <div className={cls['text']}>{textElement}</div>}
    

  </label>

);
