import React, { FC, useState, useEffect } from 'react';
import cls from './Input.module.scss';
import {UseFormRegisterReturn} from 'react-hook-form';
import ErrorExtra from '../../common/ErrorExtra';
import {errorTextType} from "src/models/types";
export enum InputType {
  TRANSPARENT = 'transparent',
  BOTTOM_DASHED = 'bottom-dashed',
  BOTTOM_BORDERED = 'bottom-bordered',
  TEXTAREA = 'textarea',
  FULL_BORDERED = 'full-bordered',
  POPUP_INPUT = 'popup-input',
  FULL_BORDERED_SHADOW = 'full-bordered-shadow',
}
export interface InputProps {
  value?: string;
  inputType?: string;
  type?: InputType;
  className?: string;
  wrapperClassName?: string;
  errorText?: errorTextType;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  register?: UseFormRegisterReturn;
  required?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
}
export const Input: FC<InputProps> = ({
  value,
  type = InputType.TRANSPARENT,
  inputType = 'text',
  onChange,
  placeholder,
  className = '',
  disabled,
  wrapperClassName,
  register = {},
  required,
  min,
  max,
  step,
  minLength,
  maxLength,
  pattern,
  readOnly,
  errorText,
}) => {
  const [inpValue, setInpValue] = useState(value);

  const onChangeInp = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInpValue(e.target.value);
    onChange && onChange(e.target.value);
  };

  useEffect(() => {
    setInpValue(value);
  }, [value]);

  return type !== InputType.TEXTAREA ? (
    <div className={`${cls['input-wrapper']} ${wrapperClassName} input`}>
      <input
        readOnly={readOnly}
        pattern={pattern}
        {...register}
        className={`input ${cls['input']} ${type && cls[type]} ${className} ${
          disabled && cls['input-disabled']
        }`}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        type={inputType}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        step={step}
        // value={inpValue}
        // onChange={onChangeInp}
        {...(onChange
          ? {
              value: inpValue,
              onChange: onChangeInp,
            }
          : {})}
      />
      <ErrorExtra errorText={errorText} />
    </div>
  ) : (
    <>
      <textarea
        {...register}
        disabled={disabled}
        required={required}
        className={`${type && cls[type]} ${className}`}
        placeholder={placeholder}
        minLength={min}
        maxLength={max}
        // value={inpValue}
        // onChange={onChangeInp}
        {...(onChange
          ? {
              value: inpValue,
              onChange: onChangeInp,
            }
          : {})}
      />
      <ErrorExtra errorText={errorText} />
    </>
  );
};
