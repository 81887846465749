import { Changes } from '../../pages/SettingsHistoryPage/components/SettingsHistoryTable/useValidStringByChange';

interface User {
  id: number;
  email: string;
  name: string;
  role: string;
}

export interface IHistoryItem {
  action_method: string;
  changes: null | Changes;
  id: number;
  message: null | string;
  model: string;
  model_id: number;
  user: User;
  ip: string;
  created_at: string;
}

export interface ActionMethodResponse {
  code: number;
  data: [string];
}
export interface Log {
  created_at: string;
  description: string;
  id: number;
  type: string;
}

export enum EnumHistoryTypes {
  created = 'created',
  read = 'read',
}

