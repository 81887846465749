import { useTranslation } from 'react-i18next';
import { useLoginMutation } from 'src/services/Auth';
import { IAuthLoginBody } from 'src/models/IAuth';
import { IAuthResponse } from 'src/models/IAuth';
import { useNavigate } from 'react-router-dom';
import { setUser, updateToken } from 'src/redux/Auth';
import { PAGES_ENUM } from 'src/routes';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import useSounds from 'src/hooks/useSounds';
import { toast } from 'react-toastify';
import { useState } from 'react';
import volumeHandler from 'src/utils/helpers/sound/volumeHandler';
import { useLoginEventMutation } from 'src/services/pytonSSE';

const useSignIn = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sign-in' });
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const [login] = useLoginMutation();
  const [loginEvent] = useLoginEventMutation();
  const { playLoginSound, playErrorSound } = useSounds();
  const [canResetPassword, setCanResetPassword] = useState<boolean>(false);

  const handleSignIn = async (values: IAuthLoginBody) => {
    try {
      const data = (await login(values).unwrap()) as IAuthResponse;
      if (data?.token) {
        await loginEvent({
          newUserAgentLogin: values.login,
          user_id: data.user.id,
          device_os: values.device_os,
        });

        const userData = data.user;
        const token = data.token;

        if (userData && token) {
          dispatch(setUser(userData));
          dispatch(updateToken(token));
          navigate(PAGES_ENUM.HOME);
          if (!userData?.muted_sound)
            playLoginSound(volumeHandler(userData?.sounds?.login_volume));
        }
        // window.location.reload();
      }
    } catch (error: any) {
      setCanResetPassword(!!error?.data?.data?.can_reset_password);
      playErrorSound();
      toast.error(t('login-error'));
    }
  };

  return { handleSignIn, canResetPassword };
};
export default useSignIn;
