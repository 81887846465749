import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { ListResult } from 'src/models/types';
import {
  onOrderCreate,
  onOrderUpdate,
  onTtnDelete,
} from 'src/redux/slices/ordersSlce';

export const ordersApi = createApi({
  reducerPath: 'orders',
  baseQuery,
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    postOrder: builder.mutation({
      query: (params) => ({
        url: 'orders',
        method: 'POST',
        body: params,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
          .then((res) => dispatch(onOrderCreate(res.data.data)))
          .catch(() => true);
      },
      // invalidatesTags: ['Orders'],
    }),
    postTTN: builder.mutation({
      query: (params) => ({
        url: '/orders/import',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),
    postClose: builder.mutation({
      query: (id) => ({
        url: `orders/${id}/close`,
        method: 'GET'
      }),
    }),
    updateOrder: builder.mutation({
      query: (params) => ({
        url: `orders/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
          .then((res) => dispatch(onOrderUpdate(res.data.data)))
          .catch(() => true);
      },
      // invalidatesTags: ['Orders'],
    }),
    deleteTtn: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'orders/ttns',
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['Orders'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
          .then(() => dispatch(onTtnDelete(arg)))
          .catch(() => true);
      },
    }),
    restoreOrders: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'orders/restore',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),
   
    // NOVA POSHTA

    postReturnTTN: builder.mutation({
      query: (params) => ({
        url: 'orders/return/ttn',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),

    getReturnReason: builder.query<
      ListResult<{ Description: string; Ref: string }>,
      any
    >({
      query: () => ({ url: 'api/np/return/reasons', method: 'POST' }),
    }),
    getReturnSubReason: builder.query<
      ListResult<{ Description: string; ReasonRef: string; Ref: string }>,
      any
    >({
      query: (params) => ({
        url: `api/np/return/${params.reason}/subreasons`,
        method: 'POST',
      }),
    }),

    // OTHER
    getPhone: builder.mutation<any, any>({
      query: (params) => ({ url: `orders/${params.id}/phone`, method: 'GET' }),
    }),
    checkPhone: builder.mutation<any, any>({
      query: (params) => ({
        url: 'orders/phones',
        method: 'POST',
        body: params,
      }),
    }),

    postSMS: builder.mutation({
      query: (params) => ({
        url: 'orders/sms',
        method: 'POST',
        body: params,
      }),
    }),

    // CART
    cleanCart: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'orders/cart/delete',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),
    clearCart: builder.mutation({
      query: () => ({
        url: 'orders/cart/clear',
        method: 'DELETE',
      }),
      invalidatesTags: ['Orders'],
    }),
  }),
});

export const {
  usePostOrderMutation,
  usePostSMSMutation,
  usePostTTNMutation,
  usePostReturnTTNMutation,
  useUpdateOrderMutation,
  useCleanCartMutation,
  useRestoreOrdersMutation,
  useClearCartMutation,
  useGetPhoneMutation,
  useDeleteTtnMutation,
  useGetReturnReasonQuery,
  useGetReturnSubReasonQuery,
  useCheckPhoneMutation,
  usePostCloseMutation,
} = ordersApi;
