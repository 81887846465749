import React, { useState } from 'react';
import cls from './ConstactsPopup.module.scss';
import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FacebookIcon } from 'src/assets/img/icons/socials/facebook.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/img/icons/socials/telegram.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/img/icons/socials/instagram.svg';
import { ReactComponent as Logo } from 'src/assets/img/icons/logo.svg';
import { ReactComponent as YoutubeIcon } from 'src/assets/img/icons/socials/youtube.svg';
import { ReactComponent as ViberIcon } from 'src/assets/img/icons/socials/viber.svg';
import { ReactComponent as Telegram2Icon } from 'src/assets/img/icons/socials/telegram-2.svg';
import { ReactComponent as KievstarIcon } from 'src/assets/img/icons/socials/kievstar.svg';
import { ReactComponent as VodafoneIcon } from 'src/assets/img/icons/socials/vodafone.svg';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import ProgramInfoPopup from '../ProgramInfoPopup/ProgramInfoPopup';

export interface IProp extends IPopupPropsExport {}
const ContactsPopup = ({ show, close }: IProp) => {
  const { t } = useTranslation('translation', { keyPrefix: 'contacts-popup' });
  const [programInfoPopupShow, setProgramInfoPopupShow] =
    useState<boolean>(false);
  const contactsData = [
    {
      icon: <FacebookIcon />,
      link: 'facebook.com/lpcrm',
    },
    {
      icon: <TelegramIcon />,
      link: 't.me/lp_crm_biz',
    },
    {
      icon: <InstagramIcon />,
      link: '@lp_crm.biz',
    },
    {
      icon: <Logo />,
      link: 'http://blog.lp-crm.biz',
    },
    {
      icon: <YoutubeIcon />,
      link: 'Youtube канал',
    },
  ];
  const supportData = [
    {
      icon: <ViberIcon />,
      link: 'lpcrmsupport - viber',
    },
    {
      icon: <Telegram2Icon />,
      link: '@lpcrm support bot',
    },
  ];
  const supportDataGroupped = {
    time: '9:00 - 21:00',
    elements: [
      {
        icon: <KievstarIcon />,
        link: '+38 (067) 650-16-55',
      },
      {
        icon: <VodafoneIcon />,
        link: '+38 (050) 451-70-82',
      },
    ],
  };

  return (
    <Popup
      show={show}
      close={close}
      title={t('title')}
      width="550px"
      mobileStyle={{ noPaddings: true }}
    >
      <div className={cls['wrapper']}>
        <div className={cls['list']}>
          {contactsData.map((item) => (
            <div key={item.link} className={cls['item']}>
              <div className={cls['image']}>{item.icon}</div>
              <div className={cls['link']}>
                <a className={cls['link-text']} href="#">
                  {item.link}
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className={cls['title']}>{t('support')}</div>
        <div className={cls['list']}>
          {supportData.map((item) => (
            <div key={item.link} className={cls['item']}>
              <div className={cls['image']}>{item.icon}</div>
              <div className={cls['link']}>
                <a className={cls['link-text']} href="#">
                  {item.link}
                </a>
              </div>
            </div>
          ))}
        </div>
        {/* <div className={ cls['list-groupped']}> */}
        <div className={`${cls['list']} ${cls['list-groupped']}`}>
          {supportDataGroupped.elements.map((item) => (
            <div key={item.link} className={cls['item']}>
              <div className={cls['image']}>{item.icon}</div>
              <div className={cls['link']}>
                <div className={cls['link-text']}>{item.link}</div>
              </div>
            </div>
          ))}
          <div className={cls['list-groupped__time']}>
            {supportDataGroupped.time}
          </div>
        </div>
        <div className="flex-center mt-25">
          <Button
            theme={ButtonTheme.LINK}
            onClick={() => {
              setProgramInfoPopupShow(true);
            }}
          >
            {t('button')}
          </Button>
        </div>
        <ProgramInfoPopup
          show={programInfoPopupShow}
          close={() => {
            setProgramInfoPopupShow(false);
          }}
        />
      </div>
    </Popup>
  );
};
export default ContactsPopup;
