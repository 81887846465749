import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPointQuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      d="m8.293 8.74-.113-.074a.67.67 0 0 0-.727.147.667.667 0 0 0-.2.473q.002.131.054.254c.071.16.2.288.36.36q.121.051.253.053a.667.667 0 0 0 .473-.2.667.667 0 0 0 0-.94zM7.92 4a1.83 1.83 0 0 0-1.587.88.667.667 0 1 0 1.154.666.51.51 0 0 1 .433-.213.5.5 0 0 1 0 1 .667.667 0 0 0 0 1.333A1.833 1.833 0 1 0 7.92 4m5.72 2.453A5.666 5.666 0 0 0 4.867 2.24 5.7 5.7 0 0 0 2.36 6.42 5.64 5.64 0 0 0 4 10.973l3.533 3.54a.666.666 0 0 0 .947 0l3.52-3.54a5.64 5.64 0 0 0 1.64-4.553zm-2.573 3.58L8 13.1l-3.067-3.067a4.33 4.33 0 0 1-1.246-3.48 4.38 4.38 0 0 1 1.926-3.22A4.313 4.313 0 0 1 11.71 4.72c.333.555.539 1.176.603 1.82a4.33 4.33 0 0 1-1.246 3.493"
    />
  </svg>
);
export default SvgMapPointQuestionIcon;
