import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7}
    height={10}
    fill="none"
    viewBox="0 0 7 10"
    {...props}
  >
    <path
      fill="#FFC627"
      d="m6.107 5.991-2.93-.658A1.622 1.622 0 1 1 4.4 5.125a.04.04 0 0 0 0 .04.04.04 0 0 0 .031.03l1.302.298a.08.08 0 0 0 .08-.03A2.844 2.844 0 0 0 3.707.888h-.12A3.249 3.249 0 0 0 .88 6l2.6 3.733a.06.06 0 0 0 .049.027.05.05 0 0 0 .049-.027L6.142 6.08a.06.06 0 0 0 0-.062.06.06 0 0 0-.035-.027"
    />
  </svg>
);
export default SvgUpIcon;
