import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTabletMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.999 4.167h-10c-.92 0-1.667.746-1.667 1.666v10c0 .92.746 1.667 1.667 1.667h10c.92 0 1.666-.746 1.666-1.667v-10c0-.92-.746-1.666-1.666-1.666M13.332 2.5v3.333M6.668 2.5v3.333M3.332 9.167h13.333M8.332 13.333h3.333"
    />
  </svg>
);
export default SvgTabletMinusIcon;
