import React, { useLayoutEffect, useRef, useState } from 'react';
import Tooltip, { ITooltipProps } from '../../Tooltip/Tooltip';
import { ITableDataItemElement } from '../Table';
interface IProps extends ITooltipProps {
  cellData: ITableDataItemElement;
}
const TableTooltip = ({ message, cellData }: IProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [disabledTooltip, setDisableTooltip] = useState<boolean>(true);
  useLayoutEffect(() => {
    if (tooltipRef && tooltipRef.current) {
      const closestTd = tooltipRef.current?.closest('td');
      if (!closestTd || !cellData) return;
      if (cellData.text) {
        setDisableTooltip(
          !(closestTd.scrollWidth - 8 > tooltipRef.current.offsetWidth)
        );
      } else {
        const content: any = (closestTd?.firstChild ||
          tooltipRef.current) as HTMLDivElement;
        if (!content || typeof content.querySelector !== 'function') return;
        if (content.querySelector('.menu')) {
          setDisableTooltip(true);
          return;
        }
        if (content) {
          let spanWidth = 0;
          content.querySelectorAll('span').forEach((span: any) => {
            spanWidth = spanWidth + span.scrollWidth;
          });

          const scrollWidth =
            content.querySelector('span') && spanWidth
              ? spanWidth +
                (content.querySelector('.icon')?.scrollWidth || 0) +
                (content.querySelector('span')
                  ? +window
                      .getComputedStyle(
                        content.querySelector('span') as Element
                      )
                      .getPropertyValue('margin-left')
                      .replace('px', '') || 0

                  : 0)
              : content.scrollWidth;

          setDisableTooltip(!(scrollWidth > tooltipRef.current.offsetWidth));
        }
      }
    } else {
      setDisableTooltip(true);
    }
  }, []);

  return (
    <Tooltip
      open={!disabledTooltip}
      customRef={tooltipRef}
      className="table-tooltip"
      dropdownClassName={'table-tooltip-message'}
      message={message}
      maxWidth={300}
    />
  );
};
export default TableTooltip;
