import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { LogoIcon, LogoWhiteIcon } from 'src/assets/icons/web';
type LoaderProps = {
  size?: number;
  isInButton?: boolean;
};
const CrmLoader: FC<LoaderProps> = ({ size = 80, isInButton }) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        animation: 'spin 2s linear 0s infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(0deg)',
          },
        },
      }}
    >
      {isInButton ? (
        <LogoWhiteIcon width={size} height={size} />
      ) : (
        <LogoIcon width={size} height={size} />
      )}
    </Box>
  );
};

export default CrmLoader;
