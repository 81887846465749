import React, { ReactNode } from 'react';
import './Popup.scss';
import { ReactComponent as PopupCloseIcon } from 'src/assets/img/icons/popup-close-icon.svg';
import { Button, ButtonTheme } from '../Button/Button';
import { IUseAddEditPopupReturned } from 'src/hooks/useAddEditPopup';

export interface IPopupPropsExport {
  close: () => void;
  show: boolean;
  useAddEditPopupReturned?: IUseAddEditPopupReturned;
  message?: string;
  title?: string;
}
export interface IPopupProps extends IPopupPropsExport {
  title?: string;
  children: ReactNode;
  width?: string;
  header?: ReactNode;
  mobileStyle?: {
    noPaddings?: boolean;
    fixedWidth?: boolean;
  };
  closeClass?: string;
  className?: string;
}
export const Popup = ({
  close,
  show,
  title,
  children,
  width = '600px',
  header,
  mobileStyle,
  closeClass = '',
  className,
}: IPopupProps) => {
  // const closeHandler = useCallback(
  //   (event: React.MouseEvent<HTMLDivElement>) => {
  //     if (
  //       event.target instanceof Element &&
  //       event.target.classList.contains('popup__overlay') &&
  //       !event.target.querySelector('.popup__overlay.show')
  //     ) {
  //       event.stopPropagation();
  //       close();
  //     }
  //   },
  //   [close, children]
  // );
  if (!show) return null;
  return (
    <div
      className={`popup__overlay ${show && 'show'} ${
        mobileStyle?.noPaddings && 'popup__overlay-mobile-no-paddings'
      } ${
        mobileStyle?.fixedWidth && 'popup__overlay-mobile-fixed-width'
      } disable-enter-handler ${className} `}
      data-disable-enter-handler={show ? 1 : 0}
      // onClick={closeHandler}
    >
      <div
        className="popup__padding"
        style={{ width: width, maxWidth: '100%' }}
      >
        <div className="popup__wrapper">
          <div
            className="popup__header"
            style={mobileStyle?.fixedWidth ? { width: width } : {}}
          >
            {header || <div className="popup__title">{title}</div>}
            <Button
              theme={ButtonTheme.TRANSPARENT}
              className={`${closeClass} popup__header-close-button`}
              onClick={close}
              buttonType="button"
            >
              <PopupCloseIcon />
            </Button>
          </div>
          <div className="popup-mobile-fix">
            <div
              className="popup__body"
              style={mobileStyle?.fixedWidth ? { width: width } : {}}
            >
              <div className="popup__body-inner">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
