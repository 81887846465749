import React from 'react';
import { IFilter, IFilterOptions, IThConfig } from '../Table';
import {
  IOption,
  IOptionValue,
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from '../../Select/Select';
import { useTranslation } from 'react-i18next';

interface IProps {
  item: IFilter;
  thConfig: IThConfig;
  filterOptions?: IFilterOptions;
  itemIndex: number;
  onChange: (value: IOptionValue) => void;
  propsValue: string;
}

const DefaultSelect = ({
  item,
  thConfig,
  filterOptions,
  itemIndex,
  onChange,
  propsValue,
}: IProps) => {
  const { t: tg } = useTranslation('translation');
  let controllerTheme = SelectControllerTheme.FROM_TABLE;
  if (item.config?.controllerTheme) {
    controllerTheme = item.config.controllerTheme;
  }
  const dropdownTheme = SelectDropdownTheme.FROM_TABLE;
  return (
    <div>
      <Select
        controllerTheme={controllerTheme}
        dropdownTheme={dropdownTheme}
        hasSearch={item.config?.hasSearch===undefined || item.config.hasSearch}
        onChange={onChange}
        propsValue={propsValue}
        disablePortal={false}
        stylesConfig={{
          isWidth100: true,
        }}
        isNoValueAllText
        options={
          [
            {
              title: null,
              value: null,
              element: (
                <div>
                  <span className="hidden-part">{tg('select-all')}</span>
                </div>
              ),
            },
            ...( filterOptions?.[item.innerOptionsId || thConfig.columnId] as IOption[] || [])
          ] as IOption[]
        }
        {...item.config}
      />
    </div>
  );
};

export default DefaultSelect;