import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult } from '../../models/types';
import * as I from 'src/models/settings/blockedIp';

export const blockedIpSlice = createApi({
  reducerPath: 'blockedIp',
  baseQuery: baseQuery,
  tagTypes: ['BlockedIps'],

  endpoints: (builder) => ({
    getBlockedIps: builder.query<
      PagListResult<I.BlockedIp>,
      I.BlockedIpsParams
    >({
      query: (params: I.BlockedIpsParams) => ({ url: 'ip/', params }),
      providesTags: ['BlockedIps'],
    }),
    updateBlockedIp: builder.mutation<unknown, I.BlockedIpUpdate>({
      query: ({ id, body }) => ({
        url: `ip/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BlockedIps'],
    }),
    deleteBlockedIp: builder.mutation<unknown, any>({
      query: (body) => ({
        url: `/ip/delete`,
        method: 'POST',
        body:body,
      }),
      invalidatesTags: ['BlockedIps'],
    }),
    postBlockedIp: builder.mutation<unknown, I.BlockedIpPostRequest>({
      query: (body) => ({
        url: `ip/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BlockedIps'],
    }),
  }),
});

export const {
  useGetBlockedIpsQuery,
  useUpdateBlockedIpMutation,
  useDeleteBlockedIpMutation,
  usePostBlockedIpMutation,
} = blockedIpSlice;
