import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M8 9.336a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="m11.773 11.105-2.829 2.828a1.333 1.333 0 0 1-1.885 0L4.23 11.105a5.334 5.334 0 1 1 7.543 0"
    />
  </svg>
);
export default SvgMapPinIcon;
