export enum NOTIFICATIONS_TYPES_ENUM {
  REGULAR = 'REGULAR',
  NEW_ORDER_RECALL = 'NEW_ORDER_RECALL',
  LOGIN = 'LOGIN',
}
export enum TARGET_NOTIFICATION {
  SELF = 'SELF',
  ALL = 'ALL',
}

export interface Notification {
  add_date_time: string;
  date_time: string;
  description: string;
  tenant_id: string;
  id: number;
  is_read: boolean;
  sent: boolean;
  is_done: boolean;
  order_id: number | null;
  target: TARGET_NOTIFICATION;
  type: NOTIFICATIONS_TYPES_ENUM;
  newUserAgentLogin: string
}
