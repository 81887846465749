import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
    viewBox="0 0 15 14"
    {...props}
  >
    <path
      fill="#DA291C"
      d="M6.797 3.6v2.42h1.75V3.6h1.75l-1.89-1.914a.98.98 0 0 0-1.4 0L5.105 3.599zM4.73 3.979 2.04 6.65a.583.583 0 0 0 0 .782l2.678 2.672V3.979zM13.392 6.65l-2.76-2.73v6.253l2.73-2.73a.583.583 0 0 0 .03-.793M8.573 10.57V8.167h-1.75v2.415h-1.64L6.87 12.25a1.225 1.225 0 0 0 1.704 0l1.686-1.691z"
    />
  </svg>
);
export default SvgNpIcon;
