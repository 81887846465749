import React, { useState } from 'react';
import cls from './Layout.module.scss';
import { CustomerAgentIcon, UserIcon } from 'src/assets/icons/scins';
import UserDropdown from './UserBar/UserDropdown/UserDropdown';
import ContactsPopup from './UserBar/ContactsPopup/ContactsPopup';
import { useTranslation } from 'react-i18next';
import Tooltip from '../UI/Tooltip/Tooltip';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import Notifications from './UserBar/Notifications';
import useUserInfo from 'src/hooks/user/useUserInfo';
import LogoIcon from 'src/assets/icons/web/LogoIcon';
import GearCancelIcon from 'src/assets/icons/gears/GearCancelIcon';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { resetFilters } from 'src/redux/slices/tableDataOperations';

const Header = () => {
  useUserInfo();
  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  const [userDropdownShow, setUserDropdownShow] = useState<boolean>(false);
  const [contactsPopupShow, setContactsPopupShow] = useState<boolean>(false);
  const isAuth = useTypedSelector(selectors.getUserId);
  const hasFilters = useAppSelector(selectors.getHasFilters);
  const dispatch = useAppDispatch();
  const resetParamsHandler = () => {
    dispatch(resetFilters());
  };
  return (
    <header className={cls.header}>
      <div className={cls.logo}>
        <LogoIcon />
      </div>

      {isAuth && (
        <div className={cls.user_bar}>
          <div className="header-portal" />
          <Tooltip title={t('t-title-reset-params')}>
            <GearCancelIcon className={`header-icon ${cls['reset-params']} ${hasFilters && cls['active']}`} onClick={resetParamsHandler} />
          </Tooltip>
          <Tooltip title={t('t-title-profile')}>
            <UserIcon
              className={'header-icon stroke-path dropdown-control'}
              onClick={() => setUserDropdownShow(true)}
            />
          </Tooltip>
          <Notifications />
          <Tooltip title={t('t-title-contacts')}>
            <CustomerAgentIcon
              className={'header-icon stroke-path'}
              onClick={() => {
                setContactsPopupShow(true);
              }}
            />
          </Tooltip>
        </div>
      )}
      {userDropdownShow && (
        <UserDropdown
          show={userDropdownShow}
          close={() => {
            setUserDropdownShow(false);
          }}
        />
      )}

      {contactsPopupShow && (
        <ContactsPopup
          show={contactsPopupShow}
          close={() => {
            setContactsPopupShow(false);
          }}
        />
      )}
    </header>
  );
};
export default Header;
