import React from 'react';
import { IFilter, IFilterOptions, IThConfig } from '../Table';
import {
  IOption,
  IOptionValue,
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from '../../Select/Select';
import { useGetCountriesQuery } from 'src/services/settings/country/goCountry';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'src/components/common/CountryFlag';

interface IProps {
  item: IFilter;
  thConfig: IThConfig;
  filterOptions?: IFilterOptions;
  itemIndex: number;
  onChange: (value: IOptionValue) => void;
  propsValue: number;
  disablePortal?: boolean
}

const CountrySelect = ({
  item,

  onChange,
  propsValue,
  disablePortal=true
}: IProps) => {
  const { t: tg } = useTranslation('translation');

  let controllerTheme = SelectControllerTheme.FROM_TABLE;
  if (item.config?.controllerTheme) {
    controllerTheme = item.config.controllerTheme;
  }
  const dropdownTheme = SelectDropdownTheme.FROM_TABLE;
  const { data } = useGetCountriesQuery({ is_active: 1 });

  return (
    <div>
      <Select
        controllerTheme={controllerTheme}
        dropdownTheme={dropdownTheme}
        hasSearch={
          item.config?.hasSearch === undefined || item.config.hasSearch
        }
        disablePortal={disablePortal}
        onChange={onChange}
        propsValue={propsValue}
        stylesConfig={{
          isWidth100: true,
        }}
        options={
          [
            {
              title: null,
              value: null,
              element: (
                <div>
                  <span className="hidden-part">{tg('select-all')}</span>
                </div>
              ),
            },
            ...(data?.countries || [])
              .filter((item) => item.is_active)
              .map((country) => ({
                title: null,
                value: country.name.en,
                searchValue: country.name.ua,
                element: (
                  <div className="align-center">
                    <CountryFlag 
                      emoji={country.emoji}
                      nameEn={country.name.en}
                      iso2={country.iso2}
                    />
                    <span>
                      {country.name.ua}
                    </span>
                  </div>
                ),
              })),
          ] as IOption[]
        }
      />
    </div>
  );
};

export default CountrySelect;
