import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult, Payment } from '../../models/types';

type paymentParams = {
  name: string;
  image: any;
  id: string;
};

export const paymentSlice = createApi({
  reducerPath: 'paymentTypes',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPaymentTypes: builder.query<PagListResult<Payment>, any>({
      query: () => 'paymentTypes',
    }),
    postPaymentType: builder.mutation({
      query: (params: paymentParams) => ({
        url: 'paymentTypes',
        method: 'POST',
        body: params,
      }),
    }),
    updatePaymentType: builder.mutation({
      query: (params) => ({
        url: `paymentTypes/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
    }),
    deletePaymentType: builder.mutation({
      query: (id: number) => ({
        url: `paymentTypes/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetPaymentTypesQuery } = paymentSlice;
