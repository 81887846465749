import { createApi } from '@reduxjs/toolkit/query/react';
import { PagListResult, Payment } from '../../../models/types';
import {getBaseQueryForGoLang} from '../../../utils/baseQuery';


export const goPaymentSlice = createApi({
  reducerPath: 'goPaymentTypes',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getPaymentTypes: builder.query<PagListResult<Payment>, any>({
      query: () => 'paymentTypes',
    }),
  }),
});

export const { useGetPaymentTypesQuery } = goPaymentSlice;
