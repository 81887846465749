import { useTranslation } from 'react-i18next';
import { NOTIFICATIONS_TYPES_ENUM, TARGET_NOTIFICATION } from 'src/models/notifications';

const useNotificationsTranslate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notifications-maps',
  });
  const notificationTypesMap = {
    [NOTIFICATIONS_TYPES_ENUM.REGULAR]: t('REGULAR'),
    [NOTIFICATIONS_TYPES_ENUM.NEW_ORDER_RECALL]: t('NEW_ORDER_RECALL'),
  };
  
  const notificationsList = [
    { value: NOTIFICATIONS_TYPES_ENUM.REGULAR, text: notificationTypesMap[NOTIFICATIONS_TYPES_ENUM.REGULAR] },
    {
      value: NOTIFICATIONS_TYPES_ENUM.NEW_ORDER_RECALL,
      text: notificationTypesMap[NOTIFICATIONS_TYPES_ENUM.NEW_ORDER_RECALL],
    },
  ];
  
  const notificationsTargetList = [
    { value: TARGET_NOTIFICATION.SELF, text: t('SELF') },
    { value: TARGET_NOTIFICATION.ALL, text: t('ALL') },
  ];
  return {notificationTypesMap, notificationsList, notificationsTargetList}
}
export default useNotificationsTranslate