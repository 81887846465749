import { createApi } from '@reduxjs/toolkit/query/react';
import { Country } from 'src/models/countries';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';

export const goCountriesSlice = createApi({
  reducerPath: 'goCountries',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (params) => ({
        url: 'countries',
        method: 'GET',
        params,
      }),
      transformResponse: (data: { data: Array<Country> }) => ({
        countries: data.data,
      }),
    }),
  }),
});
export const { useGetCountriesQuery } = goCountriesSlice;
