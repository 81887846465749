import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    viewBox="0 0 14 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M1 15v-.875C1 10.742 3.686 8 7 8s6 2.742 6 6.125V15M7.003 8c1.893 0 3.428-1.567 3.428-3.5S8.896 1 7.003 1 3.574 2.567 3.574 4.5 5.11 8 7.003 8"
    />
  </svg>
);
export default SvgUserIcon;
