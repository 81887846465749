import React, { FC } from 'react';
import Routes from './routes';
import cls from './components/layout/Layout.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import HeaderContainer from './components/layout/HeaderContainer';

const App: FC = () => (
  <div className={`${cls['layout']} `}>
    <HeaderContainer />
    <Routes />
    <ToastContainer position={toast.POSITION.TOP_CENTER} hideProgressBar />
  </div>
);

export default App;
