import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHandsetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.583 3.667H8.25l1.833 4.583-2.291 1.375a10.1 10.1 0 0 0 4.583 4.583l1.375-2.291 4.583 1.833v3.667A1.833 1.833 0 0 1 16.5 19.25 14.666 14.666 0 0 1 2.75 5.5a1.833 1.833 0 0 1 1.833-1.833"
    />
  </svg>
);
export default SvgHandsetIcon;
