import React, { FC, ReactNode } from 'react';

type TooltipBodyProps = {
  title: string;
  massage: string | ReactNode;
};

const TooltipBody: FC<TooltipBodyProps> = ({ title, massage }) => {
  return (
    <div>
      <p>{title}</p>
      <div>{massage}</div>
    </div>
  );
};

export default TooltipBody;
