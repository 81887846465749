import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIdListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    viewBox="0 0 16 14"
    {...props}
  >
    <path fill="#fff" d="M0 0h16v14H0z" />
    <path
      fill="#111"
      d="M8.457 3.582c0-.408.158-.738.352-.738h6.813c.194 0 .352.33.352.738 0 .407-.158.738-.352.738H8.809c-.194 0-.352-.33-.352-.738M.941 8.423c0-.402.316-.728.706-.728h13.647c.39 0 .706.326.706.728a.717.717 0 0 1-.706.728H1.647a.717.717 0 0 1-.706-.728M1.647 12.547a.717.717 0 0 0-.706.727c0 .402.316.728.706.728h13.647c.39 0 .706-.326.706-.728a.717.717 0 0 0-.706-.727zM1.717 1.182V7h-.532V1.182zM4.967 7H3.264V1.182H5.07q.833 0 1.424.346.592.347.909.995.315.648.315 1.554 0 .915-.324 1.57-.32.654-.937 1.004Q5.843 7 4.967 7m-1.172-.477h1.142q.75 0 1.255-.296t.759-.843q.252-.55.253-1.307-.003-.753-.25-1.296a1.8 1.8 0 0 0-.728-.832q-.48-.29-1.187-.29H3.795z"
    />
  </svg>
);
export default SvgIdListIcon;
