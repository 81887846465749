import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { EnumPostTypes } from 'src/models/enums';

type PrintParams = {
  type: EnumPostTypes | string;
  ids: Array<number>;
  zebra: boolean;
};

export type Print = {
  count: number;
  name: string;
  urls: URLs;
};
type URLs = {
  one_marking_route: string;
  one_ttn_route: string;
  one_zebra_route: string;
  more_ttn_route: string;
  more_marking_route: string;
  more_zebra_route: string;
};

export const invoicesSlice = createApi({
  reducerPath: 'invoices',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPrint: builder.query<Array<Print>, any>({
      query: (params: PrintParams) => ({
        url: `orders/invoices/print/${params.type}`,
        method: 'GET',
        params: { 'ids[]': params.ids },
      }),
    }),
    getUPPrint: builder.query<any, any>({
      query: (params) => ({
        url: 'orders/invoices/print/Ukrposhta',
        method: 'GET',
        params,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const { useGetPrintQuery, useGetUPPrintQuery, useLazyGetUPPrintQuery } = invoicesSlice;
