import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { Call, Record, UniTalk } from 'src/models/settings/unitalk';
import { PagListResult } from 'src/models/types';

export const uniTalkSlice = createApi({
  reducerPath: 'uniTalk',
  baseQuery: baseQuery,
  tagTypes: ['UniTalk'],

  endpoints: (builder) => ({
    postUTSipCall: builder.mutation<any, any>({
      query: (params) => ({
        url: 'api/unitalk/sipCall',
        method: 'POST',
        body: params,
      }),
    }),
    getGroups: builder.query<Array<Call>, any>({
      query: () => ({
        url: 'api/unitalk/calls',
        method: 'POST',
      }),
    }),
    getRecords: builder.query<PagListResult<Record>, any>({
      query: (params) => ({
        url: 'api/unitalk/getCallsWithLink',
        method: 'GET',
        params,
      }),
    }),
    getCallsHistory: builder.query<any, any>({
      query: () => ({
        url: 'api/unitalk/history-calls',
        method: 'POST',
      }),
    }),
    getUniTalk: builder.query<PagListResult<UniTalk>, any>({
      query: () => ({
        url: 'api/unitalk/crm',
        method: 'GET',
      }),
    }),
    postRecalling: builder.mutation<any, any>({
      query: (params) => ({
        url: 'api/unitalk/crm',
        method: 'POST',
        body: params,
      }),
    }),
    updateRecalling: builder.mutation<any, any>({
      query: (params) => ({
        url: `api/unitalk/crm/${params.id}`,
        method: 'PUT',
        body: params,
      }),
    }),
    postUTPhone: builder.mutation<any, any>({
      query: (params) => ({
        url: 'api/unitalk/add-phone',
        method: 'POST',
        body: params,
      }),
    }),
    deleteUTPhone: builder.mutation<any, any>({
      query: (params) => ({
        url: 'api/unitalk/deletePhones',
        method: 'POST',
        body: params,
      }),
    }),
    postUTCall: builder.mutation<any, any>({
      query: () => ({
        url: 'api/unitalk/action-call',
        method: 'POST',
      }),
    }),
    postUTRefresh: builder.mutation<any, any>({
      query: (params) => ({
        url: 'api/unitalk/refresh',
        method: 'POST',
        body: params,
      }),
    }),

    // call directing
    postCancelCall: builder.mutation<any, any>({
      query: (id) => ({
        url: 'api/unitalk/cancel',
        method: 'POST',
        body: id,
      }),
    }),
    postPauseCall: builder.mutation<any, any>({
      query: (id) => ({
        url: 'api/unitalk/pause',
        method: 'POST',
        params: id,
      }),
    }),
    postUnpauseCall: builder.mutation<any, any>({
      query: (id) => ({
        url: 'api/unitalk/unpause',
        method: 'POST',
        params: id,
      }),
    }),
    postDeleteAllCalls: builder.mutation<any, any>({
      query: (id) => ({
        url: 'api/unitalk/deleteAllCalls',
        method: 'POST',
        body: id,
      }),
    }),
  }),
});

export const {
  usePostUTSipCallMutation,
  usePostUTCallMutation,
  usePostUTPhoneMutation,
  usePostUTRefreshMutation,
  usePostCancelCallMutation,
  usePostPauseCallMutation,
  usePostDeleteAllCallsMutation,
  usePostUnpauseCallMutation,
  useGetGroupsQuery,
  usePostRecallingMutation,
  useGetUniTalkQuery,
  useUpdateRecallingMutation,
  useDeleteUTPhoneMutation,
  useGetRecordsQuery,
} = uniTalkSlice;
