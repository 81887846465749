import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCloseModalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#212121"
      d="M.173.357.258.258a.88.88 0 0 1 1.15-.085l.098.085L5 3.752 8.494.258a.882.882 0 1 1 1.248 1.248L6.248 5l3.494 3.494a.88.88 0 0 1 .085 1.149l-.085.099a.88.88 0 0 1-1.15.085l-.098-.085L5 6.248 1.506 9.742A.882.882 0 0 1 .258 8.494L3.752 5 .258 1.506A.88.88 0 0 1 .173.357"
    />
  </svg>
);
export default SvgCloseModalIcon;
