import React, { useEffect } from 'react';
import {
  Routes as DomRoutes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import { getAuthTokens } from './utils/helpers/authHelper';
import useNavigations from './hooks/routes/useNavigations';
import HomePage from './pages/HomePage/HomePage';
import MainLoader from './components/UI/Loaders/MainLoader';
import EndSessionPage from './pages/SignInPage/EndSessionPage';

export enum PAGES_ENUM {
  HOME = '/',
  ORDERS = '/orders',
  CREATE_ORDER = '/orders/create',
  COPY_ORDER = '/orders/:id',
  UPDATE_ORDER = '/orders/:id/update',
  CLIENTS = '/clients',
  ORDER_STATUSES = '/order-statuses',
  CLIENT_GROUPS = '/client-groups',
  CART = '/cart',
  WAREHOUSE_SUPPLIERS = '/warehouse-suppliers',
  WAREHOUSE_ARRIVAL = '/warehouse-arrival',
  WAREHOUSE_WRITE_OFF = '/warehouse-write-off',
  PRODUCT_MOVEMENT = '/product-movement',
  PARTNERS = '/partners',
  PRODUCTS_PRODUCERS = '/products-producers',
  PRODUCTS_CATEGORIES = '/products-categories',
  PRODUCTS_ATTRIBUTES = '/products-attributes',
  PRODUCTS_ATTRIBUTE_CATEGORY = '/products-attribute-category',
  PRODUCTS = '/products',
  DELIVERY_SERVICE = '/delivery-service',
  REGISTERS = '/registers',
  SMS_TEMPLATES = '/sms-templates',
  SMS_MAILING = '/sms-mailing',
  STATISTICS = '/statistics',
  SETTINGS_SYSTEM = '/settings-system',
  SETTINGS_DEPARTMENTS = '/settings-departments',
  SETTINGS_USER_GROUPS = '/settings-user-groups',
  SETTINGS_USERS = '/settings-users',
  SETTINGS_COUNTRIES = '/settings-countries',
  SETTINGS_CURRENCIES = '/settings-currencies',
  SETTINGS_PAY = '/settings-pay',
  SETTINGS_BLOCKED_IP = '/settings-blocked-ip',
  SETTINGS_MODULES = '/settings-modules',
  SETTINGS_HISTORY = '/settings-history',
  FAQ = '/faq',
  FAQ_DOCUMENTATION_API = '/documentation-api',
  EXPIRED_SUBSCRIPTIONS = '/expired-subscription',
  INVOICES = '/invoices',
  REGISTRATION = '/registration',
  SIGN_IN = '/login',
  SESSION_OVER = '/session-over',
  RESET_PASSWORD = '/reset-password',
  UPDATE_PASSWORD = '/users/password',
  UI = 'ui',
}

const Routes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const access_token = getAuthTokens().accessToken;
  const { routes } = useNavigations();
  useEffect(() => {
    if (
      !access_token &&
      ![
        PAGES_ENUM.REGISTRATION,
        PAGES_ENUM.RESET_PASSWORD,
        PAGES_ENUM.UPDATE_PASSWORD,
      ].includes(location.pathname as PAGES_ENUM)
    ) {
      navigate(PAGES_ENUM.SIGN_IN);
    }
  }, [access_token]);
  return (
    <DomRoutes>
      {routes.map((item) => (
        <Route
          path={item.path}
          key={item.path}
          Component={() => {
            if (item.isAvailable) {
              if (item.disableLayout) {
                return (
                  <div className="no-layout-wrapper">{item.component}</div>
                );
              } else {
                return <AppLayout>{item.component}</AppLayout>;
              }
            } else {
              return (
                <AppLayout>
                  {item.isAvailable === undefined ? (
                    <MainLoader />
                  ) : (
                    <HomePage />
                  )}
                </AppLayout>
              );
            }
          }}
        />
      ))}

      <Route path={PAGES_ENUM.SESSION_OVER} element={<EndSessionPage />} />
      <Route path="*" element={<main>Page not found</main>} />
    </DomRoutes>
  );
};

export default Routes;
