import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AsyncSelectEvent,
  ISortField,
  ISortFields,
  SortFieldTypes,
} from 'src/components/UI/Table/Table';
import { ISetFiltersDataConfig } from 'src/components/UI/Table/components/TableFilters';

interface Filters {
  data: {
    [key: string]: any;
  };
  isRefetch: boolean;
}
interface AsyncSelectData {
  [key: string]: {
    isSearched: boolean;
    fullData: any[];
  };
}
export interface TableDataOperations {
  sortFields: ISortFields | null;
  filters: Filters;
  asyncSelectData: AsyncSelectData;
}
const initialFiltersData: Filters = {
  data: {},
  isRefetch: false,
};
const initialState: TableDataOperations = {
  sortFields: null,
  filters: initialFiltersData,
  asyncSelectData: {},
};

export const tableDataOperationsSlice = createSlice({
  name: 'tableDataOperations',
  initialState,
  reducers: {
    toggleSortField(
      state,
      action: PayloadAction<{ columnId: string; tableName: string }>
    ) {
      const { columnId, tableName } = action.payload;
      let sortFieldData: ISortField | null = null;
      const prevSortField = state.sortFields?.[tableName];
      if (!prevSortField || prevSortField.columnId !== columnId) {
        sortFieldData = {
          columnId: columnId,
          type: SortFieldTypes.ASC,
        };
      } else if (prevSortField.type === SortFieldTypes.ASC) {
        sortFieldData = {
          columnId: columnId,
          type: SortFieldTypes.DESC,
        };
      }
      state.sortFields = {
        ...(state.sortFields || {}),
        [tableName]: sortFieldData,
      };
    },
    setFilter(
      state,
      action: PayloadAction<{
        key?: string;
        value?: string | number | null;
        config?: ISetFiltersDataConfig;
        isSearchCall?: boolean;
        asyncSelect?: AsyncSelectEvent;
      }>
    ) {
      const { key, value, config, isSearchCall, asyncSelect } = action.payload;
      if (key) {
        if (config?.dateRangeIndex !== undefined) {
          const prevField = state.filters.data[key]
            ? [...state.filters.data[key]]
            : [null, null];
          if (config?.dateRangeIndex === 1 && !prevField[0]) {
            prevField[0] = value;
          }
          prevField[config.dateRangeIndex] = value;

          state.filters.data[key] = prevField;
        } else {
          if (asyncSelect?.key) {
            state.filters.data[asyncSelect.key] = asyncSelect.value;
            if (state.asyncSelectData[key]) {
              state.asyncSelectData[key].isSearched = false;
            }
          }
          let data = state.filters.data;
          data[key] = value === undefined ? '' : value;
          if (key === 'post_status_id' && value && data) {
            data = {
              ...data,
              delivery_type_id: String(value).length < 4 ? 1 : 2,
            };
          }

          state.filters.data = data;
        }
        if (isSearchCall) {
          state.filters.isRefetch = true;
        }
      }
    },
    setFilters(state, action: PayloadAction<Filters>) {
      state.filters = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        data: {},
        isRefetch: true,
      };
    },
    setFiltersIsRefetch(state, action: PayloadAction<boolean>) {
      state.filters.isRefetch = action.payload;
    },
    clearSortField(state, action: PayloadAction<string>) {
      const tableName = action?.payload;
      if (tableName && state.sortFields?.[tableName]) {
        state.sortFields[tableName] = null;
      }
    },
    clearSortFields(state) {
      state.sortFields = null;
    },
    syncDatesOnPickerClose(
      state,
      action: PayloadAction<{
        key: string;
      }>
    ) {
      const { key } = action.payload;
      const value1 = state.filters.data[key]?.[0];
      const value2 = state.filters.data[key]?.[1];
      if (!value2 && value1) {
        state.filters.data[key][1] = value1;
        state.filters.isRefetch = true;
      }
    },
    setAsyncSelectData(
      state,
      action: PayloadAction<{
        key: string;
        isSearched: boolean;
        fullData?: any[];
      }>
    ) {
      state.asyncSelectData[action.payload.key] = {
        isSearched:
          state.asyncSelectData[action.payload.key]?.isSearched ||
          action.payload.isSearched,
        fullData: action.payload.fullData || [],
      };
    },
    addAsyncSelectData(
      state,
      action: PayloadAction<{
        key: string;
        isSearched: boolean;
        fullData?: any[];
      }>
    ) {
      state.asyncSelectData[action.payload.key] = {
        isSearched:
          state.asyncSelectData[action.payload.key]?.isSearched ||
          action.payload.isSearched,
        fullData: [
          ...(state.asyncSelectData[action.payload.key]?.fullData || []),
          ...(action.payload.fullData || []),
        ],
      };
    },
    clearOperationData(state) {
      state = initialState;
    },
  },
});

export const {
  toggleSortField,
  clearSortField,
  clearSortFields,
  setFilter,
  setFilters,
  resetFilters,
  setFiltersIsRefetch,
  syncDatesOnPickerClose,
  setAsyncSelectData,
  clearOperationData,
  addAsyncSelectData,
} = tableDataOperationsSlice.actions;
