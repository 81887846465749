import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import ProductCategory, {
  CategoryParams,
  ProductCategoriesParams
} from '../../models/products/productCategory';
import { PagListResult } from '../../models/types';

export const productCategoriesSlice = createApi({
  reducerPath: 'productsCategories',
  baseQuery: baseQuery,
  tagTypes: ['Categories'],
  endpoints: (builder) => ({
    getProductCategories: builder.query<PagListResult<ProductCategory>, any>({
      query: (params: ProductCategoriesParams) => ({ url: 'productCategories', method: 'GET', params }),
      providesTags: ['Categories'],
    }),
    postProductCategory: builder.mutation({
      query: (params: CategoryParams) => ({
        url: 'productCategories',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Categories'],
    }),
    updateProductCategory: builder.mutation({
      query: ({ categoryId, params }) => ({
        url: `productCategories/${categoryId}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Categories'],
    }),
    deleteProductCategory: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'productCategories/delete/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Categories'],
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  usePostProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
} = productCategoriesSlice;
