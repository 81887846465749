import React, { FC, useEffect } from 'react';
import cls from './NotificationItem.module.scss';
import { ReactComponent as InfoNotificationIcon } from 'src/assets/img/icons/notifications/info-notification-icon.svg';
import { ReactComponent as CloseIcon } from 'src/assets/img/icons/popup-close-icon.svg';
import useSounds from 'src/hooks/useSounds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PAGES_ENUM } from 'src/routes';

interface AlertProps {}

const LogoutAlert: FC<AlertProps> = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'logout-alert' });
  const navigate = useNavigate();

  const { playWarningSound } = useSounds();
  useEffect(() => {
    playWarningSound();
  }, []);
  return (
    <div
      className={`alert-notification-wrapper ${cls['logout-notification']}}`}
    >
      <div className={cls['logout-notification-header']}>
        <h4 className={cls['logout-notification-title']}>{t('title')}</h4>
        <CloseIcon
          className={cls['close-icon']}
          onClick={() => navigate(PAGES_ENUM.SIGN_IN)}
        />
      </div>
      <div className={cls['logout-notification-footer']}>
        <InfoNotificationIcon />
        <div className={cls['logout-notification-text']}>{t('text')}</div>
      </div>
    </div>
  );
};

export default LogoutAlert;
