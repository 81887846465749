import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type Id = string | number;
interface EventKeys {
  altKey: boolean,
  shiftKey: boolean
}
export interface SelectedRowsState {
  selectedRows: Array<Id>,
  lastSelectedValue: Id | null,
  tableDataIds: Array<Id>
}

const initialState: SelectedRowsState = {
  selectedRows: [],
  lastSelectedValue: null,
  tableDataIds: []
};

export const selectedRowsSlice = createSlice({
  name: 'selectedRows',
  initialState,
  reducers: {
    setSelectedRows(state, action: PayloadAction<any[]>){
      state.selectedRows = action.payload;
    },
    setTableDataIds(state, action: PayloadAction<Array<Id>>){
      state.tableDataIds = action.payload;
      state.selectedRows = [];
    },
    toggleSelectedRows(state, action: PayloadAction<{
      value: string | number,
      event: EventKeys
    }>){
      const {value, event} = action.payload
      let copy = [...state.selectedRows];
      if (event.shiftKey && state.lastSelectedValue) {
        const tableRowsId = [...state.tableDataIds];
        const valueIndex = tableRowsId.findIndex((item) => item === value);
        const lastSelectedValueIndex = tableRowsId.findIndex(
          (item) => item === state.lastSelectedValue
        );
        const res: Array<string | number> = [];
        if (lastSelectedValueIndex > valueIndex) {
          for (
            let index = lastSelectedValueIndex;
            index >= valueIndex;
            index--
          ) {
            res.push(tableRowsId[index]);
          }
        } else if (lastSelectedValueIndex < valueIndex) {
          for (
            let index = lastSelectedValueIndex;
            index <= valueIndex;
            index++
          ) {
            res.push(tableRowsId[index]);
          }
        } else {
          res.push(value);
        }
        copy = res;
      } else {
        if (copy.includes(value)) {
          copy = copy.filter(item => item !== value);
          state.lastSelectedValue = null;
        } else {
          copy = [...copy, value];
          state.lastSelectedValue = value;
        }
      }
      state.selectedRows = copy;
      if (window.getSelection) {
        window?.getSelection()?.removeAllRanges();
      } else {
        // старый IE
        document?.getSelection()?.empty();
      }
    },
    removeSelectedRowId(state, action: PayloadAction<string | number>){
      const value = action.payload;
      if (!value) {
        state.selectedRows = []
        return;
      }
      const tableSelectedRowsIdCopy = [...state.selectedRows];
      tableSelectedRowsIdCopy.splice(
        tableSelectedRowsIdCopy.findIndex((item) => item === value),
        1
      );
      state.selectedRows = tableSelectedRowsIdCopy

    },
    toggleAllSelectedRows(state){
      if (state.selectedRows.length === state.tableDataIds.length){
        state.selectedRows = []
      }else{
        state.selectedRows = state.tableDataIds
      }
    }
  },
});


export const { setSelectedRows, toggleSelectedRows, setTableDataIds, toggleAllSelectedRows, removeSelectedRowId } = selectedRowsSlice.actions;
