import React, { useState } from 'react';
import {
  IFilter,
  IThConfig,
} from '../Table';
import {
  DatePickerComponent,
  DatePickerTheme,
} from '../../DatePickerComponent/DatePickerComponent';
import { ISetFiltersDataConfig } from './TableFilters';
import {useAppDispatch} from "../../../../redux";
import {syncDatesOnPickerClose} from "../../../../redux/slices/tableDataOperations";
interface IProps {
  item: IFilter;
  thConfig: IThConfig;
  filtersData: {
    [key: string]: any;
  },
  setFiltersDataHandler: (key?:string, value?:string|number|null, isSearchCall?: boolean, config?: ISetFiltersDataConfig)=>void
}

const TableDateRange = ({ item, thConfig, filtersData, setFiltersDataHandler }: IProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(syncDatesOnPickerClose({
      key: item.parameterName || thConfig.columnId
    }))
    setOpen(false);
  };
  const dateFirstPicker = filtersData[item?.parameterName || thConfig.columnId]?.[0];
  const dateSecondPicker = filtersData[item?.parameterName || thConfig.columnId]?.[1];
  return (
      <div
        className={`${'table__tr-filter--item'} ${'table__tr-filter--text'} ${item.config?.className}`}
        style={item.config?.style}
      >
        <div>
          <div className="display-flex">
            <DatePickerComponent
              {...item.dataConfig}
              maxDate={dateSecondPicker}
              format="YYYY.MM.DD"
              placeholder={'Дата'}
              className="table__tr-filter-datepicker"
              theme={DatePickerTheme.FROM_TABLE}
              disableFuture
              onChange={(value) => {
                const datePickerValue2 = filtersData[item?.parameterName || thConfig.columnId]?.[1]
                setFiltersDataHandler(
                  item.parameterName || thConfig.columnId,
                  value,
                  !!datePickerValue2,
                  {
                    dateRangeIndex: 0
                  }
                );
                
                if(!datePickerValue2){
                  handleOpen();
                }
              }}
              propsValue={dateFirstPicker}
            />
            <div className="table__tr-filter-dash-divider">-</div>
            <DatePickerComponent
              {...item.dataConfig}
              minDate={dateFirstPicker}
              placeholder={'Дата'}
              className="table__tr-filter-datepicker"
              theme={DatePickerTheme.FROM_TABLE}
              disableFuture
              format="YYYY.MM.DD"
              onChange={(value) => {
                setFiltersDataHandler(
                  item.parameterName || thConfig.columnId,
                  value,
                  true, 
                  {
                    dateRangeIndex: 1
                  }
                );
              }}
              propsValue={dateSecondPicker}
              controlPicker={{
                open: open,
                onClose: handleClose,
                onOpen: handleOpen
              }}
            />
          </div>
        </div>
      </div>
  );
}
export default TableDateRange