import React from 'react';
import useSignIn from './useSignIn';
import { ReactComponent as Logo } from 'src/assets/img/icons/logo.svg';
import { ReactComponent as SignOutIcon } from 'src/assets/img/icons/sign-out-icon.svg';
import { ReactComponent as LockAltIcon } from 'src/assets/img/icons/lock-alt-icon.svg';
import { ReactComponent as MapMarkerQuestion } from 'src/assets/img/icons/map-marker-question.svg';
import cls from './SignInPage.module.scss';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import getOC from 'src/utils/helpers/getOC';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import { PAGES_ENUM } from 'src/routes';
import debounce from '@mui/utils/debounce';
import { useForm } from 'react-hook-form';
import NoLayoutChangeLanguage from 'src/components/common/NoLayoutChangeLanguage/NoLayoutChangeLanguage';

const SignInPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sign-in' });
  const { register, handleSubmit } = useForm();
  const { handleSignIn, canResetPassword } = useSignIn();

  const onSubmit = async (data: any) =>
    handleSignIn({
      login: data.login,
      password: data.password,
      device_os: getOC(),
      user_agent: navigator.userAgent,
    });

  return (
    <div className={cls['wrapper']}>
      <NoLayoutChangeLanguage />
      <Logo className={cls['logo']} />
      <form onSubmit={handleSubmit(debounce(onSubmit, 200))}>
        <div className={cls['input-wrapper']}>
          <Tooltip title={t('t-title-login')}>
            <SignOutIcon className="icon" />
          </Tooltip>
          <input required className={cls['input']} {...register('login')} />
        </div>
        <div className={cls['input-wrapper']}>
          <Tooltip title={t('t-title-password')}>
            <LockAltIcon className="icon" />
          </Tooltip>
          <input
            required
            type="password"
            className={cls['input']}
            {...register('password')}
          />
          {canResetPassword && (
            <Tooltip title={t('t-title-forgot-password')}>
              <NavLink to={PAGES_ENUM.RESET_PASSWORD}>
                <MapMarkerQuestion className="icon" />
              </NavLink>
            </Tooltip>
          )}
        </div>

        <Button
          buttonType={'submit'}
          theme={ButtonTheme.PRIMARY}
          className={cls['button']}
        >
          {t('button')}
        </Button>
      </form>
    </div>
  );
};
export default SignInPage;
