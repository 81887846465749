import { createSlice } from '@reduxjs/toolkit';
import { Order, ShortOrder } from 'src/models/orders/orders';
import { GoPagList, Meta } from 'src/models/types';

type ordersActionTypes = {
  payload: GoPagList<ShortOrder & Order>;
  type: string;
};
type orderActionTypes = {
  payload: ShortOrder & Order;
  type: string;
};

const initialState = {
  orders: [] as Array<ShortOrder & Order>,
  meta: {} as Meta,
};

export const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {
    getOrders: (_, action: ordersActionTypes) => {
      return {
        orders: action.payload.data,
        meta: action.payload.meta,
      };
    },
    onOrderCreate: (state, action: orderActionTypes) => {
      return {
        ...state,
        orders: [action.payload, ...state.orders],
      };
    },
    onOrderUpdate: (state, action: orderActionTypes) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.id === action.payload.id ? action.payload : order;
        }),
      };
    },
    onOrderDelete: (
      state,
      action: { type: string; payload: { ids: Array<number | string> } }
    ) => {
      return {
        ...state,
        orders: state.orders.filter((order) => {
          return !action.payload.ids.includes(order.id);
        }),
      };
    },
    onTtnDelete: (
      state,
      action: { type: string; payload: { ids: Array<number | string> } }
    ) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (action.payload.ids.includes(order.id)) {
            return { ...order, ttn: null, back_ttn: null };
          }

          return order;
        }),
      };
    },
    onOrderOpen: (
      state,
      action: {
        type: string;
        payload: { order_id: number; user_id: number; user_name: string };
      }
    ) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.id === action.payload.order_id
            ? { ...order, opened_by: action.payload.user_name || 'some user' }
            : order;
        }),
      };
    },
    onOrderClose: (
      state,
      action: {
        type: string;
        payload: { order_id: number; user_id: number };
      }
    ) => {
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.id === action.payload.order_id
            ? { ...order, opened_by: '' }
            : order;
        }),
      };
    },
  },
});
export const orderProductReducer = ordersSlice.reducer;

export const {
  getOrders,
  onOrderUpdate,
  onOrderCreate,
  onOrderDelete,
  onTtnDelete,
  onOrderOpen,
  onOrderClose,
} = ordersSlice.actions;
