import React, { useMemo } from 'react';
import {
  defaultTableName,
  IConfig,
  ITableDataItem,
  SortFieldTypes,
} from '../Table';
import { ReactComponent as SortIcon } from 'src/assets/img/icons/sort-icon.svg'
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { toggleSortField } from 'src/redux/slices/tableDataOperations';
import selectors from 'src/redux/selectors';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import {getWidthStyles} from "../helpers";

interface IProps {
  config: IConfig;
  hasSort?: boolean;
  tableData: ITableDataItem[];
}

const TableHeaderTitles = ({ config, hasSort, tableData }: IProps) => {
  const dispatch = useTypedDispatch();
  const sortFields = useTypedSelector(selectors.getSortFields);

  const sortFieldNames = useMemo(() => {
    if(hasSort && tableData[0] && tableData[0].elements){      
      return Object.entries(tableData[0].elements).reduce((accumulator, currentValue) => {
        return Object.keys(currentValue[1]).includes('sortValue') ? [...accumulator, currentValue[0]] : accumulator
      }, [] as string[])
    }
    return []
  }, [hasSort, tableData])
  const sortField = sortFields?.[config.tableName || defaultTableName];
  return (
    <>
      <tr className="table__tr-title">
        {config.th.filter((i) => !!i).map((thConfig) => (
          <th
            key={thConfig.columnId}
            style={getWidthStyles(thConfig)}
            onClick={() => {
              if( sortFieldNames.includes(thConfig.columnId) ){
                dispatch(toggleSortField({columnId: thConfig.columnId, tableName: config.tableName || defaultTableName}));
              }
            }}
            className={sortFieldNames.includes(thConfig.columnId) ? 'th-sort' : ''}
          >
            <div className="th-wrapper">
              {thConfig.element}
              {
                sortField?.columnId===thConfig.columnId && (sortField?.type===SortFieldTypes.ASC) && <SortIcon className="icon sort-icon" />
              }
              {
                sortField?.columnId===thConfig.columnId && (sortField?.type===SortFieldTypes.DESC) && <SortIcon style={{transform: 'rotate(180deg)'}} className="icon sort-icon" />
              }
            </div>
          </th>
        ))}
      </tr>
    </>
  );
}
export default TableHeaderTitles