import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWebsiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="#000"
      d="M9.469 18.586a9 9 0 1 0 0-18 9 9 0 0 0 0 18m0-16.5c.56 0 1.313.534 1.969 1.977q.213.47.39 1.023H7.11q.176-.553.39-1.023c.656-1.443 1.408-1.977 1.969-1.977M6.134 3.442a11 11 0 0 0-.59 1.644H3.469a7.5 7.5 0 0 1 3.057-2.4 8 8 0 0 0-.39.756m-.92 3.144c-.16.941-.245 1.952-.245 3s.085 2.059.244 3h-2.62a7.5 7.5 0 0 1-.624-3c0-1.067.222-2.081.624-3zm.33 7.5a11 11 0 0 0 .59 1.644q.179.392.391.756a7.5 7.5 0 0 1-3.057-2.4zm1.566 0h4.718q-.176.553-.39 1.023c-.656 1.443-1.409 1.977-1.97 1.977s-1.312-.534-1.968-1.977a9 9 0 0 1-.39-1.023m5.09-1.5H6.738a16.4 16.4 0 0 1-.268-3c0-1.071.096-2.084.268-3H12.2c.171.916.268 1.929.268 3s-.097 2.084-.268 3m1.193 1.5h2.076a7.5 7.5 0 0 1-3.057 2.4q.213-.365.391-.756c.227-.498.424-1.05.59-1.644m2.952-1.5h-2.62c.158-.941.244-1.952.244-3a18 18 0 0 0-.245-3h2.62c.402.919.625 1.933.625 3a7.5 7.5 0 0 1-.624 3m-3.933-9.9a7.5 7.5 0 0 1 3.057 2.4h-2.076a11 11 0 0 0-.59-1.644 8 8 0 0 0-.39-.756"
    />
  </svg>
);
export default SvgWebsiteIcon;
