import React, { FC } from 'react';
import {
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from 'src/components/UI/Select/Select';
import cls from './NoLayoutChangeLanguage.module.scss';
import { ReactComponent as GlobeIcon } from 'src/assets/img/icons/globe-icon.svg';
import { useTranslation } from 'react-i18next';
interface NoLayoutChangeLanguageProps {
  inverted?: boolean;
}
const NoLayoutChangeLanguage: FC<NoLayoutChangeLanguageProps> = ({
                                                                   inverted = '',
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={`${cls.wrapper} ${inverted ? cls.inverted : ''}`}>
      <Select
        controllerTheme={SelectControllerTheme.TRANSPARENT}
        dropdownTheme={SelectDropdownTheme.FULL_BORDERED}
        className={cls.select}
        customIconArrow={null}
        stylesConfig={{
          arrowIconHidden: true,
        }}
        defaultValue={'uk'}
        onChange={(value) => {
          i18n.changeLanguage(value);
        }}
        propsValue={i18n.language}
        options={[
          {
            title: 'UA',
            value: 'uk',
          },
          {
            title: 'RU',
            value: 'ru',
          },
          {
            title: 'EN',
            value: 'en',
          },
        ]}
      />
      <GlobeIcon className={cls.icon} />
    </div>
  );
};

export default NoLayoutChangeLanguage;
