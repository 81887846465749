import React, { lazy, ReactElement } from 'react';
import { useTypedSelector } from '../useTypedSelector';
import selectors from 'src/redux/selectors';
import { PAGES_ENUM } from 'src/routes';
import SettingsPayPage from 'src/pages/SettingsPayPage/SettingsPayPage';
import SignInPage from 'src/pages/SignInPage/SignInPage';
import { ReactComponent as SidebarHomepage } from 'src/assets/img/icons/desktop.svg';
import cls from 'src/components/Parts/Sidebar/Sidebar.module.scss';
import { ReactComponent as SidebarOrders } from 'src/assets/img/icons/sidebar-orders.svg';
import { ReactComponent as SidebarUsers } from 'src/assets/img/icons/sidebar-users.svg';
import { ReactComponent as SidebarWarehouse } from 'src/assets/img/icons/sidebar-warehouse.svg';
import { ReactComponent as SidebarBox } from 'src/assets/img/icons/sidebar-box.svg';
import { ReactComponent as SidebarCart } from 'src/assets/img/icons/sidebar-cart.svg';
import { ReactComponent as SidebarMegaphone } from 'src/assets/img/icons/sidebar-megaphone.svg';
import { ReactComponent as SidebarChart } from 'src/assets/img/icons/sidebar-chart.svg';
import { ReactComponent as SidebarVAlt } from 'src/assets/img/icons/sidebar-v-alt.svg';
import { ReactComponent as SidebarCircle } from 'src/assets/img/icons/sidebar-circle.svg';
import { ReactComponent as SidebarHandshake } from 'src/assets/img/icons/sidebar-handshake.svg';
import { useTranslation } from 'react-i18next';

export interface RouteItem {
  title: string;
  path: string;
  disabled: boolean;
}
export interface NavigationItem {
  icon: ReactElement;
  path?: string;
  title: string;
  id?: string;
  className?: string;
  routes?: RouteItem[];
  disabled: boolean;
}
const StatisticsPage = lazy(
  () => import('src/pages/StatisticsPage/StatisticsPage')
);
const FaqPage = lazy(() => import('src/pages/FaqPage/FaqPage'));
const DocumentationPage = lazy(
  () => import('src/pages/DocumentanionPage/DocumentationPage')
);

const OrderStatusesPage = lazy(
  () => import('src/pages/OrderStatuses/OrderStatusesPage')
);
const CreateOrderPage = lazy(() => import('src/pages/CreateOrderPage'));
const OrdersPage = lazy(() => import('src/pages/OrdersPage/OrdersPage'));
const CartPage = lazy(() => import('src/pages/CartPage/CartPage'));

const HomePage = lazy(() => import('src/pages/HomePage/HomePage'));

const ProductsProducersPage = lazy(
  () => import('src/pages/ProductsProducersPage/ProductsProducersPage')
);
const ProductCategoriesPage = lazy(
  () => import('src/pages/ProductCategoriesPage/ProductCategoriesPage')
);
const ProductsAttributesPage = lazy(
  () => import('src/pages/ProductsAttributesPage/ProductsAttributesPage')
);
const ProductsAttributeCategoriesPage = lazy(
  () =>
    import(
      'src/pages/ProductsAttributeCategoriesPage/ProductsAttributeCategoriesPage'
    )
);
const ProductsPage = lazy(() => import('src/pages/ProductsPage/ProductsPage'));

const PartnersPage = lazy(() => import('src/pages/PartnersPage/PartnersPage'));
const DeliveryServicePage = lazy(
  () => import('src/pages/DeliveryServicePage/DeliveryServicePage')
);
const RegistersPage = lazy(
  () => import('src/pages/RegistersPage/RegistersPage')
);
const SmsTemplatesPage = lazy(
  () => import('src/pages/SmsTemplatesPage/SmsTemplatesPage')
);
const SmsMailingPage = lazy(
  () => import('src/pages/SmsMailingPage/SmsMailingPage')
);
const ExpiredSubscriptionPage = lazy(
  () => import('src/pages/ExpiredSubscriptionPage/ExpiredSubscriptionPage')
);
const InvoicesPage = lazy(() => import('src/pages/InvoicesPage/InvoicesPage'));

const ClientsPage = lazy(() => import('src/pages/ClientsPage/ClientsPage'));
const ClientGroupsPage = lazy(
  () => import('src/pages/ClientGroupsPage/ClientGroupsPage')
);

const WarehouseArrivalPage = lazy(
  () => import('src/pages/WirehouseArrivalPage/WarehouseArrivalPage')
);
const WarehouseSuppliersPage = lazy(
  () => import('src/pages/WirehouseSuppliersPage/WarehouseSuppliersPage')
);
const WarehouseWriteoff = lazy(
  () => import('src/pages/WirehouseWriteoff/WarehouseWriteoff')
);
const ProductMovement = lazy(
  () => import('src/pages/ProductMovement/ProductMovement')
);

const SettingsHistoryPage = lazy(
  () => import('src/pages/SettingsHistoryPage/SettingsHistoryPage')
);
const SettingsSystemPage = lazy(
  () => import('src/pages/SettingsSystemPage/SettingsSystemPage')
);
const SettingsDepartmentsPage = lazy(
  () => import('src/pages/SettingsDepartmentsPage/SettingsDepartmentsPage')
);
const SettingsUserGroupsPage = lazy(
  () => import('src/pages/SettingsUserGroupsPage/SettingsUserGroupsPage')
);
const SettingsUsersPage = lazy(
  () => import('src/pages/SettingsUsersPage/SettingsUsersPage')
);
const SettingsCountriesPage = lazy(
  () => import('src/pages/SettingsCountriesPage/SettingsCountriesPage')
);
const SettingsCurrenciesPage = lazy(
  () => import('src/pages/SettingsCurrenciesPage/SettingsCurrenciesPage')
);
// const SettingsPayPage = lazy(
//   () => import('src/pages/SettingsPayPage/SettingsPayPage')
// );
const SettingsBlockedIpPage = lazy(
  () => import('src/pages/SettingsBlockedIpPage/SettingsBlockedIpPage')
);
const SettingsModulesPage = lazy(
  () => import('src/pages/SettingsModulesPage/SettingsModulesPage')
);

const RegistrationPage = lazy(
  () => import('src/pages/RegistrationPage/RegistrationPage')
);
// const SignInPage = lazy(() => import('src/pages/SignInPage/SignInPage'));
const PasswordReset = lazy(
  () => import('src/pages/PasswordReset/PasswordReset')
);
const UiPage = lazy(() => import('src/pages/UiPage/UiPage'));
const useNavigations = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sidebar' });
  const permissions = useTypedSelector(selectors.getPermissions);

  const navigations: NavigationItem[] = [
    {
      icon: <SidebarHomepage className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.HOME,
      title: t('desktop'),
      disabled: false,
    },
    {
      id: 'orders',
      icon: <SidebarOrders className={cls['sidebar__icon']} />,
      title: t('orders'),
      disabled: !permissions.can_order_module,
      routes: [
        {
          title: t('statuses'),
          path: PAGES_ENUM.ORDER_STATUSES,
          disabled: !permissions.can_use_order_status,
        },
        {
          title: t('list'),
          path: PAGES_ENUM.ORDERS,
          disabled: !permissions.can_use_order_list,
        },
        {
          title: t('cart'),
          path: PAGES_ENUM.CART,
          disabled: !permissions.can_use_order_cart,
        },
      ],
    },
    {
      icon: <SidebarUsers className={cls['sidebar__icon']} />,
      title: t('clients'),
      disabled: !permissions.can_client_module,
      routes: [
        {
          title: t('groups'),
          path: PAGES_ENUM.CLIENT_GROUPS,
          disabled: !permissions.can_use_client_group,
        },
        {
          title: t('list'),
          path: PAGES_ENUM.CLIENTS,
          disabled: !permissions.can_use_client,
        },
      ],
    },
    {
      icon: <SidebarWarehouse className={cls['sidebar__icon']} />,
      title: t('warehouse'),
      disabled: !permissions.can_stock_module,
      routes: [
        {
          title: t('suppliers'),
          path: PAGES_ENUM.WAREHOUSE_SUPPLIERS,
          disabled: !permissions.can_use_product_provider,
        },
        {
          title: t('arrival'),
          path: PAGES_ENUM.WAREHOUSE_ARRIVAL,
          disabled: !permissions.can_use_consignment,
        },
        {
          title: t('writeoff'),
          path: PAGES_ENUM.WAREHOUSE_WRITE_OFF,
          disabled: !permissions.can_use_write_off,
        },
        {
          title: t('movement'),
          path: PAGES_ENUM.PRODUCT_MOVEMENT,
          disabled: !permissions.can_use_movement,
        },
      ],
    },
    {
      icon: <SidebarBox className={cls['sidebar__icon']} />,
      title: t('products'),
      disabled: !permissions.can_product_module,
      routes: [
        {
          title: t('producers'),
          path: PAGES_ENUM.PRODUCTS_PRODUCERS,
          disabled: !permissions.can_use_product_producer,
        },
        {
          title: t('categories'),
          path: PAGES_ENUM.PRODUCTS_CATEGORIES,
          disabled: !permissions.can_use_product_category,
        },
        {
          title: t('list'),
          path: PAGES_ENUM.PRODUCTS,
          disabled: !permissions.can_use_product,
        },
        {
          title: t('attribute-categories'),
          path: PAGES_ENUM.PRODUCTS_ATTRIBUTE_CATEGORY,
          disabled: !permissions.can_use_product_attribute_category,
        },
        {
          title: t('attributes'),
          path: PAGES_ENUM.PRODUCTS_ATTRIBUTES,
          disabled: !permissions.can_use_product_attribute,
        },
      ],
    },
    {
      icon: <SidebarCart className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.CART,
      title: t('dispatch'),
      disabled: !permissions.can_moving_module,
      routes: [
        {
          title: t('delivery-service'),
          path: PAGES_ENUM.DELIVERY_SERVICE,
          disabled: !permissions.can_use_delivery_type,
        },
        {
          title: t('invoices'),
          path: PAGES_ENUM.INVOICES,
          disabled: !permissions.can_use_invoices,
        },
        {
          title: t('registers'),
          path: PAGES_ENUM.REGISTERS,
          disabled: !permissions.can_use_registry,
        },
      ],
    },
    {
      icon: <SidebarMegaphone className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.HOME,
      title: t('newsletter'),
      disabled: !permissions.can_sms_module,
      routes: [
        {
          title: t('sms-templates'),
          path: PAGES_ENUM.SMS_TEMPLATES,
          disabled: !permissions.can_use_sms_template,
        },
        {
          title: t('sms-mailing'),
          path: PAGES_ENUM.SMS_MAILING,
          disabled: !permissions.can_use_sms,
        },
      ],
    },
    {
      icon: <SidebarChart className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.STATISTICS,
      title: t('statistics'),
      disabled: !permissions.can_use_statistics,
    },
    {
      icon: <SidebarVAlt className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.HOME,
      title: t('settings'),
      disabled: !permissions.can_settings_module,
      routes: [
        {
          title: t('system'),
          path: PAGES_ENUM.SETTINGS_SYSTEM,
          disabled: !permissions.can_use_api,
        },
        {
          title: t('modules'),
          path: PAGES_ENUM.SETTINGS_MODULES,
          disabled: !permissions.can_use_modules,
        },
        {
          title: t('departments'),
          path: PAGES_ENUM.SETTINGS_DEPARTMENTS,
          disabled: !permissions.can_use_office,
        },
        {
          title: t('users-groups'),
          path: PAGES_ENUM.SETTINGS_USER_GROUPS,
          disabled: !permissions.can_use_roles,
        },
        {
          title: t('users'),
          path: PAGES_ENUM.SETTINGS_USERS,
          disabled: false,
        },
        {
          title: t('countries'),
          path: PAGES_ENUM.SETTINGS_COUNTRIES,
          disabled: !permissions.can_use_country,
        },
        {
          title: t('pay'),
          path: PAGES_ENUM.SETTINGS_PAY,
          disabled: !permissions.can_use_payment_type,
        },
        {
          title: t('currencies'),
          path: PAGES_ENUM.SETTINGS_CURRENCIES,
          disabled: !permissions.can_use_currency,
        },
        {
          title: t('blocked-ip'),
          path: PAGES_ENUM.SETTINGS_BLOCKED_IP,
          disabled: !permissions.can_use_ip,
        },
        {
          title: t('history'),
          path: PAGES_ENUM.SETTINGS_HISTORY,
          disabled: !permissions.can_use_history,
        },
      ],
    },
    {
      icon: <SidebarCircle className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.HOME,
      title: 'FAQ',
      disabled: !permissions.can_faq_module,
      routes: [
        {
          title: t('questions-answers'),
          path: PAGES_ENUM.FAQ,
          disabled: !permissions.can_use_faq,
        },
        {
          title: t('documentation-api'),
          path: PAGES_ENUM.FAQ_DOCUMENTATION_API,
          disabled: !permissions.can_use_faq_api,
        },
      ],
    },
    {
      icon: <SidebarHandshake className={cls['sidebar__icon']} />,
      path: PAGES_ENUM.PARTNERS,
      className: '',
      title: t('partners'),
      disabled: false,
    },
  ];
  const routes = [
    {
      path: PAGES_ENUM.HOME,
      component: <HomePage />,
      isAvailable: true,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.CLIENTS,
      component: <ClientsPage />,
      isAvailable: permissions?.can_use_client,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.CLIENT_GROUPS,
      component: <ClientGroupsPage />,
      isAvailable: permissions?.can_use_client_group,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.ORDERS,
      component: <OrdersPage />,
      isAvailable: permissions?.can_use_order_list,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.CREATE_ORDER,
      component: <CreateOrderPage />,
      isAvailable: true,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.COPY_ORDER,
      component: <CreateOrderPage />,
      isAvailable: true,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.UPDATE_ORDER,
      component: <CreateOrderPage />,
      isAvailable: true,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.ORDER_STATUSES,
      component: <OrderStatusesPage />,
      isAvailable: permissions?.can_use_order_status,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.CART,
      component: <CartPage />,
      isAvailable: permissions?.can_use_order_cart,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.WAREHOUSE_SUPPLIERS,
      component: <WarehouseSuppliersPage />,
      isAvailable: permissions?.can_use_product_provider,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.WAREHOUSE_ARRIVAL,
      component: <WarehouseArrivalPage />,
      isAvailable: permissions?.can_use_consignment,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.WAREHOUSE_WRITE_OFF,
      component: <WarehouseWriteoff />,
      isAvailable: permissions?.can_use_write_off,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.PRODUCT_MOVEMENT,
      component: <ProductMovement />,
      isAvailable: permissions?.can_use_movement,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.PARTNERS,
      component: <PartnersPage />,
      isAvailable: true,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.PRODUCTS_PRODUCERS,
      component: <ProductsProducersPage />,
      isAvailable: permissions?.can_use_product_producer,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.PRODUCTS_CATEGORIES,
      component: <ProductCategoriesPage />,
      isAvailable: permissions?.can_use_product_category,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.PRODUCTS_ATTRIBUTES,
      component: <ProductsAttributesPage />,
      isAvailable: permissions?.can_use_product_attribute,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.PRODUCTS_ATTRIBUTE_CATEGORY,
      component: <ProductsAttributeCategoriesPage />,
      isAvailable: permissions?.can_use_product_attribute_category,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.PRODUCTS,
      component: <ProductsPage />,
      isAvailable: permissions?.can_use_product,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.SETTINGS_SYSTEM,
      component: <SettingsSystemPage />,
      isAvailable: permissions?.can_use_api,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_DEPARTMENTS,
      component: <SettingsDepartmentsPage />,
      isAvailable: permissions?.can_use_office,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_USER_GROUPS,
      component: <SettingsUserGroupsPage />,
      isAvailable: permissions?.can_use_roles,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_USERS,
      component: <SettingsUsersPage />,
      isAvailable: true,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_COUNTRIES,
      component: <SettingsCountriesPage />,
      isAvailable: permissions?.can_use_country,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_CURRENCIES,
      component: <SettingsCurrenciesPage />,
      isAvailable: permissions?.can_use_currency,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_PAY,
      component: <SettingsPayPage />,
      isAvailable: permissions?.can_use_payment_type,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_BLOCKED_IP,
      component: <SettingsBlockedIpPage />,
      isAvailable: permissions?.can_use_ip,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_MODULES,
      component: <SettingsModulesPage />,
      isAvailable: permissions?.can_use_modules,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SETTINGS_HISTORY,
      component: <SettingsHistoryPage />,
      isAvailable: permissions?.can_use_history,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.DELIVERY_SERVICE,
      component: <DeliveryServicePage />,
      isAvailable: permissions?.can_use_delivery_type,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.REGISTERS,
      component: <RegistersPage />,
      isAvailable: permissions?.can_use_registry,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.INVOICES,
      component: <InvoicesPage />,
      isAvailable: permissions?.can_use_invoices,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.SMS_TEMPLATES,
      component: <SmsTemplatesPage />,
      isAvailable: permissions?.can_use_sms_template,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.SMS_MAILING,
      component: <SmsMailingPage />,
      isAvailable: permissions?.can_use_sms,
      disableLayout: false,
    },

    {
      path: PAGES_ENUM.STATISTICS,
      component: <StatisticsPage />,
      isAvailable: permissions?.can_use_statistics,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.FAQ,
      component: <FaqPage />,
      isAvailable: permissions?.can_use_faq,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.EXPIRED_SUBSCRIPTIONS,
      component: <ExpiredSubscriptionPage />,
      isAvailable: true,
      disableLayout: true,
    },

    {
      path: PAGES_ENUM.REGISTRATION,
      component: <RegistrationPage />,
      isAvailable: true,
      disableLayout: true,
    },
    {
      path: PAGES_ENUM.SIGN_IN,
      component: <SignInPage />,
      isAvailable: true,
      disableLayout: true,
    },
    {
      path: PAGES_ENUM.RESET_PASSWORD,
      component: <PasswordReset />,
      isAvailable: true,
      disableLayout: true,
    },
    {
      path: PAGES_ENUM.UPDATE_PASSWORD,
      component: <PasswordReset isUpdate />,
      isAvailable: true,
      disableLayout: true,
    },
    {
      path: PAGES_ENUM.FAQ_DOCUMENTATION_API,
      component: <DocumentationPage />,
      isAvailable: permissions?.can_use_faq_api,
      disableLayout: false,
    },
    {
      path: PAGES_ENUM.UI,
      component: <UiPage />,
      isAvailable: true,
      disableLayout: false,
    },
  ];

  return { navigations, routes };
};

export default useNavigations;
