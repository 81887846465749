import * as React from 'react';
import type { SVGProps } from 'react';
const SvgScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="#000"
      d="M16.303 3.086H4.637a2.5 2.5 0 0 0-2.5 2.5v6.667a2.5 2.5 0 0 0 2.5 2.5h5v1.666H6.303a.834.834 0 0 0 0 1.667h8.334a.834.834 0 0 0 0-1.667h-3.334v-1.666h5a2.5 2.5 0 0 0 2.5-2.5V5.586a2.5 2.5 0 0 0-2.5-2.5m.834 9.167a.833.833 0 0 1-.834.833H4.637a.834.834 0 0 1-.834-.833V5.586a.833.833 0 0 1 .834-.833h11.666a.833.833 0 0 1 .834.833z"
    />
  </svg>
);
export default SvgScreenIcon;
