import {useEffect, useMemo, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import {
  addAsyncSelectData,
  setAsyncSelectData,
} from '../redux/slices/tableDataOperations';
import selectors from '../redux/selectors';
import {IOption} from "../components/UI/Select/Select";
export interface AdditionalParams {
  [key: string]: any;
}
export type UseAsyncSelectRenderOption =  (data: any) => IOption
export interface UseAsyncSelectProps {
  storeFieldName: string;
  queryFieldName: string;
  titleFieldName?: string;
  valueFieldName?: string;
  isInfinite?: boolean;
  additionalParams?: AdditionalParams;
  query: any;
  renderOption?: UseAsyncSelectRenderOption
}
const useAsyncSelect = ({
  storeFieldName,
  queryFieldName,
  titleFieldName,
  valueFieldName,
  renderOption,
  isInfinite,
  additionalParams,
  query,
}: UseAsyncSelectProps) => {
  const [search, setSearch] = useState<string>();
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const additionalParamsClear = additionalParams
    ? Object.fromEntries(
        Object.entries(additionalParams).filter(([key, value]) => value)
      )
    : {};
  const params = { ...additionalParamsClear };
  if (search){
    params[queryFieldName] = search;
  }
  if (page !==1 ){
    params.page = page;
  }
  const { data } = query(params);
  const hasMore = !!(data?.meta?.current_page < data?.meta?.last_page);
  const fetchNextPage = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  const dispatch = useAppDispatch();
  useEffect(() => {

    const res = {
      key: storeFieldName,
      fullData: data?.data,
      isSearched: !!search,
    };
    if (isInfinite && data?.meta?.current_page !== 1) {
      dispatch(addAsyncSelectData(res));
    } else {
      dispatch(setAsyncSelectData(res));
    }
    setIsSearched(prev => prev || !!search);
  }, [data, isInfinite]);
  const searchHandler = (value: string) => {
    setSearch(value);
    setPage(1);
  };
  const selectData = useAppSelector(selectors.getAsyncSelectData);

  const options: IOption[] = useMemo((): IOption[] => {
    if (!(titleFieldName && valueFieldName) && !renderOption){
      return []
    }
    const data = selectData[storeFieldName]?.fullData || [];
    return data.map((item:any) => {
      if (titleFieldName && valueFieldName){
        return {
          title: item[titleFieldName],
          value: item[valueFieldName]
        } as IOption
      }else{
        return renderOption?.(item) as IOption
      }
    })
  }, [selectData, renderOption, titleFieldName, valueFieldName])

  return {
    searchHandler,
    fetchNextPage,
    hasMore,
    options,
    isSearched
  };
};
export default useAsyncSelect;
