import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { PagListResult } from 'src/models/types';
import { OrderStatus, TtnStatus } from 'src/models/orders/orders';
import { createApi } from '@reduxjs/toolkit/query/react';

export const goStatuses = createApi({
  reducerPath: 'goStatuses',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Status'],
  endpoints: (builder) => ({
    getOrderStatuses: builder.query<PagListResult<OrderStatus>, any>({
      query: (params) => ({ url: '/statuses/', method: 'GET', params: params }),
      providesTags: ['Status'],
    }),
    getTtnStatus: builder.query<TtnStatus, any>({
      query: (id) => ({ url: `orders/${id}/poststatus`, method: 'GET' }),
    }),
    postOrderStatus: builder.mutation({
      query: (params) => ({
        url: 'statuses',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Status'],
    }),
    updateOrderStatus: builder.mutation({
      query: (params) => ({
        url: `statuses/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Status'],
    }),
    changePosition: builder.mutation({
      query: (body) => ({
        url: 'statuses/changePosition',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Status'],
    }),
  }),
});

export const {
  useGetOrderStatusesQuery,
  useLazyGetTtnStatusQuery,
  useChangePositionMutation,
  usePostOrderStatusMutation,
  useUpdateOrderStatusMutation,
} = goStatuses;
