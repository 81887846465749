import React, { useEffect, useRef, useState } from 'react';
import cls from '../UserSettingsPopup.module.scss';
import { ReactComponent as PlayIcon } from 'src/assets/img/icons/play-icon.svg';

interface SoundItemProps {
  icon: any;
  title: string;
  src: string;
  value: number;
  onChange: (newVolume: number) => void;
}

const SoundItem: React.FC<SoundItemProps> = ({
  icon,
  title,
  src,
  value,
  onChange,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const normalizedValue = value / 100;

  const [currVolume, setCurrVolume] = useState(normalizedValue || 0.5);

  useEffect(() => {
    setCurrVolume(normalizedValue); // Обновление громкости на основе нормализованного значения
  }, [normalizedValue]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = currVolume; // Установка громкости для аудио элемента
    }
  }, [currVolume]);

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = +event.target.value / 100;
    setCurrVolume(newVolume);
    onChange(+event.target.value);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <>
      <audio ref={audioRef} src={src} />
      <div className={cls['sound-item']}>
        <div className={cls['sound-item__icon']}>{icon}</div>
        <div className={cls['sound-item__title']}>{title}</div>
        <div className={cls['sound-item__play']}>
          <div
            className={cls['sound-item__play-button']}
            onClick={handlePlayClick}
          >
            <PlayIcon />
          </div>
        </div>
        <div className={cls['sound-item__slider']}>
          <input
            type="range"
            min="1"
            max="100"
            value={currVolume * 100}
            onChange={handleVolumeChange}
          />
        </div>
        <div className={cls['sound-item__value']}>
          {(currVolume * 100).toFixed(0)}
        </div>
        <div className={cls['sound-item__percent']}>%</div>
      </div>
    </>
  );
};

export default SoundItem;
