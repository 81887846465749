import React, { ReactNode, useEffect, useRef } from 'react';
export interface IDropdownPropsExport {
  show?: boolean;
  close: () => void;
}
export interface IDropdownProps extends IDropdownPropsExport {
  children: ReactNode;
  positionAbsolute?: boolean;
}
export const Dropdown = ({
  children,
  show,
  close,
  positionAbsolute,
}: IDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // if (show) {
    const closeHandler = (event: MouseEvent) => {
      if (event.target instanceof Element) {
        if (
          !event.target.closest('.dropdown') &&
          !event.target.closest('.dropdown-control')
        ) {
          close();
        }
      }
    };
    window.addEventListener('click', closeHandler);
    return () => {
      window.removeEventListener('click', closeHandler);
    };
    // }
  }, [show, close]);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown-wrapper ${show && 'dropdown-wrapper--opened'}`}
      style={{
        position: positionAbsolute ? 'absolute' : 'unset',
        // , display: show ? 'block' : 'none'
      }}
    >
      {children}
    </div>
  );
};
