import { EnumPaymentTypes } from "src/models/enums";
import { ReactComponent as DollarIcon } from 'src/assets/img/icons/create-new-order/dollar-icon.svg';
import { ReactComponent as PrePayIcon } from 'src/assets/img/icons/create-new-order/prepay-icon.svg';
import { ReactComponent as CompletedIcon } from 'src/assets/img/icons/create-new-order/complited-icon.svg';
import { ReactComponent as ChangeIcon } from 'src/assets/img/icons/create-new-order/change-icon.svg';
import { ReactComponent as OtherIcon } from 'src/assets/img/icons/other-icon.svg';

export const paymentTypesIcons = {
  [EnumPaymentTypes.AtDelivery]: <DollarIcon className="icon" />,
  [EnumPaymentTypes.PrePayment]: <PrePayIcon className="icon" />,
  [EnumPaymentTypes.Paid]: <CompletedIcon className="icon" />,
  [EnumPaymentTypes.Exchange]: <ChangeIcon className="icon" />,
  "_other": <OtherIcon className="icon" />
};
export default paymentTypesIcons