import React, { useState } from 'react';
import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { ReactComponent as NotificationButtonIcon } from 'src/assets/img/icons/notification-button-icon.svg';
import { useTranslation } from 'react-i18next';
import NotificationItem from './NotificationItem/NotificationItem';
import CreateNotificationPopup from 'src/components/Page/CreateNotificationPopup/CreateNotificationPopup';
import { useDeleteNotificationMutation } from 'src/services/notification';
import { Notification } from 'src/models/notifications';
import ConfirmDeletePopup from './ConfirmDeletePopup/ConfirmDeletePopup';
import { toast } from 'react-toastify';
import { useDeleteNotificationEventMutation } from 'src/services/pytonSSE';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { onNotificationDelete } from 'src/redux/slices/notificationsSlice';
export interface IProp extends IPopupPropsExport {
  data: Array<Notification>;
}

const NotificationPopup = ({ show, close, data }: IProp) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification-popup',
  });
  const [createNotificationPopupShow, setCreateNotificationPopupShow] =
    useState<boolean>(false);
  const userLogin = useTypedSelector(selectors.getUserLogin);
  const [deleteNotification] = useDeleteNotificationMutation();
  const [deleteEvent] = useDeleteNotificationEventMutation();
  const dispatch = useTypedDispatch();
  const onShowModalConfirmDeleteNotification = (id: number) => () => {
    setIsConfirmDeletePopup(true);
    setIdDeleteItem(id);
  };
  const [idDeleteItem, setIdDeleteItem] = useState<number | null>(null);

  const onDeleteNotification = () => {
    setIsConfirmDeletePopup(false);
    deleteEvent({
      notification_id: idDeleteItem,
      newUserAgentLogin: userLogin,
    })
      .unwrap()
      .then(() => {
        deleteNotification(idDeleteItem)
          .unwrap()
          .then(() => {
            dispatch(onNotificationDelete(idDeleteItem));
          })
          .catch((e) => toast.error(e?.data?.message || 'error'));
      })
      .catch((e) => toast.error(e?.data?.message || 'error'));

    setIdDeleteItem(null);
  };

  const [currentItem, setCurrentItem] = useState<Notification | null>(null);
  const [isConfirmDeletePopup, setIsConfirmDeletePopup] = useState(false);

  const onEdit = (item: Notification) => () => {
    setCurrentItem(item);
    setCreateNotificationPopupShow(true);
  };

  const onShowNotificationPopup = () => {
    setCurrentItem(null);
    setCreateNotificationPopupShow(true);
  };

  const isEmptyData = data.length === 0;
  return (
    <Popup show={show} close={close} title={t('title')} width="800px">
      <>
        <div className="flex-center">
          <Button theme={ButtonTheme.PRIMARY} onClick={onShowNotificationPopup}>
            <NotificationButtonIcon className="icon" />
            <span>{t('create-new-notification')}</span>
          </Button>
        </div>

        {isEmptyData && (
          <p className="flex-center mt-10">{t('no-notifications')}</p>
        )}
        {data.map((item, index) => (
          <NotificationItem
            key={index}
            {...item}
            onEdit={onEdit(item)}
            onDelete={onShowModalConfirmDeleteNotification(item.id)}
          />
        ))}
      </>
      {createNotificationPopupShow && (
        <CreateNotificationPopup
          // refresh={refresh}
          show={createNotificationPopupShow}
          currentItem={currentItem}
          close={() => {
            setCreateNotificationPopupShow(false);
          }}
        />
      )}

      {isConfirmDeletePopup && (
        <ConfirmDeletePopup
          show={isConfirmDeletePopup}
          onConfirm={onDeleteNotification}
          close={() => setIsConfirmDeletePopup(false)}
        />
      )}
    </Popup>
  );
};
export default NotificationPopup;
