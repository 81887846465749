import React, { ChangeEvent, useState } from 'react';
import cls from './Switch.module.scss';
// import soundHandler from 'src/utils/helpers/soundHandler';
import useSounds from 'src/hooks/useSounds';
interface IProps {
  className?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}
export const Switch = ({
  className = '',
  value,
  onChange,
  disabled = false,
}: IProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const { playToggleSound } = useSounds();
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // soundHandler('/sound/BUTTON_SWITCH.mp3');
    playToggleSound();
    if (onChange) {
      onChange(event.target.checked);
    } else {
      setChecked(event.target.checked);
    }
  };

  return (
    <div
      className={`${cls['switch-wrapper']} ${disabled ? cls['disabled'] : ''}`}
      onClick={(e) => {e.stopPropagation()}}
    >
      <label className={`${cls['switch']} ${className}`}>
        <input
          disabled={disabled}
          type="checkbox"
          checked={value !== undefined ? value : checked}
          onChange={onChangeHandler}
        />
        <span className={`${cls['slider']} ${cls['round']}`} />
      </label>
    </div>
  );
};
