import React from 'react';
import cls from './NotificationItem.module.scss';
import { ReactComponent as CalendarIcon } from 'src/assets/img/icons/calendar-icon.svg';
import { ReactComponent as NotificationItemIcon } from 'src/assets/img/notification-item.svg';
import { ReactComponent as FlagIcon } from 'src/assets/img/icons/flag-icon.svg';
import { ReactComponent as EditIcon } from 'src/assets/img/icons/history/order-or-cart-details-changed.svg';
import { useTranslation } from 'react-i18next';
import { Notification } from 'src/models/notifications';
import { ReactComponent as DeleteIcon } from 'src/assets/img/icons/popup-close-icon.svg';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import useNotificationsTranslate from 'src/hooks/useNotificationsTranslate';

interface Props extends Notification {
  onDelete: () => void;
  onEdit: () => void;
}

const NotificationItem: React.FC<Props> = (p) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification-popup',
  });
  const { notificationTypesMap } = useNotificationsTranslate();
  const userData = useTypedSelector(selectors.getUser);

  return (
    <div className={`${cls['notification']} ${p.is_done ? cls['read'] : ''}`}>
      <div className={cls['image']}>
        <NotificationItemIcon />
      </div>
      <div className={cls['data']}>
        <div className={cls['title']}>{notificationTypesMap[p.type]}</div>
        <div className={cls['date']}>
          <FlagIcon className="icon" />
          <span>{p?.date_time}</span>
        </div>
        <div className={`${ cls['description']} ${p.is_done ? cls['describtion-shortened'] : ''}`}>
          {t('description')}: <span>{p?.description}</span>
        </div>

        <div className={cls['added']}>
          {t('added')}:<CalendarIcon className="icon ml-3" />
          <span>{p.add_date_time}</span>
        </div>
        <div className={cls['user']}>{userData?.full_name}</div>
      </div>

      <div className={cls['delete-edit-btns-wrapper']}>
        {!p.is_done && (
          <Button
            theme={ButtonTheme.TRANSPARENT}
            className={`${cls['delete-btn']} popup__header-close-button`}
            onClick={p.onEdit}
            buttonType="button"
          >
            <EditIcon />
          </Button>
        )}
        <Button
          theme={ButtonTheme.TRANSPARENT}
          className={`${cls['delete-btn']} popup__header-close-button`}
          onClick={p.onDelete}
          buttonType="button"
        >
          <DeleteIcon />
        </Button>
      </div>
      {p.is_done && <div className={cls['completed']}>{t('completed')}</div>}
    </div>
  );
};
export default NotificationItem;
