import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { Office, PagListResult } from '../../../models/types';
//TODO: check out correct folder structure
export const goOfficeSlice = createApi({
  reducerPath: 'goOffices',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Office'],
  endpoints: (builder) => ({
    getOffices: builder.query<PagListResult<Office>, any>({
      query: (params) => ({
        url: 'offices',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Office'],
    }),
  }),
});

export const {
  useGetOfficesQuery,
} = goOfficeSlice;
