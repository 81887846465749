import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { ListResult } from 'src/models/types';
import UPPost, { CityUP, StreetUP } from 'src/models/posts/up';

export const upLocSlice = createApi({
  reducerPath: 'upLoc',
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getCityUP: builder.query<ListResult<CityUP>, any>({
      query: (body) => ({
        url: 'ukrPoshtaSettings/city',
        method: 'POST',
        body,
      }),
    }),

    getWarehouseUP: builder.query<ListResult<any>, any>({
      query: (body) => ({
        url: 'ukrPoshtaSettings/branches',
        method: 'POST',
        body,
      }),
    }),
    getStreetsUP: builder.query<ListResult<StreetUP>, any>({
      query: (body) => ({
        url: 'ukrPoshtaSettings/street/name',
        method: 'POST',
        body,
      }),
    }),

    postStoreAddress: builder.mutation<{ data: UPPost }, any>({
      query: (body) => ({
        url: 'api/ukr/address',
        method: 'POST',
        body,
      }),
    }),

    postStoreClient: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/ukr/client',
        method: 'POST',
        body,
      }),
    }),
    getUPTrackingStatuses: builder.query<ListResult<any>, any>({
      query: () => 'api/ukr/statuses',
    }),
    getUPShipment: builder.query<any, any>({
      query: () => ({
        url: 'api/ukr/shipment',
        method: 'POST',
      }),
    }),
  }),
});
export const {
  useGetUPTrackingStatusesQuery,
  usePostStoreAddressMutation,
  usePostStoreClientMutation,
  useGetCityUPQuery,
  useGetWarehouseUPQuery,
  useGetStreetsUPQuery,
} = upLocSlice;
