import { RootState } from './index';
import { UserRoleEnum } from '../models/enums';

// type TSelector<R> = (state: RootState) => R;

// User selectors
const getUser = (state: RootState) => state.auth.user;
const getUserId = (state: RootState) => state.auth.user?.id;
const getAdminCheck = (state: RootState) =>
  state.auth.user.role === UserRoleEnum.ADMIN;
const getUserName = (state: RootState) => state.auth.user?.full_name;
const getUserLogin = (state: RootState) => state.auth.user?.login;
const selectIsRedirectToSignIn = (state: RootState) =>
  state.auth.isRedirectToSignIn;
const getAccessToken = (state: RootState) => state.auth.access_token;
const getNotifications = (state: RootState) => state.notificationsSlice;

// permissions
const getPermissions = (state: RootState) => state.auth.permissions;
const getExportPermissions = (state: RootState) =>
  state.auth.permissions.can_export;
const getOfficePermission = (state: RootState) =>
  state.auth.permissions.can_edit_office;
const getDeleteOrderPermission = (state: RootState) =>
  state.auth.permissions.can_order_delete;
const getCreateOrderPermission = (state: RootState) =>
  state.auth.permissions.can_create_order;
const getUpdateOrderPermission = (state: RootState) =>
  state.auth.permissions.can_edit_order;
const getTtnPermission = (state: RootState) =>
  state.auth.permissions.can_create_ttn;
const getSMSPermission = (state: RootState) =>
  state.auth.permissions.can_send_sms;
const getPhonePermission = (state: RootState) =>
  state.auth.permissions.hide_phones;

// Order selectors
const getOrderProducts = (state: RootState) => state.orderProduct;
const getOrders = (state: RootState) => state.ordersSlice;

// Sort selectors
const getSortFields = (state: RootState) =>
  state.tableDataOperations.sortFields;
// Selected rows selectors
const getSelectedRows = (state: RootState) => state.selectedRows.selectedRows;
const getFixStateResetValue = (state: RootState) =>
  state.fixStateReset.fixStateResetValue;
const getFilters = (state: RootState) => state.tableDataOperations.filters;
const getHasFilters = (state: RootState) => Object.entries(state.tableDataOperations.filters.data).filter(([key, value]) => key !== 'pagination' && value).length;
const getAsyncSelectData = (state: RootState) =>
  state.tableDataOperations.asyncSelectData;

const getIsAllRowsSelected = (state: RootState) =>
  state.selectedRows.selectedRows.length && 
  state.selectedRows.selectedRows.length ===
  state.selectedRows.tableDataIds.length;

const selectors = {
  getUser,
  getAdminCheck,
  getOrders,
  getUserName,
  getUserId,
  selectIsRedirectToSignIn,
  getOrderProducts,
  getPermissions,
  getOfficePermission,
  getExportPermissions,
  getCreateOrderPermission,
  getDeleteOrderPermission,
  getTtnPermission,
  getSMSPermission,
  getSortFields,
  getPhonePermission,
  getSelectedRows,
  getFixStateResetValue,
  getFilters,
  getUpdateOrderPermission,
  getAccessToken,
  getAsyncSelectData,
  getNotifications,
  getUserLogin,
  getIsAllRowsSelected,
  getHasFilters
};

export default selectors;
