import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult, Role } from '../../models/types';

type RoleParams = {
  description: null | string;
  id: number;
  name: string;
  offices_ids: Array<number>;
};

export const rolesSlice = createApi({
  reducerPath: 'roles',
  baseQuery: baseQuery,
  tagTypes: ['UserGroups'],

  endpoints: (builder) => ({
    getRoles: builder.query<PagListResult<Role>, any>({
      query: (params) => ({
        url: 'roles',
        method: 'GET',
        params: params,
      }),
      providesTags: ['UserGroups'],
    }),
    getRole: builder.mutation<{ code: number; data: Role }, any>({
      query: (id) => ({
        url: `roles/${id}`,
        method: 'GET',
      }),
    }),
    postRoles: builder.mutation({
      query: (params: RoleParams) => ({
        url: 'roles',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UserGroups'],
    }),
    updateRoles: builder.mutation({
      query: (params) => ({
        url: `roles/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['UserGroups'],
    }),
    deleteRoles: builder.mutation({
      query: (body) => ({
        url: 'roles/delete',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['UserGroups'],
    }),
  }),
});

export const {
  useDeleteRolesMutation,
  useGetRolesQuery,
  usePostRolesMutation,
  useUpdateRolesMutation,
  useGetRoleMutation,
} = rolesSlice;
