import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult } from '../../models/types';
import { Registry } from '../../models/sending/registries';

export const registriesSlice = createApi({
  reducerPath: 'registries',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getRegistries: builder.query<PagListResult<Registry>, any>({
      query: (params) => ({
        url: 'registries',
        params,
        method: 'GET',
      }),
    }),
    getRegistry: builder.query<{ data: Registry }, any>({
      query: (id) => `registries/${id}/`,
    }),
    postRegistry: builder.mutation({
      query: (params: any) => ({
        url: 'registries',
        method: 'POST',
        body: params,
      }),
    }),
    updateRegistry: builder.mutation({
      query: ({ id, params }) => ({
        url: `registries/${id}/`,
        method: 'PUT',
        body: params,
      }),
    }),
    deleteRegistry: builder.mutation({
      query: (id: number) => ({
        url: `registries/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetRegistriesQuery,
  useGetRegistryQuery,
  usePostRegistryMutation,
  useDeleteRegistryMutation,
  useUpdateRegistryMutation,
} = registriesSlice;
