import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from 'src/models/IAuth';
import { setUser } from 'src/redux/Auth';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { PagListResult } from 'src/models/types';
import { Notification, TARGET_NOTIFICATION } from 'src/models/notifications';
import { GoPagList } from 'src/models/types';
import {
  setNewNotification,
  setNotifications,
} from 'src/redux/slices/notificationsSlice';

export class RetriableError extends Error {}
export class FatalError extends Error {}
export enum EventKeysEnum {
  NewOrder = 'order-created',
  OpenOrder = 'order-open',
  CloseOrder = 'order-close',
  UserLogin = 'user-login',
  Notification = 'send_notification',
}

export const goUsers = createApi({
  reducerPath: 'goUsers',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUsers: builder.query<GoPagList<User>, any>({
      query: (params) => ({
        url: 'users',
        method: 'GET',
        params: params,
      }),
      providesTags: ['User'],
    }),
    getNotifications: builder.query<PagListResult<Notification>, unknown>({
      query: () => ({
        url: 'notifications',
        method: 'GET',
      }),
      onQueryStarted: async (_, api: any) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        dispatch(setNotifications(data?.data));
      },
    }),
    getUser: builder.query<{ data: User }, any>({
      query: () => ({
        url: 'users/personal/',
        method: 'GET',
      }),
      providesTags: ['User'],
      onQueryStarted: async (_, api: any) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        dispatch(setUser(data?.data));
      },
      async onCacheEntryAdded(_, { dispatch, getCacheEntry }) {
        await fetchEventSource(
          process.env.NODE_ENV !== 'development'
            ? `https://live${process.env.REACT_APP_DOMAIN}/crm/${window.location.hostname.split('.')[0]}/`
            : `https://live.promt-mkt.com/crm/${process.env.REACT_APP_DEV_TENANT}/`,
          {
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return;
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                throw new FatalError();
              } else {
                throw new RetriableError();
              }
            },
            onmessage(ev) {
              if (ev.data && ev.event === EventKeysEnum.Notification) {
                const item = JSON.parse(ev.data).data;
                if (
                  item.target === TARGET_NOTIFICATION.ALL ||
                  item.creator_id === getCacheEntry()?.data?.data.id
                ) {
                  return dispatch(setNewNotification(JSON.parse(ev.data).data));
                }
              }
            },
            onclose() {
              throw new RetriableError();
            },
            onerror(err) {
              // temporary to
              console.log('SSE onerror', err);
              if (err instanceof FatalError) {
                throw err;
              }
            },
          }
        );
      },
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery, useGetNotificationsQuery } =
  goUsers;
