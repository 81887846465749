import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult } from '../../models/types';
import * as I from 'src/models/settings/histories';

export const historiesSlice = createApi({
  reducerPath: 'histories',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getLogs: builder.query<PagListResult<I.Log>, any>({
      query: (params) => ({
        url: 'logs',
        params,
      }),
    }),
    getLogTypes: builder.query<I.ActionMethodResponse, any>({
      query: (params) => ({
        url: 'logs/types',
        params,
      }),
    }),
  }),
});

export const { useGetLogsQuery, useGetLogTypesQuery } = historiesSlice;
