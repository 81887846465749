import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAvatarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      fill="#000"
      d="M14.478 13.203q-.209-1.47-.83-2.443t-1.75-1.124a4.5 4.5 0 0 1-1.513 1.095 4.5 4.5 0 0 1-1.855.394 4.5 4.5 0 0 1-1.855-.394 4.5 4.5 0 0 1-1.513-1.095q-1.128.151-1.75 1.124-.62.972-.83 2.443 1.005 1.423 2.57 2.253a7.1 7.1 0 0 0 3.378.83 7.1 7.1 0 0 0 3.377-.83 7.3 7.3 0 0 0 2.571-2.253m-3.372-4.056.533-.534q.534-.533.534-2.042a3.5 3.5 0 0 0-1.067-2.575A3.5 3.5 0 0 0 8.53 2.929a3.5 3.5 0 0 0-2.576 1.067A3.5 3.5 0 0 0 4.887 6.57a3.5 3.5 0 0 0 1.067 2.576 3.5 3.5 0 0 0 2.576 1.067 3.5 3.5 0 0 0 2.576-1.067m5.25-3.448.337.787q.337.788.337 2.514t-.674 3.297a8.7 8.7 0 0 1-1.807 2.713 8.4 8.4 0 0 1-2.708 1.816 8.3 8.3 0 0 1-3.311.674 8.3 8.3 0 0 1-3.301-.674 8.5 8.5 0 0 1-2.713-1.812 8.5 8.5 0 0 1-1.812-2.713A8.3 8.3 0 0 1 .03 9a8.3 8.3 0 0 1 .674-3.301 8.5 8.5 0 0 1 1.812-2.714 8.5 8.5 0 0 1 2.713-1.811A8.3 8.3 0 0 1 8.53.5a8.3 8.3 0 0 1 3.301.674 8.5 8.5 0 0 1 2.714 1.811A8.5 8.5 0 0 1 16.357 5.7"
    />
  </svg>
);
export default SvgAvatarIcon;
