import './sass/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { InitOptions } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import translationEN from './assets/i18n/en.json';
import translationRU from './assets/i18n/ru.json';
import translationUK from './assets/i18n/uk.json';
import { store } from './redux';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/Parts/ErrorBoundary';

void i18next.init({
  lng: 'ru',
  resources: {
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
    uk: {
      translation: translationUK,
    },
  },
  interpolation: {
    escapeValue: false, // Не экранировать значения
  },
  //debug: true,
} as InitOptions);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);
