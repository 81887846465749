import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/models/IAuth';
import { removeAuthTokens } from 'src/utils/helpers/authHelper';
import { Permission } from 'src/models/settings/permissions';

interface UserInfo {
  access_token: string | null;
  isRedirectToSignIn: boolean;
  isAuth: boolean;
  user: User;
  permissions: any;
}

const initialState: UserInfo = {
  access_token: null,
  isRedirectToSignIn: false,
  isAuth: false,
  user: {} as User,
  permissions: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken(state, action: PayloadAction<string | null>) {
      state.access_token = action.payload;
    },

    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setUserSettings(state, action) {
      const sounds = {
        total_volume: action.payload.total_volume,
        login_volume: action.payload.login_volume,
        logout_volume: action.payload.logout_volume,
        error_volume: action.payload.error_volume,
        approve_volume: action.payload.approve_volume,
        warning_volume: action.payload.warning_volume,
        info_volume: action.payload.info_volume,
        reminder_volume: action.payload.reminder_volume,
        toggle_volume: action.payload.toggle_volume,
        block_access_volume: action.payload.block_access_volume,
        mini_reminder_volume: action.payload.mini_reminder_volume,
        new_order_volume: action.payload.new_order_volume,
      };

      state.user = {
        ...state.user,
        muted_sound: action.payload.muted_sound,
        sounds,
        crm_language: action.payload.crm_language,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };
    },
    setPermissions(
      state,
      action: { type: string; payload: Array<Permission> }
    ) {
      const perm: Array<{
        id: number;
        name: string;
        is_available: boolean;
      }> = [];
      action.payload.forEach((item: Permission) => {
        perm.push({
          id: item.id,
          name: item.name,
          is_available: item.is_available,
        });
        if (item?.children?.length) perm.push(...item.children);
      });
      const newState = {};
      for (let i = 0; i < perm.length; i++) {
        newState[perm[i].name] = perm[i].is_available;
      }
      return { ...state, permissions: newState };
    },

    clearStore(state) {
      removeAuthTokens();
      state.access_token = null;
      state.user = {} as User;
    },
  },
});

export const authReducer = authSlice.reducer;

export const {
  setUser,
  setUserSettings,
  setPermissions,
  clearStore,
  updateToken,
} = authSlice.actions;
