import React from 'react';

import cls from './SettingsPayPage.module.scss';
import { Button, ButtonTheme } from '../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { useTable } from '../../hooks/useTable';
import SettingsPayTable from './components/SettingsPayTable/SettingsPayTable';
import { useGetPaymentTypesQuery } from '../../services/settings/goPayments/goPayments';
import MainLoader from 'src/components/UI/Loaders/MainLoader';

const SettingsPayPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings-pay' });
  const { data, refetch, isFetching } = useGetPaymentTypesQuery({});
  const useTableReturned = useTable();

  if (isFetching) {
    return <MainLoader />;
  }

  return (
    <div className={cls['wrapper']}>
      <SettingsPayTable
        payList={data?.data || []}
        useTableReturned={useTableReturned}
        dataLoading={isFetching}
      />

      <Button
        onClick={() => refetch()}
        theme={ButtonTheme.PRIMARY}
        className={`${cls['update-pay']} mt-15 text-capitalize`}
      >
        {t('update-list-pays')}
      </Button>
    </div>
  );
};
export default SettingsPayPage;
