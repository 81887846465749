import {IThConfig} from "./Table";

export const getWidthStyles = (thConfig: IThConfig) => {
  let widthStyles:{
    width?: string,
    minWidth?: string,
    maxWidth?: string
  } = {};
  if(thConfig.width){
    const widthString = typeof thConfig.width === "number" ? thConfig.width + 'px' : thConfig.width;
    if(thConfig.width==='auto'){
      widthStyles = {
        width: widthString,
      }
      if(thConfig.minWidth){
        widthStyles.minWidth = typeof thConfig.minWidth === "number" ? thConfig.minWidth + 'px' : thConfig.minWidth
      }
    }else{
      widthStyles = {
        width: widthString,
        maxWidth: widthString,
        minWidth: widthString
      }
    }
  }
  return widthStyles
}