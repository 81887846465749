import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { Providers } from '../../models/types';

export const providersSlice = createApi({
  reducerPath: 'providers',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getProviders: builder.query<{ data: Array<Providers> }, any>({
      query: () => 'providers',
    }),
  }),
});

export const { useGetProvidersQuery  } = providersSlice;
