import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';

type InvoiceFileParams = {
  types: 'NovaPoshta' | 'Ukrposhta';
  id: number;
};

export const filesSlice = createApi({
  reducerPath: 'files',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getInvoice: builder.query<any, any>({
      query: (params: InvoiceFileParams) => ({
        url: `orders/invoices/print/${params.types}/${params.id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetInvoiceQuery } = filesSlice;
