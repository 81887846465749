import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../../models/sms';
import { baseQuery } from '../../utils/baseQuery';
import { ListResult } from '../../models/types';

export const smsApi = createApi({
  reducerPath: 'sms',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['sms'],
  endpoints: (builder) => ({
    getSender: builder.query<ListResult<I.Sender>, any>({
      query: () => ({ url: '/api/turbo/senders' }),
      providesTags: ['sms'],
    }),

    postSender: builder.mutation<I.SenderResponse, any>({
      query: (body) => ({ url: '/api/turbo/senders', body, method: 'POST' }),
      invalidatesTags: ['sms'],
    }),

    updateSender: builder.mutation<I.SenderResponse, any>({
      query: ({ body, id }) => ({
        url: `/api/turbo/senders/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['sms'],
    }),

    deleteSender: builder.mutation<I.SenderResponse, any>({
      query: (id) => ({
        url: `/api/turbo/senders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sms'],
    }),

    getSmsTemplatesList: builder.query<I.ISmsTemplatesListResponse, unknown>({
      query: (params: I.ISmsTemplatesListResponse) => ({
        url: '/templates/',
        params,
      }),
      transformResponse: (
        data: I.ISmsTemplatesListResponse,
        meta: FetchBaseQueryMeta
      ) => ({
        data: data.data,
        status: meta?.response?.status,
      }),
      providesTags: ['sms'],
    }),
    createSmsTemplate: builder.mutation<any, I.IRequestCreateSmsTemplate>({
      query: ({ body }) => ({
        url: '/templates/',
        body,
        method: 'POST',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['sms'],
    }),
    updateSmsTemplate: builder.mutation<any, I.IRequestUpdateSmsTemplate>({
      query: ({ id, body }) => ({
        url: `/templates/${id}`,
        body,
        method: 'PUT',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['sms'],
    }),
    deleteSmsTemplate: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: 'templates/delete',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['sms'],
    }),

    getMacros: builder.query<ListResult<I.Macros>, any>({
      query: () => ({ url: 'macros' }),
    }),
  }),
});

export const {
  useGetSenderQuery,
  usePostSenderMutation,
  useUpdateSenderMutation,
  useDeleteSenderMutation,
  useGetSmsTemplatesListQuery,
  useCreateSmsTemplateMutation,
  useUpdateSmsTemplateMutation,
  useDeleteSmsTemplateMutation,
  useGetMacrosQuery,
} = smsApi;
