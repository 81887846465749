import React, { FC } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
interface ErrorExtraProps {
  errorText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  className?: string;
}
const ErrorExtra: FC<ErrorExtraProps> = ({ errorText, className = '' }) =>
  typeof errorText === 'string' ? (
    <span
      
      className={`error-extra ${className}`}
    >
      {errorText}
    </span>
  ) : null;

export default ErrorExtra;
