import React, {FC} from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
import CrmLoader from './CRMLoader';

interface MainLoaderProps {
  withoutTitle?: boolean
}
const MainLoader:FC<MainLoaderProps> = ({withoutTitle}) => {
  return (
    <div className="main_loader">
      {/*<CircularProgress size={80} />*/}
      <CrmLoader />
      {!withoutTitle && <div>...ОБРОБКА ДОСТУПУ...</div>}
    </div>
  );
};

export default MainLoader;
