import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { GoPagList } from '../../models/types';
import { Order, ShortOrder } from '../../models/orders/orders';
import { getOrders, onOrderDelete } from '../../redux/slices/ordersSlce';

export const goOrdersApi = createApi({
  reducerPath: 'goOrders',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Orders', 'Order'],
  endpoints: (builder) => ({
    getOrders: builder.query<GoPagList<ShortOrder>, any>({
      query: (params) => ({ url: '/orders/', method: 'GET', params: params }),
      providesTags: ['Orders'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        if (!arg.all)
          await queryFulfilled.then((res) =>
            dispatch(getOrders(res.data as GoPagList<ShortOrder & Order>))
          );
      },
    }),
    getOrder: builder.query<Order, any>({
      query: (params) => ({ url: `orders/${params.id}/`, method: 'GET' }),
      providesTags: ['Order'],
    }),
    getTtnStatuses: builder.query<
      {
        nova: Array<{ id: number; text: string }>;
        ukr: Array<{ id: number; text: string }>;
      },
      any
    >({
      query: () => ({ url: 'orders/ttn/statuses', method: 'GET' }),
    }),

    postExtension: builder.mutation({
      query: (params) => ({
        url: 'api/np/terminate',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
    postModify: builder.mutation({
      query: (params) => ({
        url: 'api/np/modify',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
    postRedirection: builder.mutation({
      query: (params) => ({
        url: 'api/np/redirection',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
    postReturn: builder.mutation({
      query: (params) => ({
        url: 'api/np/return',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
    postCreateTTN: builder.mutation({
      query: (params) => ({
        url: '/orders/ttns',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),

    getLastNPCounterparty: builder.mutation({
      query: (params) => ({
        url: 'novaPoshtaSettings/last',
        method: 'GET',
        params: params,
      }),
    }),
    getLastUPCounterparty: builder.mutation({
      query: (params) => ({
        url: 'ukrPoshtaSettings/last',
        method: 'GET',
        params: params,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'orders/delete',
        method: 'POST',
        body: params,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(() => dispatch(onOrderDelete(arg)));
      },
    }),
    updateStatusInOrder: builder.mutation({
      query: (params) => ({
        url: `orders/${params.order_status}/statuses`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),
    getCheck: builder.query({
      query: ({ id, cashierId }) => ({
        url: cashierId ? `orders/sell/${id}/${cashierId}` : `orders/sell/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  usePostCreateTTNMutation,
  useGetLastNPCounterpartyMutation,
  useGetLastUPCounterpartyMutation,
  useLazyGetOrderQuery,
  useDeleteOrderMutation,
  useUpdateStatusInOrderMutation,
  useGetTtnStatusesQuery,
  usePostExtensionMutation,
  usePostModifyMutation,
  usePostRedirectionMutation,
  usePostReturnMutation,
  useLazyGetCheckQuery,
} = goOrdersApi;
