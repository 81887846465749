import React, { FC, useState } from 'react';
import Tooltip from 'src/components/UI/Tooltip/Tooltip';
import NotificationPopup from './NotificationPopup/NotificationPopup';
import { useTranslation } from 'react-i18next';
import useNotificationSound from 'src/hooks/useNotificationSound';
import NotificationAlert from './NotificationPopup/NotificationItem/NotificationAlert';
import { useGetNotificationsQuery } from 'src/services/users/goUsers';
import LogoutAlert from './NotificationPopup/NotificationItem/LogoutAlert';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { closeNewNotification } from 'src/redux/slices/notificationsSlice';
import BellIcon from 'src/assets/icons/tools/BellIcon';

const Notifications: FC = () => {
  useNotificationSound();
  const dispatch = useTypedDispatch();
  const { isLogout, notifications, newNotifications } = useTypedSelector(
    selectors.getNotifications
  );

  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refetch } = useGetNotificationsQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const onClose = (id: number) => {
    dispatch(closeNewNotification(id));
    refetch();
  };

  return (
    <>
      <Tooltip title={t('t-title-notifications')}>
        <BellIcon
          className={'header-icon stroke-path'}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </Tooltip>
      {isOpen && (
        <NotificationPopup
          show={isOpen}
          close={() => {
            setIsOpen(false);
          }}
          data={notifications}
        />
      )}
      {isLogout && <LogoutAlert />}
      {newNotifications?.map((i) => (
        <NotificationAlert
          key={i.id}
          notification={i}
          onClose={() => onClose(i.id)}
        />
      ))}
    </>
  );
};

export default Notifications;
