import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { PagListResult } from 'src/models/types';
import Invoice from 'src/models/sending/invoices';

type InvoicesParams = {
  id: number;
  product_id: number;
  delivery_type_id: number;
  invoice_type: string;
  payment_type_id: number;
  office_id: number;
};
export const goInvoicesServicesSlice = createApi({
  reducerPath: 'goInvoices',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getInvoices: builder.query<PagListResult<Invoice>, any>({
      query: (params: InvoicesParams) => ({
        url: 'orders/invoices',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetInvoicesQuery } = goInvoicesServicesSlice;
