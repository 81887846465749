import React, { FC, useEffect, useRef, useState } from 'react';
import cls from './Select.module.scss';
import { IOption } from './Select';
import { Tooltip } from '@mui/material';

interface IProps {
  option: IOption;
  open: boolean;
  filteredOptions: IOption[]
}
const DropdownItemEntry: FC<IProps> = ({ option, open, filteredOptions }) => {
  const entryRef = useRef<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  useEffect(() => {
    if (entryRef.current) {
      const element = entryRef.current;
      let spanWidth = 0;
      element.querySelectorAll('span').forEach((span) => {
        spanWidth =
          spanWidth +
          span.scrollWidth +
          (+window
            .getComputedStyle(span)
            .getPropertyValue('margin-left')
            .replace('px', '') || 0);
      });
      const scrollWidth =
        spanWidth +
        (element.querySelector('.dropdown__item-text')?.scrollWidth || 0) +
        (element.querySelector('img')?.scrollWidth || 0) + 1;
      const isOverflowed =
        element.offsetWidth < scrollWidth &&
        element.offsetWidth !== scrollWidth;
      setIsOverflow(isOverflowed);
    } else {
      setIsOverflow(false);
    }
  }, [entryRef.current, filteredOptions]);

  return (
    <Tooltip
      data-mui-portal="false"
      placement="right"
      title={
        isOverflow ? (
          <div className={cls['tooltip-inner']}>
            {option.element}
            {option.title}
          </div>
        ) : option.tooltipTitle
      }
    >
      <div className={cls['dropdown__item-entry']} ref={entryRef}>
        {option.element && (
          <div className={cls['dropdown__item-icon']}>{option.element}</div>
        )}
        {option?.title && (
          <div className={`${cls['dropdown__item-text']} dropdown__item-text`}>
            {option.title}
            {option?.dropdownOnlyElement}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
export default DropdownItemEntry;
