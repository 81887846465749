import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AsyncFilters, AsyncSelectEvent,
  FilterTypes,
  IConfig,
  IFilterOptions,
  IFilterSelectType,
} from '../Table';
import { IUseTableReturned } from 'src/hooks/useTable';
import DefaultSelect from './DefaultSelect';
import { IOptionValue } from '../../Select/Select';
import CountrySelect from './CountrySelect';
import TableDateRange from './TableDateRange';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';
import TableDate from './TableDate';
import { useAppDispatch, useAppSelector } from 'src/redux';
import {
  resetFilters,
  setFilter,
  setFilters,
  setFiltersIsRefetch,
} from 'src/redux/slices/tableDataOperations';
import selectors from 'src/redux/selectors';
import { getWidthStyles } from '../helpers';
import AsyncSelectConfig from "./AsyncSelectConfig";
import {UseAsyncSelectProps} from "src/hooks/useAsyncSelect";
interface IProps {
  config: IConfig;
  filterOptions?: IFilterOptions;
  useTableReturned?: IUseTableReturned;
  asyncFilters?: AsyncFilters;
}
export interface ISetFiltersDataConfig {
  dateRangeIndex: number;
}
const TableFilters = ({ config, filterOptions, useTableReturned, asyncFilters }: IProps) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectors.getFilters);
  const { params, setParams } = useSearchQueryParams({});
  const [filtersDataTouched, setFiltersDataTouched] = useState<boolean>(false);

  const dateRangeThArray = useMemo(() => {
    const dateRangeFields: string[] = [];
    config.th.forEach((item) => {
      (Array.isArray(item.filter) ? item.filter : [item.filter]).forEach(
        (element) => {
          if (element?.type === FilterTypes.DATE_RANGE) {
            dateRangeFields.push(element.parameterName || item.columnId);
          }
        }
      );
    });
    return dateRangeFields;
  }, [config.th]);

  const searchHandler = useCallback(
    (params?: any) => {
      const setParamsLocal = useTableReturned?.setParams || setParams;
      if (setParamsLocal) {
        let res: any = {};
        if (params) {
          res = params;
        } else {
          res = Object.keys(filters.data).length
            ? { ...filters.data, page: 1 }
            : { ...filters.data };
        }

        Object.keys(res).forEach((key) => {
          if (dateRangeThArray.includes(key) && Array.isArray(res[key])) {
            res[key] = res[key].join('-');
          }
        });
        setParamsLocal(res);
      }
    },
    [setParams, filters, dateRangeThArray, useTableReturned?.setParams]
  );
  useEffect(() => {
    if (
      useTableReturned &&
      useTableReturned?.searchHandler.current !== searchHandler
    ) {
      useTableReturned.searchHandler.current = searchHandler;
    }
  }, [searchHandler]);

  const setFiltersDataHandler = (
    key?: string,
    value?: string | number | null,
    isSearchCall?: boolean,
    config?: ISetFiltersDataConfig,
    asyncSelect?: AsyncSelectEvent
  ) => {
    if (!filtersDataTouched) return;
    dispatch(
      setFilter({
        key,
        value,
        isSearchCall,
        config,
        asyncSelect
      })
    );
  };

  const enterKeyHandler = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.code === 'Enter' &&
        (document.querySelectorAll('[data-disable-enter-handler="1"]').length
          ? document
              .querySelectorAll('.popup__overlay.show')[0]
              ?.querySelectorAll('.table__tr-filter')?.length
          : true)
      ) {
        event.preventDefault();
        event.stopPropagation();
        searchHandler();
      }
    },
    [searchHandler]
  );
  useEffect(() => {
    if (
      useTableReturned &&
      useTableReturned?.setFiltersDataHandler.current !== setFiltersDataHandler
    ) {
      useTableReturned.setFiltersDataHandler.current = setFiltersDataHandler;
    }
  }, [setFiltersDataHandler]);
  useEffect(() => {
    document.addEventListener('keyup', enterKeyHandler);
    return () => {
      document.removeEventListener('keyup', enterKeyHandler);
    };
  }, [searchHandler]);

  useEffect(() => {
    if (params && config.th.length) {
      const paramsLocal = { ...params };
      const resFiltersData: any = {};

      Object.entries(paramsLocal).map(([paramName, paramValue]) => {
        if (dateRangeThArray.includes(paramName)) {
          resFiltersData[paramName] = paramValue
            .split('-')
            .map((item: string) => item || null);
        } else {
          resFiltersData[paramName] = paramValue;
        }
      });
      dispatch(
        setFilters({
          data: resFiltersData,
          isRefetch: false,
        })
      );
      setFiltersDataTouched(true);
    }
  }, [params, setFilters, config.th, dateRangeThArray]);

  useEffect(() => {
    if (filters.isRefetch) {
      searchHandler();
      dispatch(setFiltersIsRefetch(false));
    }
  }, [searchHandler, filters]);

  const resetParamsHandler = useCallback(() => {
    dispatch(resetFilters());
  }, []);

  useEffect(() => {
    const resetParamsButton = document.querySelector('.reset-params');
    if (resetParamsButton && filters.data) {
      if (
        Object.keys(filters.data)
          .filter((key) => key !== 'pagination')
          .filter((key) => filters.data[key]).length
      ) {
        resetParamsButton.classList.add('active');
      } else {
        resetParamsButton.classList.remove('active');
      }
      resetParamsButton.addEventListener('click', resetParamsHandler);
      return () => {
        resetParamsButton.removeEventListener('click', resetParamsHandler);
        resetParamsButton.classList.remove('active');
      };
    }
  }, [document.querySelector('.reset-params'), filters]);
  const filtersData = filters.data || {};
  return (
    <>
      <tr className="table__tr-filter">
        {config.th
          .filter((i) => !!i)
          .map((thConfig) => (
            <th key={thConfig.columnId} style={getWidthStyles(thConfig)}>
              <div
                className={Array.isArray(thConfig.filter) ? 'align-center' : ''}
              >
                {(Array.isArray(thConfig.filter)
                  ? thConfig.filter
                  : [thConfig.filter]
                ).map((item, itemIndex) => {
                  
                  let element: any = <div key={itemIndex} />;
                  const fieldName = item?.parameterName || thConfig.columnId;
                  if (item?.type === FilterTypes.TEXT) {
                    const inputConfig = { ...item.inputConfig };
                    delete inputConfig.inputType;
                    element = (
                      <div
                        className={`${'table__tr-filter--item'} ${'table__tr-filter--text'} ${item.config?.className}`}
                        style={item.config?.style}
                        key={itemIndex}
                      >
                        <div>
                          {
                            <input
                              id={`${thConfig.columnId}`}
                              className="table-input"
                              {...inputConfig}
                              onChange={(event) => {
                                setFiltersDataHandler(
                                  fieldName,
                                  event.target.value
                                );
                              }}
                              value={
                                filters.data?.[fieldName] || ''
                              }
                              type={item.parameterType || undefined}
                            />
                          }
                        </div>
                      </div>
                    );
                  }
                  if (item?.type === FilterTypes.SELECT) {
                    let selectElement;
                    if (item.selectType === IFilterSelectType.COUNTRY) {
                      selectElement = (
                        <CountrySelect
                          item={item}
                          thConfig={thConfig}
                          filterOptions={filterOptions}
                          itemIndex={itemIndex}
                          disablePortal={false}
                          onChange={(
                            value: IOptionValue,
                            event?: React.MouseEvent
                          ) => {
                            setFiltersDataHandler(
                              fieldName,
                              value,
                              !!event
                            );
                          }}
                          propsValue={
                            filtersData[
                              fieldName
                            ]
                          }
                        />
                      );
                    } else if (item.selectType === IFilterSelectType.ASYNC){
                      if(!asyncFilters?.[fieldName]) return console.error ("неправильный конфиг ассинхронных фильтров, отсутсвует значение для " + fieldName);
                      selectElement = (
                        <AsyncSelectConfig
                          item={item}
                          asyncFilter={asyncFilters[fieldName] as UseAsyncSelectProps}
                          fieldName={fieldName}
                          onChange={(
                            value: IOptionValue,
                            event?: React.MouseEvent,
                            asyncSelect?: AsyncSelectEvent
                          ) => {
                            setFiltersDataHandler(
                              fieldName,
                              value as string,
                              !!event,
                              undefined,
                              asyncSelect
                            );
                          }}
                          propsValue={
                            filtersData[
                              fieldName
                            ]
                          }
                        />
                      );
                    }
                    else {
                      selectElement = (
                        <DefaultSelect
                          item={item}
                          thConfig={thConfig}
                          filterOptions={filterOptions}
                          itemIndex={itemIndex}
                          onChange={(
                            value: IOptionValue,
                            event?: React.MouseEvent
                          ) => {
                            setFiltersDataHandler(
                              fieldName,
                              value as string,
                              !!event
                            );
                          }}
                          propsValue={
                            filtersData[
                              fieldName
                            ]
                          }
                        />
                      );
                    }
                    element = (
                      <div
                        className={`${'table__tr-filter--item'} ${'table__tr-filter--select'} ${item.config?.className}`}
                        style={item.config?.style}
                        key={itemIndex}
                      >
                        <div>{selectElement}</div>
                      </div>
                    );
                  }
                  if (item?.type === FilterTypes.DATE) {
                    element = (
                      <TableDate
                        key={itemIndex}
                        item={item}
                        thConfig={thConfig}
                        filtersData={filtersData}
                        setFiltersDataHandler={setFiltersDataHandler}
                      />
                    );
                  }
                  if (item?.type === FilterTypes.DATE_RANGE) {
                    element = (
                      <TableDateRange
                        key={itemIndex}
                        item={item}
                        thConfig={thConfig}
                        filtersData={filtersData}
                        setFiltersDataHandler={setFiltersDataHandler}
                      />
                    );
                  }

                  return element;
                })}
              </div>
            </th>
          ))}
      </tr>
    </>
  );
};
export default TableFilters;
