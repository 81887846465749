import React, { useEffect } from 'react';
import {
  IFilter,
  IThConfig,
} from '../Table';
import {
  DatePickerComponent,
  DatePickerTheme,
} from '../../DatePickerComponent/DatePickerComponent';
import { ISetFiltersDataConfig } from './TableFilters';
import dayjs from 'dayjs';
interface IProps {
  item: IFilter;
  thConfig: IThConfig;
  filtersData: {
    [key: string]: any;
  },
  setFiltersDataHandler: (key?:string, value?:string|number|null, isSearchCall?: boolean, config?: ISetFiltersDataConfig)=>void
}

const TableDate = ({ item, thConfig, filtersData, setFiltersDataHandler }: IProps) => {
  useEffect(() => {
    if (!filtersData.created_at) {
      setFiltersDataHandler(
        'created_at',
        dayjs().format('YYYY.MM.DD'),
        true
      );
    }
  }, [filtersData.created_at, setFiltersDataHandler]);
  return (
      <div
        className={`${'table__tr-filter--item'} ${'table__tr-filter--text'} ${item.config?.className}`}
        style={item.config?.style}
      >
        <div>
          <div className="display-flex">
            <DatePickerComponent
              {...item.dataConfig}
              placeholder={'Дата'}
              className="table__tr-filter-datepicker"
              theme={DatePickerTheme.FROM_TABLE}
              disableFuture
              format="YYYY.MM.DD"
              onChange={(value) => {
                setFiltersDataHandler(
                  item.parameterName || thConfig.columnId,
                  value,
                  true
                );
              }}
              propsValue={
                filtersData[
                  item?.parameterName || thConfig.columnId
                ]
              }
            />
          </div>
        </div>
      </div>
  );
}
export default TableDate