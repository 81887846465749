import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogoWhiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={132}
    height={129}
    fill="none"
    viewBox="0 0 132 129"
    {...props}
  >
    <path
      fill="#fff"
      d="M65.16 16.28q-10.885.07-20.573 4.91c-10.912 5.445-19.68 13.893-24.515 25.11-.361.833-.575 1.614-1 3-.857 2.812-1.964 7.04-6.078 5.646-2.892-.976-2.484-4.857-1.599-7.166q1.66-4.327 4.6-10.042 2.717-5.28 6.176-9.24 6.465-7.388 15.38-12.274 9.469-4.989 17.253-6.344 22.179-3.847 41.59 7.584 6.074 3.578 10.817 8.516c1.693 1.753 1.474 4.974-1.171 5.84q-2.56.832-4.483-1.033c-9.418-9.15-23.13-14.667-36.397-14.507M120.968 87.895a171 171 0 0 1-1.838 3.803 53.5 53.5 0 0 1-10.9 14.801c-3.589 3.446-7.475 6.615-11.658 9.301l-3.043-5.004a.45.45 0 0 1 .01-.512c.254-.384.658-.539.533-.984 2.086-1.065 3.765-2.378 5-3.5 5.393-4.91 9.097-8.698 12.421-14.627q6.753-12.042 6.698-26.152a57.7 57.7 0 0 0-2.613-13.998q-.898-2.847-2.891-6.61a755 755 0 0 1-3.343-6.39c-1.19-2.292.083-4.597 2.595-4.478q2.133.095 3.485 2.121 4.803 7.206 7.454 16.03 5.545 18.415-1.91 36.2"
    />
    <path
      fill="#fff"
      d="M76.978 34.673q10.047 3.232 16.28 11.014 8.04 10.048 7.261 23.299c-.445 7.629-4.246 14.761-9.055 20.717-1.448 1.793-3.596 2.88-5.892 1.597-4.516-2.517 1.013-7.416 2.73-9.742q1.65-2.22 2.474-4.074c4.107-9.275 2.96-20.365-4.092-27.862q-7.73-8.218-18.589-9.285c-3.551-.348-4.046-5.034-1.156-6.595 2.444-1.315 7.512.169 10.04.93M34.674 60.252c1.03-5.623 3.144-11.318 6.75-15.615 1.91-2.27 3.86-4.172 6.648-5.337 2.965-1.236 4.915-.584 5.921 2.59.51 1.598-.553 2.571-1.928 3.466-6.309 4.089-10.3 11.792-10.62 19.248q-.524 12.246 9.83 20.585c4.508 3.625 9.399 5.869 15.087 6.153 2.288.118 6.893-.666 6.71 2.958-.141 2.715-1.788 4.366-4.867 4.297q-2.17-.05-6.61-.826-11.324-1.99-19.034-10.306-10.742-11.577-7.887-27.213"
    />
    <path
      fill="#fff"
      d="M94.072 109.3q.19.665-.191 1.236c-.11.167-.135.184-.03.356l2.721 4.908c-5.419 3.109-10.918 5.163-17.182 6.226q-2.716.458-5.72.884-2.552.08-5.244.086-1.738.002-4.615-.333c-2.068-.238-4.203-.201-6.147-.63-5.378-1.176-10.652-2.851-15.535-5.393-2.149-1.114-4.285-2.19-6.263-3.475q-5.99-3.876-9.764-7.696-3.14-3.175-7.334-9.358a52 52 0 0 1-5.62-10.773q-3.902-9.115-4.445-19.439c-.101-1.907-.31-4.142.645-5.659.99-1.565 2.505-2.24 4.724-1.94.422.057.78.23 1.024.684.84 1.562.976 3.316 1.07 5.368q-.354 7.002 1.49 12.887c1.15 3.674 2.23 7.379 3.977 10.61q3.005 5.533 6.38 9.751 2.172 2.722 5.908 5.642 4.052 3.175 9.095 6.171a44 44 0 0 0 9.683 4.305q11.678 3.558 23.465 1.904 7.598-1.062 13.947-4.225c1.179-.585 2.482-.945 3.961-2.097"
    />
  </svg>
);
export default SvgLogoWhiteIcon;
