import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={12}
    fill="none"
    viewBox="0 0 13 12"
    {...props}
  >
    <path
      fill="#000"
      d="M8.401 4.29h-5.32v-.84H8.4zm0 2.129h-5.32v-.84H8.4zm0 2.128h-5.32v-.84H8.4zM5.741.257a5.747 5.747 0 0 1 5.74 5.741v.02a5.65 5.65 0 0 1-1.35 3.673l.006-.008 1.876 2.056H5.741a5.74 5.74 0 1 1 0-11.481M10.109 10.9 8.994 9.675l.275-.284a4.82 4.82 0 0 0 1.372-3.374v-.02.001a4.9 4.9 0 1 0-4.9 4.901z"
    />
  </svg>
);
export default SvgChatIcon;
