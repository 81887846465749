import React, { FC } from 'react';
import { Input, InputType } from 'src/components/UI/Input/Input';
import cls from '../UserSettingsPopup.module.scss';
import {
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from 'src/components/UI/Select/Select';
import CountryFlag from 'src/components/common/CountryFlag';
import { EnumLanguage } from 'src/models/enums';
import useEnumTranslate from 'src/hooks/translate/useEnumTranslate';
import {
  SetFieldValue,
  UseFormRegister,
} from 'react-hook-form/dist/types/form';

interface UserSettingsProps {
  register: UseFormRegister<any>;
  t: any;
  watchAllFields: any;
  setValue: SetFieldValue<any>;
}

const CommonUserSettings: FC<UserSettingsProps> = ({
  t,
  setValue,
  watchAllFields,
  register,
}) => {
  const { languageTypesMap } = useEnumTranslate();

  return (
    <div className="columns columns--w-25 columns--padding-10 mt-25">
      <div className="columns__item">
        <div className="columns__label">
          <span>{t('user-name')}</span>
        </div>
        <div className="columns__value">
          <div>
            <Input
              readOnly
              register={register('first_name')}
              type={InputType.POPUP_INPUT}
            />
          </div>
        </div>
      </div>
      <div className="columns__item">
        <div className="columns__label">
          <span>{t('user-surname')}</span>
        </div>
        <div className="columns__value">
          <div>
            <Input
              readOnly
              register={register('last_name')}
              type={InputType.POPUP_INPUT}
            />
          </div>
        </div>
      </div>

      <div className="columns__item">
        <div className="columns__label">
          <span>{t('language')}</span>
        </div>
        <div className="columns__value">
          <div className={cls['language-select-wrapper']}>
            <Select
              controllerTheme={SelectControllerTheme.TRANSPARENT}
              dropdownTheme={SelectDropdownTheme.TOP_UNBORDERED}
              stylesConfig={{
                isNoFixedArrowPosition: true,
              }}
              propsValue={watchAllFields['crm_language']}
              register={register('crm_language')}
              setValueInForm={setValue}
              options={[
                {
                  title: null,
                  value: 'uk',
                  element: (
                    <div className="align-center">
                      <CountryFlag iso2={'UA'} emoji={'🇺🇦'} />
                      <span>{languageTypesMap[EnumLanguage.ua]}</span>
                    </div>
                  ),
                },
                {
                  title: null,
                  value: 'ru',
                  element: (
                    <div className="align-center">
                      <CountryFlag iso2={'RU'} emoji={'🇷🇺'} />
                      <span>{languageTypesMap[EnumLanguage.ru]}</span>
                    </div>
                  ),
                },
                {
                  title: null,
                  value: 'en',
                  element: (
                    <div className="align-center">
                      <CountryFlag iso2={'GB'} emoji={'🇬🇧'} />
                      <span>{languageTypesMap[EnumLanguage.uk]}</span>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonUserSettings;
