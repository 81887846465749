import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { PagListResult } from 'src/models/types';
import {
  CreateOrderStatusTemplate,
  OrderStatus,
  OrderStatusAndOrderTrackingStatusTemplate,
  OrderStatusTemplate,
  OrderTrackingStatusTemplate,
} from 'src/models/orders/orders';

export const orderStatusesApi = createApi({
  reducerPath: 'orderStatuses',
  baseQuery,
  tagTypes: [
    'Orders',
    'OrderStatusTemplates',
    'OrderTrackingStatusTemplates',
    'OrderStatusAndOrderTrackingStatusTemplates',
  ],
  endpoints: (builder) => ({
    deleteOrderStatuses: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'statuses/delete/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Orders'],
    }),
    getOrderStatusTemplates: builder.query<PagListResult<OrderStatus>, any>({
      query: () => ({
        url: '/statusTemplates/',
        method: 'GET',
      }),
      providesTags: ['OrderStatusTemplates'],
    }),

    postOrderStatusTemplates: builder.mutation<
      OrderStatus,
      { body: CreateOrderStatusTemplate }
    >({
      query: (body) => ({
        url: '/trackingStatusTemplate/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'OrderStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),

    updateOrderStatusTemplates: builder.mutation<
      OrderStatus,
      { body: OrderStatusTemplate; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/trackingStatusTemplate/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'OrderStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),

    deleteOrderStatusTemplates: builder.mutation<number, any>({
      query: (id) => ({
        url: `/trackingStatusTemplate/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'OrderStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),

    getStatusTemplates: builder.query<
      {
        data: { data: OrderStatusAndOrderTrackingStatusTemplate[] };
        code: number;
      },
      any
    >({
      query: () => ({
        url: '/trackingStatusTemplate/all',
        method: 'GET',
      }),
      providesTags: ['OrderStatusAndOrderTrackingStatusTemplates'],
    }),

    getOrderTrackingStatusTemplates: builder.query<
      PagListResult<OrderTrackingStatusTemplate>,
      any
    >({
      query: () => ({
        url: '/trackingStatusTemplate/',
        method: 'GET',
      }),
      providesTags: ['OrderTrackingStatusTemplates'],
    }),

    postOrderTrackingStatusTemplates: builder.mutation<
      OrderStatus,
      { body: CreateOrderStatusTemplate }
    >({
      query: (body) => ({
        url: '/trackingStatusTemplate/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'OrderTrackingStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),

    updateOrderTrackingStatusTemplates: builder.mutation<
      OrderStatus,
      { body: OrderStatusTemplate; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/trackingStatusTemplate/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'OrderTrackingStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),

    deleteOrderTrackingStatusTemplates: builder.mutation<number, any>({
      query: (id) => ({
        url: `/trackingStatusTemplate/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'OrderTrackingStatusTemplates',
        'OrderStatusAndOrderTrackingStatusTemplates',
      ],
    }),
  }),
});

export const {
  useDeleteOrderStatusesMutation,
  usePostOrderStatusTemplatesMutation,
  useUpdateOrderStatusTemplatesMutation,
  useDeleteOrderStatusTemplatesMutation,
  usePostOrderTrackingStatusTemplatesMutation,
  useUpdateOrderTrackingStatusTemplatesMutation,
  useDeleteOrderTrackingStatusTemplatesMutation,
  useGetStatusTemplatesQuery,
} = orderStatusesApi;
