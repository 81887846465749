import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export interface FixStateReset {
  fixStateResetValue: boolean,
}

const initialState: FixStateReset = {
  fixStateResetValue: false,
};

export const fixStateResetSlice = createSlice({
  name: 'fixStateReset',
  initialState,
  reducers: {
    setFixStateResetValue(state, action: PayloadAction<boolean>){
      state.fixStateResetValue = action.payload
    }
  },
});


export const { setFixStateResetValue } = fixStateResetSlice.actions;
