import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCustomerAgentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#3152E7"
      stroke="#3152E7"
      strokeWidth={0.038}
      d="M3.41 6.722V6.47h-.843a1.52 1.52 0 0 0-1.52 1.517v1.356c.002.837.681 1.515 1.52 1.516h.844v-.248c.057.449.179.877.356 1.275h-1.2A2.55 2.55 0 0 1 .02 9.342V7.986a2.55 2.55 0 0 1 2.013-2.487q-.017.226-.017.457v.018H3.12v-.018q0-.26.026-.514h.623c-.178.4-.3.83-.358 1.28Zm-1.357-.785q0-.225.017-.446.242-.049.497-.049h.541a5 5 0 0 0-.026.495zm1.358 1.37c0-.664.143-1.295.399-1.865h.63v6.443h-.632a4.56 4.56 0 0 1-.397-1.865zm1.048-1.903h-.632a4.59 4.59 0 0 1 4.17-2.674 4.59 4.59 0 0 1 4.17 2.674h-.644v1.437a3.555 3.555 0 0 0-3.527-3.083A3.555 3.555 0 0 0 4.477 6.79V5.404zm.018 5.13a3.56 3.56 0 0 0 3.52 3.036 3.56 3.56 0 0 0 3.526-3.088v1.441h.645a4.59 4.59 0 0 1-4.172 2.674 4.59 4.59 0 0 1-4.171-2.674h.652v-1.389ZM8 .018c3.115 0 5.67 2.39 5.926 5.433a2.6 2.6 0 0 0-.493-.048h-.545A4.916 4.916 0 0 0 8 1.046a4.916 4.916 0 0 0-4.888 4.358h-.545q-.254 0-.493.048A5.944 5.944 0 0 1 8 .019Zm5.433 5.423q.255 0 .497.049.016.22.017.446h-1.029q-.001-.25-.026-.495zm.552.514a6 6 0 0 0-.017-.457 2.55 2.55 0 0 1 2.013 2.487v1.356a2.55 2.55 0 0 1-1.996 2.483v-.473H12.88v.533h-.654c.185-.415.31-.864.363-1.333v.307h.844a1.52 1.52 0 0 0 1.52-1.517V7.986a1.52 1.52 0 0 0-1.52-1.517h-.844v.311a4.6 4.6 0 0 0-.364-1.338h.629q.027.253.027.514v.018h1.104zm-1.067 6.125v-.158h.515q.265 0 .514-.052v.21a3.917 3.917 0 0 1-3.894 3.9v-1.027a2.886 2.886 0 0 0 2.865-2.873Zm1.03-.692v.444a2.6 2.6 0 0 1-.515.052h-.515v-.496zM11.56 5.442h.623c.256.57.398 1.201.4 1.866v2.712c0 .664-.143 1.296-.398 1.865h-.625z"
    />
  </svg>
);
export default SvgCustomerAgentIcon;
