import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { GoPagList } from 'src/models/types';
import { Product } from 'src/models/products';
import { ProductParams } from './products';

export const goProductsSlice = createApi({
  reducerPath: 'goProducts',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query<GoPagList<Product>, any>({
      query: (params: ProductParams) => ({
        url: 'products',
        method: 'GET',
        params: params,
        providesTags: ['Products'],

      }),
    }),
  }),
});

export const { useGetProductsQuery } = goProductsSlice;
export const { resetApiState,  } = goProductsSlice.util;