import { useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export interface SearchQueryParams {
  [key:string]: any;
}

const useSearchQueryParams = (initialParams: SearchQueryParams) => {
  const router = useNavigate();
  const location = useLocation();
  const [params, setQuery] = useState({
    ...initialParams,
    ...queryString.parse(location.search),
  });

  const setParams = (queryObject: SearchQueryParams) => {
    if (location.pathname) {
      const currentQuery = queryString.parseUrl(location.pathname).query;
      const changedSearchQuery = queryString.stringify(
        { ...currentQuery, ...queryObject },
        { skipEmptyString: true, skipNull: true }
      );
      setQuery((prevQuery) => ({
        ...prevQuery,
        ...queryObject,
      }));
      return router(
        {
          pathname: location.pathname,
          search: createSearchParams(changedSearchQuery).toString(),
        },
        { replace: false }
      );
    }
  };
  return { setParams, params };
};

export default useSearchQueryParams;
