import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../../models/writeoff';
import { baseQuery } from '../../utils/baseQuery';

export const writeoffApi = createApi({
  reducerPath: 'writeoff',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['Clients'],
  endpoints: (builder) => ({
    getWriteoffList: builder.query<I.IWriteoffListResponse, unknown>({
      query: (params?: { product?: string }) => ({
        url: '/writeOffs/',
        params,
      }),
      transformResponse: (
        data: I.IWriteoffListResponse,
        meta: FetchBaseQueryMeta
      ) => ({
        data: data.data,
        status: meta?.response?.status,
      }),
    }),
    getWriteoffItem: builder.query<I.IWriteoffListItem, { id: string }>({
      query: ({ id }) => {
        if (!id || id === 'all') throw new Error('Missing required data');
        return { url: `/writeOffs/${id}` };
      },
      transformResponse: (data: any, meta: FetchBaseQueryMeta) => {
        return data as I.IWriteoffListItem;
      },
    }),

    createWriteoff: builder.mutation<any, I.IRequestCreateWriteoff>({
      query: ({ body }) => ({
        url: '/writeOffs/',
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetWriteoffListQuery,

  useGetWriteoffItemQuery,
  useCreateWriteoffMutation,
} = writeoffApi;
