import React, { useMemo } from 'react';

import { ReactComponent as MoneyStackIcon } from 'src/assets/img/icons/money-stack-1-icon.svg';

import { useTranslation } from 'react-i18next';
import { IPageTable, Table } from 'src/components/UI/Table/Table';

import Tooltip from 'src/components/UI/Tooltip/Tooltip';
import { Payment } from 'src/models/types';
import paymentTypesIcons from 'src/utils/constans/PaymentTypesIcons';
import useEnumTranslate from 'src/hooks/translate/useEnumTranslate';

interface IProps extends IPageTable {
  payList: Array<Payment>;
}

const SettingsPayTable = ({ useTableReturned, payList, dataLoading }: IProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings-pay' });
  const { paymentTypesMap } = useEnumTranslate();

  const tableData = useMemo(() => {
    const res: any[] = [];
    payList.forEach((item) => {
      res.push({
        data: {
          id: item.id,
          style: {
            height: '24px',
          },
        },
        elements: {
          id: {
            element: <div className="text-13-111 text-center">{item.id}</div>,
            sortValue: item.id
          },
          name: {
            element: <div className="text-13-000 align-center">
              {paymentTypesIcons[item.name] || paymentTypesIcons['_other']}
              <span>{ paymentTypesMap[item.name] || item.name}</span>
            </div>,
            sortValue: paymentTypesMap[item.name] || item.name
          },
        },
      });
    });
    return res;
  }, [payList]);

  return (
    <Table
      config={{
        th: [
          {
            element: t('th-id'),
            columnId: 'id',
            width: 40,
          },
          {
            element: (
              <div className="flex-center">
                <Tooltip title={t('t-title-name')}>
                  <MoneyStackIcon className="icon" />
                </Tooltip>
              </div>
            ),
            columnId: 'name',
            width: 210,
          },
        ],
      }}
      tableData={tableData}
      useTableReturned={useTableReturned}
      dataLoading={dataLoading}
      hasSort
    />
  );
};
export default SettingsPayTable;
