import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSignOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    viewBox="0 0 16 15"
    {...props}
  >
    <path fill="#212121" d="M5.866 8.803a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6" />
    <path
      fill="#212121"
      d="M8.533.533A.533.533 0 0 0 7.92.006L.453 1.147A.53.53 0 0 0 0 1.675v11.584c0 .263.192.487.453.527l7.466 1.142a.533.533 0 0 0 .614-.527V7.467h5.517l-1.063.932a.534.534 0 0 0 .701.802l2.13-1.866a.533.533 0 0 0 0-.803l-2.13-1.867a.532.532 0 1 0-.701.803l1.063.932H8.534zm-1.066.621V13.78l-6.4-.98V2.134z"
    />
    <path
      fill="#212121"
      d="M10.133 13.865H9.6V8.53h1.066v4.8a.533.533 0 0 1-.533.534M9.6 5.337V1.07h.533c.294 0 .533.24.533.534v3.733z"
    />
  </svg>
);
export default SvgSignOutIcon;
