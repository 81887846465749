import React, { ReactNode } from 'react';
import cls from './Button.module.scss';
import './Button.scss';
import { ReactComponent as SearchIcon } from 'src/assets/img/icons/search-icon.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/img/icons/settings-icon.svg';
import { ReactComponent as AddIcon } from 'src/assets/img/icons/add-icon.svg';
import { ReactComponent as PrevArrowIcon } from 'src/assets/img/icons/prev-arrow-icon.svg';
import { ReactComponent as NextArrowIcon } from 'src/assets/img/icons/next-arrow-icon.svg';
import { ReactComponent as ButtonInfoIcon } from 'src/assets/img/icons/button-info-icon.svg';
import { ReactComponent as CartClearIcon } from 'src/assets/img/icons/cart-clear-icon.svg';
import { useTranslation } from 'react-i18next';
export enum ButtonTheme {
  PRIMARY = 'primary',
  PRIMARY_DASHED = 'primary-dashed',
  SECONDARY = 'secondary',
  BLACK = 'black',
  TRANSPARENT = 'transparent',
  LINK = 'link',
  TRANSPARENT_TABLE_PAGE = 'transparent-table-page',
  // SECONDARY_RED = 'secondary-red'
}
export enum ButtonType {
  SEARCH = 'search',
  SETTINGS = 'settings',
  ADD = 'add',
  // CLEAR_CART = 'clear-cart',
  PREV = 'prev',
  NEXT = 'next',
  SELECTED = 'SELECTED',
  CART_CLEAR = 'cart-clear',
}

interface IProp {
  className?: string;
  theme?: ButtonTheme;
  type?: ButtonType;
  children?: ReactNode;
  icon?: ReactNode;
  additionalData?: string | number;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonType?: 'submit' | 'button';
}
export const Button = ({
  className,
  theme,
  type,
  children,
  icon,
  onClick,
  additionalData,
  isDisabled,
  buttonType,
}: IProp) => {
  const { t } = useTranslation('translation', { keyPrefix: 'buttons' });
  let buttonAdditionalClasses = '';
  const ButtonTypeConfig: Record<
    ButtonType,
    { icon?: ReactNode; text?: ReactNode; buttonAdditionalClass?: string }
  > = {
    [ButtonType.SEARCH]: {
      icon: <SearchIcon />,
    },
    [ButtonType.SETTINGS]: {
      icon: <SettingsIcon />,
    },
    [ButtonType.NEXT]: {
      icon: <NextArrowIcon />,
      // buttonAdditionalClass:""
    },
    [ButtonType.PREV]: {
      icon: <PrevArrowIcon />,
      // buttonAdditionalClass:""
    },
    [ButtonType.ADD]: {
      icon: <AddIcon />,
      text: <span>{t('add')}</span>,
    },
    [ButtonType.CART_CLEAR]: {
      icon: <CartClearIcon />,
      // text: <span className={cls['cart-clear']}>
      //     {t('cart_clear')}
      // </span>
    },
    [ButtonType.SELECTED]: {
      icon: <ButtonInfoIcon />,
      text: <span>{`${t('selected')}: ${additionalData}`}</span>,
      // buttonAdditionalClass: cls['dashed']
    },
  };
  if (type) {
    const buttonTypeData = ButtonTypeConfig[type];
    if (buttonTypeData.buttonAdditionalClass) {
      buttonAdditionalClasses =
        buttonAdditionalClasses + ' ' + buttonTypeData.buttonAdditionalClass;
    }
    children = (
      <div className={`${cls['button__icon-wrapper']} flex-center`}>
        {buttonTypeData.icon}
        {buttonTypeData.text && (
          <span className="align-center">{buttonTypeData.text}</span>
        )}
      </div>
    );
  }

  if (icon) {
    children = <div className={`${cls['button__icon-wrapper']}`}>{icon}</div>;
  }
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      className={`button ${cls['button']} ${
        theme && cls[theme]
      } ${theme} ${buttonAdditionalClasses} ${
        isDisabled && cls['disabled']
      } ${className}`}
      type={buttonType}
    >
      {children}
    </button>
  );
};
