import React from 'react';
import {AsyncSelectEvent, IFilter} from '../Table';
import {
  IOption,
  IOptionValue,
  Select,
  SelectControllerTheme,
  SelectDropdownTheme,
} from '../../Select/Select';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux';
import selectors from 'src/redux/selectors';
import useAsyncSelect, { UseAsyncSelectProps } from 'src/hooks/useAsyncSelect';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';
import { SUPPORT_FRONTEND_PARAM_NAME } from 'src/utils/constans/global';

interface IProps {
  item: IFilter;
  onChange: (
    value: IOptionValue,
    event?: React.MouseEvent,
    asyncData?: AsyncSelectEvent
  ) => void;
  propsValue: string;
  asyncFilter: UseAsyncSelectProps;
  fieldName: string;
}

const AsyncSelectConfig = ({
  item,
  onChange,
  propsValue,
  asyncFilter,
  fieldName,
}: IProps) => {
  const { t: tg } = useTranslation('translation');
  const { params } = useSearchQueryParams({});
  let controllerTheme = SelectControllerTheme.FROM_TABLE;
  if (item.config?.controllerTheme) {
    controllerTheme = item.config.controllerTheme;
  }
  const dropdownTheme = SelectDropdownTheme.FROM_TABLE;
  const asyncSelectData = useAppSelector(selectors.getAsyncSelectData);
  const { searchHandler, options, hasMore, fetchNextPage } = useAsyncSelect({...asyncFilter, isInfinite:true});
  const preSelectedTitle =
    params[asyncFilter.storeFieldName + SUPPORT_FRONTEND_PARAM_NAME];
  const optionsWithAdditional = [...options];
  if (
    !options.find((item) => item.value == propsValue) &&
    !asyncSelectData[fieldName]?.isSearched
  ) {
    optionsWithAdditional.push({
      title: preSelectedTitle,
      value: propsValue,
    });
  }
  const changeHandler = (value: IOptionValue, event?: React.MouseEvent) => {
    onChange(value, event, {
      key: asyncFilter.storeFieldName + SUPPORT_FRONTEND_PARAM_NAME,
      value: options.find((item) => item.value == value)?.title,
    });
  };
  return (
    <div>
      <Select
        controllerTheme={controllerTheme}
        dropdownTheme={dropdownTheme}
        hasSearch
        hasMore={hasMore}
        fetchNextPage={fetchNextPage}
        onSearch={searchHandler}
        onChange={changeHandler}
        propsValue={propsValue}
        disablePortal={false}
        stylesConfig={{
          isWidth100: true,
        }}
        isNoValueAllText
        options={
          [
            {
              title: null,
              value: null,
              element: (
                <div>
                  <span className="hidden-part">{tg('select-all')}</span>
                </div>
              ),
            },
            ...optionsWithAdditional,
          ] as IOption[]
        }
        {...item.config}
      />
    </div>
  );
};

export default AsyncSelectConfig;
