
import { flattenTree } from 'react-accessible-treeview';
// export type IFlatMetadata = Record<string, string | number | undefined | null>;
// interface ITreeNode<M extends IFlatMetadata> {
//     id?: string;
//     name: string;
//     children?: ITreeNode<M>[];
//     metadata?: M;
// }
const recPrepare = (itemsArray:unknown[]) => {
  const res:any[] = [];
  let childrenCount = 0;
  itemsArray.forEach((item:any) => {
    childrenCount=0;
    const children = item.children.length ? recPrepare(item.children) : [];
    // @ts-ignore
    children?.itemsArray?.forEach((item1:any) => {
      childrenCount = childrenCount + item1.metadata.count + 1
    })
    res.push({
      id:item.id,
      name: item.id.toString(),
      metadata:{
        ...item,
        count: childrenCount
      },
      // @ts-ignore
      children: children?.itemsArray
    })
  })
  return {itemsArray:res, count: childrenCount}
}
const getDataForTree = (array:any[]) => {
  
  return flattenTree({
    name: "getDataForTreeName",
    children: recPrepare(array).itemsArray
  })
}

export default getDataForTree