import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { PagListResult } from 'src/models/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../../models/sms';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

export const goSms = createApi({
  reducerPath: 'goSms',
  baseQuery: getBaseQueryForGoLang,
  endpoints: (builder) => ({
    getSmsList: builder.query<PagListResult<I.ISmsItem>, any>({
      query: (params) => ({ url: '/sms/', params: params }),
      transformResponse: (
        data: PagListResult<I.ISmsItem>,
        meta: FetchBaseQueryMeta
      ) => ({
        data: data.data,
        meta: data.meta,
        links: data.links,
        status: meta?.response?.status,
      }),
    }),
  }),
});

export const { useGetSmsListQuery } = goSms;
