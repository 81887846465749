import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { Currency, ListResult } from '../../models/types';

export const currencySlice = createApi({
  reducerPath: 'currency',
  baseQuery: baseQuery,
  tagTypes: ['Currency'],
  endpoints: (builder) => ({
    getCurrency: builder.query<ListResult<Currency>, any>({
      query: (params) => ({
        url: 'currency',
        method: 'GET',
        params,
      }),
      providesTags: ['Currency'],
    }),
    postCurrency: builder.mutation({
      query: (params) => ({
        url: 'currency',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Currency'],
    }),
    updateCurrency: builder.mutation({
      query: ({ id, params }) => ({
        url: `currency/${id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Currency'],
    }),
    deleteCurrency: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'currency/delete',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Currency'],
    }),
  }),
});

export const {
  useGetCurrencyQuery,
  useDeleteCurrencyMutation,
  usePostCurrencyMutation,
  useUpdateCurrencyMutation,
} = currencySlice;
