import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { Attribute } from '../../models/attributes';
import { PagListResult } from '../../models/types';

type AttributeParams = {
  name: string;
  category_id: number;
  is_active: boolean;
};

export const attributesSlice = createApi({
  reducerPath: 'attributes',
  baseQuery: baseQuery,
  tagTypes: ['attributes'],
  endpoints: (builder) => ({
    getAttributes: builder.query<PagListResult<Attribute>, any>({
      query: (params) => ({
        url: `attributes/`,
        params,
        method: 'GET',
      }),
      providesTags: ['attributes'],
    }),
    postAttribute: builder.mutation({
      query: (params: AttributeParams) => ({
        url: 'attributes',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['attributes'],
    }),
    updateAttribute: builder.mutation({
      query: (params) => ({
        url: `attributes/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['attributes'],
    }),
    deleteAttribute: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'attributes/delete',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['attributes'],
    }),
  }),
});

export const {
  usePostAttributeMutation,
  useUpdateAttributeMutation,
  useGetAttributesQuery,
  useDeleteAttributeMutation,
} = attributesSlice;
