import React, { useState } from 'react';
import cls from './TariffsPopup.module.scss';

import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import {ReactComponent as ChevronDown1Icon} from 'src/assets/img/icons/chevron-down-1-icon.svg'
import './TarrifsPopup.scss';
import { Select, SelectControllerTheme, SelectDropdownTheme } from 'src/components/UI/Select/Select';
import TarrifsCard from './components/TarrifsCard';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { Checkbox, ICheckboxTypes } from 'src/components/UI/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
interface IProp extends IPopupPropsExport {}
const config = [
  {
    id:"1",
    title: "Test",
    descriptions: ["Необмежена кількість користувачів", "200 замовлень/місяць"],
    normal_price: "4,99 $/міс",
    discount_price: "0,00 $/міс",
  },
  {
    id:"2",
    title: "Start",
    descriptions: ["2 користувача (не враховуючи адміна)", "200 замовлень/місяць"],
    normal_price: "15,99 $/міс",
    discount_price: "10,99 $/міс",
  },
  {
    id:"3",
    title: "Office",
    descriptions: ["Необмежена кількість користувачів", "500 замовлень/місяць"],
    normal_price: "40,99 $/міс",
    discount_price: "25,99 $/міс",
  },
  {
    id:"4",
    title: "Company",
    descriptions: ["Необмежена кількість користувачів", "1200 замовлень/місяць"],
    normal_price: "70,99 $/міс",
    discount_price: "45,99 $/міс",
  },
  {
    id:"5",
    title: "Business",
    descriptions: ["Необмежена кількість користувачів", "10 000 замовлень/місяць"],
    normal_price: "100,99 $/міс",
    discount_price: "75,99 $/міс",
  },
  {
    id:"6",
    title: "Corporation",
    descriptions: ["Необмежена кількість користувачів", "Необмежена кількість замовлень"],
    normal_price: "178,99 $/міс",
    discount_price: "149,99 $/міс",
  },
];


const TarrifsPopup = ({ show, close }: IProp) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tariffs',
  });
  const [compareActive, setCompareActive] = useState<string | null>(null);
  const [selectValue, setSelectValue] = useState<string|number|null>(null)
  return (
    <div className={`tarrifs-popup ${compareActive!==null && "compare-active"}`}>
      <Popup
        show={show}
        close={() => {
         compareActive !== null ? setCompareActive(null) : close();
        }}
        title={''}
        width={ compareActive !== null ? '504px' : '1400px'}
        mobileStyle={{
          fixedWidth:true
        }}
      >
        <div className={`${cls["wrapper"]}`}>
          {
            compareActive 
            ?<>
              <div className={`${cls["cards"]}`}>
                <TarrifsCard status="Поточний тариф" item={config[0]}></TarrifsCard>
                <TarrifsCard status="Новий тариф" item={config.find(item => item.id===compareActive)}></TarrifsCard>
              </div>
              <div className={`flex-center mt-20`}>
                <Checkbox textElement={<span className={cls["checkbox-text"]} >{t('terms-agree')}</span>} type={ICheckboxTypes.SQUARE} />
                
              </div>
              <div className={`flex-center mt-20`}>
                <Button theme={ButtonTheme.PRIMARY}>{t('change-tariff')}</Button>
              </div>
            </>
            :<>
              <div className={`${cls["cards-wrapper"]}`}>
                <div className={`${cls["cards"]}`}>
                  {config.map(item => {
                    return (
                      <TarrifsCard key={item.id} item={item} onClick={ (compareActive || item.id === config[0].id) ? undefined : () => { setCompareActive(item.id)}} />
                    )
                  })}
                </div>
              </div>
              <div className={`${cls["select-wrapper"]}`}>
                <Select
                  controllerTheme={SelectControllerTheme.TRANSPARENT}
                  dropdownTheme={SelectDropdownTheme.FULL_BORDERED}
                  className={cls["select"]}
                  stylesConfig={{
                    isNoFixedArrowPosition: true,
                  }}
                  propsValue={selectValue}
                  onChange={(value) => {setSelectValue(value)}}
                  hideSelectedItem={true}
                  options={[
                    {
                      title: null,
                      element: <div className="flex-center">{t('month')}</div>,
                      value: null
                    },
                    {
                      title:null,
                      element:<div className="flex-center">{t('year')} <div className={`hidden-part ${cls['select__discount']}`}>-20 %</div></div>,
                      value: "1"
                    }
                  ]}
                  customIconArrow={<ChevronDown1Icon className={cls["select__dropdown-icon"]}></ChevronDown1Icon>}
                ></Select>
              </div>
            </>
          }
        </div>
      </Popup>
    </div>
  );
};
export default TarrifsPopup