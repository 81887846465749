import React, { FC, useEffect, useState } from 'react';
import cls from './NotificationItem.module.scss';
import {
  Notification,
  NOTIFICATIONS_TYPES_ENUM,
} from 'src/models/notifications';
import { ReactComponent as ClockIcon } from 'src/assets/img/icons/notifications/yellow-clock-icon.svg';
import { ReactComponent as MindIcon } from 'src/assets/img/icons/notifications/mind-icon.svg';
import { ReactComponent as CloseIcon } from 'src/assets/img/icons/popup-close-icon.svg';
import useSounds from 'src/hooks/useSounds';
import {
  usePostNotificationDoneMutation,
  usePostNotificationExtendMutation,
} from 'src/services/notification';
import { useTranslation } from 'react-i18next';
import { useUpdateNotificationEventMutation } from 'src/services/pytonSSE';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { toast } from 'react-toastify';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { onNotificationDone } from 'src/redux/slices/notificationsSlice';

interface AlertProps {
  notification: Notification | null;
  onClose: () => void;
}

const NotificationAlert: FC<AlertProps> = ({ notification, onClose }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification-alert',
  });
  const timeList = [
    { title: t('5-min'), value: 5 },
    { title: t('10-min'), value: 10 },
    { title: t('15-min'), value: 15 },
    { title: t('30-min'), value: 30 },
    { title: t('1-hour'), value: 60 },
  ];
  const { playAlertSound } = useSounds();
  const [extend] = usePostNotificationExtendMutation();
  const [done] = usePostNotificationDoneMutation();
  const [updateEvent] = useUpdateNotificationEventMutation();
  const [time, setTime] = useState<string>('5');
  const userLogin = useTypedSelector(selectors.getUserName);

  const onExtend = async () => {
    await extend({ id: notification?.id, minutes: time, sent: false })
      .unwrap()
      .then(() =>
        updateEvent({
          notification_id: notification?.id,
          newUserAgentLogin: userLogin,
        })
      );
    return onClose();
  };
  const dispatch = useTypedDispatch();

  const onDone = async () => {
    await done({ id: notification?.id })
      .unwrap()
      .then(() => dispatch(onNotificationDone(notification?.id)))
      .catch((e) => toast.error(e?.data?.message || 'error'));
    return onClose();
  };
  const onCloseNotification = async () => {
    await extend({ id: notification?.id, minutes: 1, sent: false })
      .unwrap()
      .then(() =>
        updateEvent({
          notification_id: notification?.id,
          newUserAgentLogin: userLogin,
        })
      );

    return onClose();
  };

  useEffect(() => {
    playAlertSound();
  }, []);

  return (
    <div className={`alert-notification-wrapper ${cls['alert-container']}`}>
      <ClockIcon className={'ml-10'} />
      <div className={cls['inner']}>
        <div className={'justify-between width-full'}>
          <h4>{t('notification')}</h4>
          <CloseIcon
            className={cls['close-icon']}
            onClick={onCloseNotification}
          />
        </div>

        <p>
          {notification?.type === NOTIFICATIONS_TYPES_ENUM.NEW_ORDER_RECALL
            ? t('recall')
            : t('ordinary')}
        </p>
        <div className={cls['message-inner']}>
          <MindIcon /> <span>{notification?.description}</span>
        </div>

        <div>
          <button onClick={onDone} style={{ backgroundColor: '#75B83F' }}>
            {t('completed')}
          </button>{' '}
          <span>{notification?.date_time}</span>
        </div>
        <div>
          <button style={{ backgroundColor: '#999999' }} onClick={onExtend}>
            {t('move-to')}
          </button>
          <select onChange={(e) => setTime(e.target.value)}>
            {timeList.map((i) => (
              <option key={i.value} value={i.value}>
                {i.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default NotificationAlert;
