import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { getAuthTokens } from './helpers/authHelper';
import { PAGES_ENUM } from '../routes';

enum Headers {
  Authorization = 'authorization',
  Accept = 'Accept',
}
export const getTenant = () =>
  process.env.NODE_ENV !== 'development'
    ? window.location.hostname.split('.')[0]
    : process.env.REACT_APP_DEV_TENANT;

export const getBaseUrl = (isForGoLang?: boolean) => {
  const hostName = window.location.hostname.split('.');
  return process.env.NODE_ENV !== 'development'
    ? `${window.location.protocol}//${hostName[0]}.${
        isForGoLang ? 'go' : 'api'
      }${process.env.REACT_APP_DOMAIN}/`
    : `http://${process.env.REACT_APP_DEV_TENANT}.${
        isForGoLang ? 'go' : 'api'
      }${process.env.REACT_APP_DOMAIN}/`;
};
const getParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();

  for (const param in params) {
    if (Array.isArray(params[param])) {
      params[param].forEach((value: any) => {
        queryParams.append(param, value.toString());
      });
    } else {
      queryParams.set(param, params[param].toString());
    }
  }

  return queryParams.toString();
};
const getPrepareHeader = (headers: any) => {
  const token = getAuthTokens().accessToken;
  if (token) {
    headers.set(Headers.Authorization, `Bearer ${token}`);
  }
  headers.set(Headers.Accept, 'application/json');
  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  paramsSerializer: (params) => getParamsSerializer(params),
  prepareHeaders: (headers) => getPrepareHeader(headers),
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.location.pathname = PAGES_ENUM.SIGN_IN;
    document.cookie =
      'accessTokenCrm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  return result;
};
const baseQueryForGoLang = fetchBaseQuery({
  baseUrl: getBaseUrl(true),
  paramsSerializer: (params) => getParamsSerializer(params),
  prepareHeaders: (headers) => getPrepareHeader(headers),
});
const baseQueryForPython = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV !== 'development'
      ? `https://live${process.env.REACT_APP_DOMAIN}/sse/`
      : 'https://live.promt-mkt.com/sse/',
  paramsSerializer: (params) => getParamsSerializer(params),
  prepareHeaders: (headers) => getPrepareHeader(headers),
});
const baseQueryForOrderPython = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV !== 'development'
      ? `https://open-api${process.env.REACT_APP_DOMAIN}/sse/`
      : 'https://open-api.promt-mkt.com/sse/',
  paramsSerializer: (params) => getParamsSerializer(params),
  prepareHeaders: (headers) => getPrepareHeader(headers),
});

const getBaseQueryForGoLang: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQueryForGoLang(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.location.pathname = PAGES_ENUM.SIGN_IN;
    document.cookie =
      'accessTokenCrm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  return result;
};
const getBaseQueryForPython: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQueryForPython(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.location.pathname = PAGES_ENUM.SIGN_IN;
    document.cookie =
      'accessTokenCrm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  return result;
};
const getBaseQueryForOrderPython: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQueryForOrderPython(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.location.pathname = PAGES_ENUM.SIGN_IN;
    document.cookie =
      'accessTokenCrm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  return result;
};

export {
  baseQueryWithReAuth as baseQuery,
  getBaseQueryForGoLang,
  getBaseQueryForPython,
  getBaseQueryForOrderPython,
};
