import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBranchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      d="M11.333 4.04a2 2 0 0 0-.767 3.847 1.33 1.33 0 0 1-1.233.82H6.666c-.47.002-.93.13-1.333.373V5.253a2 2 0 1 0-1.334 0v5.574a2 2 0 1 0 1.44.033 1.33 1.33 0 0 1 1.227-.82h2.667a2.666 2.666 0 0 0 2.606-2.107 2 2 0 0 0-.606-3.893M4.666 2.707a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333m0 10.666a.666.666 0 1 1 0-1.332.666.666 0 0 1 0 1.332m6.667-6.666a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333"
    />
  </svg>
);
export default SvgBranchIcon;
