import { createApi } from '@reduxjs/toolkit/query/react';
import {
  getBaseQueryForPython,
  getTenant,
  getBaseQueryForOrderPython,
} from '../utils/baseQuery';
import getOC from '../utils/helpers/getOC';

type OrderToggleParams = {
  user: number;
  order: number;
  action_type: 'close' | 'open';
};

export const pythonSSEOrders = createApi({
  reducerPath: 'pythonSSEOrders',
  baseQuery: getBaseQueryForOrderPython,
  endpoints: (builder) => ({
    postOrderToggle: builder.mutation<any, any>({
      query: ({ user, order, action_type }: OrderToggleParams) => ({
        url: 'open-close-order/',
        method: 'POST',
        body: { user, order, tenant_id: getTenant(), action_type },
      }),
    }),
  }),
});
export const pythonSSE = createApi({
  reducerPath: 'pythonSSE',
  baseQuery: getBaseQueryForPython,
  endpoints: (builder) => ({
    loginEvent: builder.mutation<any, any>({
      query: ({ newUserAgentLogin, user_id }) => ({
        url: 'login-event/',
        method: 'POST',
        body: {
          user_id,
          newUserAgentLogin,
          tenant_id: getTenant(),
          device_os: getOC(),
        },
      }),
    }),
    createNotificationEvent: builder.mutation<any, any>({
      query: ({ newUserAgentLogin, notification_id }) => ({
        url: 'notification/create',
        method: 'POST',
        body: {
          notification_id,
          newUserAgentLogin,
          tenant_id: getTenant(),
          device_os: getOC(),
        },
      }),
    }),
    updateNotificationEvent: builder.mutation<any, any>({
      query: ({ newUserAgentLogin, notification_id }) => ({
        url: 'notification/update',
        method: 'PATCH',
        body: {
          notification_id,
          newUserAgentLogin,
          tenant_id: getTenant(),
          device_os: getOC(),
        },
      }),
    }),
    deleteNotificationEvent: builder.mutation<any, any>({
      query: ({ newUserAgentLogin, notification_id }) => ({
        url: 'notification/delete',
        method: 'POST',
        body: {
          notification_id,
          newUserAgentLogin,
          tenant_id: getTenant(),
          device_os: getOC(),
        },
      }),
    }),
  }),
});

export const {
  useLoginEventMutation,
  useCreateNotificationEventMutation,
  useDeleteNotificationEventMutation,
  useUpdateNotificationEventMutation,
} = pythonSSE;
export const { usePostOrderToggleMutation } = pythonSSEOrders;
