import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGearCancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="#3152e7"
      d="M9.5 13.063A3.562 3.562 0 1 1 13.063 9.5 3.523 3.523 0 0 1 9.5 13.063m0-5.938A2.323 2.323 0 0 0 7.125 9.5 2.32 2.32 0 0 0 9.5 11.875 2.32 2.32 0 0 0 11.875 9.5 2.32 2.32 0 0 0 9.5 7.125"
    />
    <path
      fill="#3152e7"
      d="M17.4 6.557 15.997 4.13a1.186 1.186 0 0 0-1.41-.53l-1.444.488a7 7 0 0 0-.78-.45l-.299-1.496a1.19 1.19 0 0 0-1.164-.954H8.098a1.19 1.19 0 0 0-1.164.954l-.3 1.496q-.409.196-.787.447L4.41 3.599a1.186 1.186 0 0 0-1.408.531L1.6 6.557a1.19 1.19 0 0 0 .245 1.486L2.99 9.051c-.01.149-.023.297-.023.449q0 .229.017.455l-1.14 1.002a1.19 1.19 0 0 0-.245 1.486l1.402 2.427a1.186 1.186 0 0 0 1.408.531l1.446-.489q.374.252.779.45l.299 1.496a1.19 1.19 0 0 0 1.164.954h2.59v-1.187h-2.59l-.421-2.108a5.4 5.4 0 0 1-1.6-.933l-2.047.692-1.401-2.427 1.618-1.422a5.3 5.3 0 0 1-.004-1.857L2.628 7.15 4.03 4.724l2.035.688a5.4 5.4 0 0 1 1.611-.929l.422-2.108h2.803l.421 2.108a5.4 5.4 0 0 1 1.6.933l2.047-.692L16.37 7.15 14.71 8.607l.783.893 1.662-1.457a1.19 1.19 0 0 0 .245-1.486"
    />
    <path
      fill="#DD1818"
      d="m12.677 12.153.042-.049a.434.434 0 0 1 .564-.041l.049.041 1.717 1.717 1.717-1.716a.434.434 0 0 1 .613.613l-1.717 1.717 1.717 1.716a.434.434 0 0 1 .042.565l-.042.048a.434.434 0 0 1-.565.042l-.049-.042-1.716-1.716-1.717 1.716a.434.434 0 0 1-.613-.613l1.716-1.716-1.716-1.717a.434.434 0 0 1-.042-.565"
    />
  </svg>
);
export default SvgGearCancelIcon;
