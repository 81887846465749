import React, { useEffect, useState } from 'react';
import cls from './UserSettingsPopup.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginIcon } from 'src/assets/img/icons/header/login-icon.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/img/icons/header/logout-icon.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/img/icons/header/error-icon.svg';
import { ReactComponent as ApproveIcon } from 'src/assets/img/icons/header/approve-icon.svg';
import { ReactComponent as WarningIcon } from 'src/assets/img/icons/header/warning-icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/img/icons/header/info-icon.svg';
import { ReactComponent as RemindIcon } from 'src/assets/img/icons/header/remind-icon.svg';
import { ReactComponent as SwitchIcon } from 'src/assets/img/icons/header/switch-icon.svg';
import { ReactComponent as NotificationIcon } from 'src/assets/img/icons/header/notification-icon.svg';
import { ReactComponent as NewOrderIcon } from 'src/assets/img/icons/header/new-order-icon.svg';
import { ReactComponent as VolumeIcon } from 'src/assets/img/icons/header/volume-icon.svg';
import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { Switch } from 'src/components/UI/Switch/Switch';
import { useUpdateUserSettingsMutation } from 'src/services/users/users';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { toast } from 'react-toastify';
import SoundItem from './components/SoundItem';
import { InfoPopup } from 'src/pages/SettingsSystemPage/components/InfoPopup/InfoPopup';
import CommonUserSettings from './components/CommonUserSettings';
import { setUserSettings } from 'src/redux/Auth';
import { UserSettingsParams } from 'src/models/IAuth';

export interface IProp extends IPopupPropsExport {}

const UserSettingsPopup = ({ show, close }: IProp) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user-settings-popup',
  });

  const userData = useTypedSelector(selectors.getUser);
  const userSounds = userData?.sounds;
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [tab, setTab] = useState<string>('interface');
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
  const [sounds, setSounds] = useState([
    {
      icon: <LoginIcon />,
      title: t('sound-login'),
      src: process.env.PUBLIC_URL + '/sound/LogON.mp3',
      value: userSounds?.login_volume,
      type: 'login_volume',
    },
    {
      icon: <LogoutIcon />,
      title: t('sound-logout'),
      src: process.env.PUBLIC_URL + '/sound/LogOFF.mp3',
      value: userSounds?.logout_volume,
      type: 'logout_volume',
    },
    {
      icon: <ErrorIcon />,
      title: t('sound-error'),
      src: process.env.PUBLIC_URL + '/sound/ERROR.mp3',
      value: userSounds?.error_volume,
      type: 'error_volume',
    },
    {
      icon: <ApproveIcon />,
      title: t('sound-approve'),
      src: process.env.PUBLIC_URL + '/sound/CONFIRM.mp3',
      value: userSounds?.approve_volume,
      type: 'approve_volume',
    },
    {
      icon: <WarningIcon />,
      title: t('sound-warning'),
      src: process.env.PUBLIC_URL + '/sound/WARNING.mp3',
      value: userSounds?.warning_volume,
      type: 'warning_volume',
    },
    {
      icon: <InfoIcon />,
      title: t('sound-info'),
      src: process.env.PUBLIC_URL + '/sound/INFO.mp3',
      value: userSounds?.info_volume,
      type: 'info_volume',
    },
    {
      icon: <RemindIcon />,
      title: t('sound-remind'),
      src: process.env.PUBLIC_URL + '/sound/ALARM_CLOCK.mp3',
      value: userSounds?.reminder_volume,
      type: 'reminder_volume',
    },
    {
      icon: <SwitchIcon />,
      title: t('sound-switch'),
      src: process.env.PUBLIC_URL + '/sound/BUTTON_SWITCH.mp3',
      value: userSounds?.toggle_volume,
      type: 'toggle_volume',
    },
    {
      icon: <NotificationIcon />,
      title: t('sound-notification'),
      src: process.env.PUBLIC_URL + '/sound/MESSAGE.mp3',
      value: userSounds?.mini_reminder_volume,
      type: 'mini_reminder_volume',
    },
    {
      icon: <NewOrderIcon />,
      title: t('sound-new-order'),
      src: process.env.PUBLIC_URL + '/sound/NewOrder.mp3',
      value: userSounds?.new_order_volume,
      type: 'new_order_volume',
    },
  ]);

  const onChangeSoundVolume = (type: string) => (newValue: number) =>
    setSounds((pre) =>
      pre.map((s) => (s.type === type ? { ...s, value: newValue } : s))
    );

  const [totalVolume, setTotalVolume] = useState(
    userData?.sounds.total_volume || 50
  );
  const [isActiveTotalVolume, setIsActiveTotalVolume] = useState(false);
  const [mutedSound, setMutedSound] = useState(userData?.muted_sound);
  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      crm_language: userData?.crm_language,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    },
  });

  const watchAllFields = watch();
  const dispatch = useTypedDispatch();
  const onUpdateUserSettings = () => {
    const soundObject = sounds.reduce((obj, item) => {
      obj[item.type] = item.value;
      return obj;
    }, {});

    const crm_language = getValues('crm_language');
    const first_name = getValues('first_name');
    const last_name = getValues('last_name');
    if (userData) {
      const params = {
        ...soundObject,
        muted_sound: mutedSound,
        total_volume: totalVolume,
        crm_language: crm_language || userData?.crm_language,
        first_name: first_name || userData?.first_name,
        last_name: last_name || userData?.last_name,
      };

      updateUserSettings(params as UserSettingsParams)
        .unwrap()
        .then(() => {
          setShowInfoPopup(true);
          if (params.crm_language !== userData?.crm_language) {
            return window.location.reload();
          }
          dispatch(setUserSettings(params));
        })
        .catch((e) => toast.error(e?.data?.message || 'update user error'));
      setIsActiveTotalVolume(false);
    }
  };

  useEffect(() => {
    if (!isActiveTotalVolume) return;
    setSounds((pre) => pre.map((s) => ({ ...s, value: totalVolume })));
  }, [totalVolume]);

  useEffect(() => {
    if (userData) {
      setValue('crm_language', userData.crm_language);
      setValue('first_name', userData?.first_name);
      setValue('last_name', userData?.last_name);
      setSounds((pre) =>
        pre.map((s) => ({ ...s, value: userData.sounds[s.type] }))
      );
    }
  }, [userData]);

  return (
    <Popup
      show={show}
      close={close}
      title={t('title')}
      width="470px"
      mobileStyle={{ fixedWidth: tab === 'sounds' }}
    >
      <div
        className={cls['wrapper']}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={cls['tabs']}>
          <div
            className={`${cls['tab-item']} ${
              tab === 'interface' && cls['active']
            }`}
            onClick={() => {
              setTab('interface');
            }}
          >
            {t('general-tab')}
          </div>
          <div
            className={`${cls['tab-item']} ${
              tab === 'sounds' && cls['active']
            }`}
            onClick={() => {
              setTab('sounds');
            }}
          >
            {t('sounds-tab')}
          </div>
        </div>

        <div className={cls['tab-container']}>
          {tab === 'interface' && (
            <>
              <form
                // onSubmit={handleSubmit(debounce(onSubmit, 200))}
                className={cls['form']}
              >
                <CommonUserSettings
                  t={t}
                  setValue={setValue}
                  register={register}
                  watchAllFields={watchAllFields}
                />
              </form>
            </>
          )}
          {tab === 'sounds' && (
            <>
              <div
                className={`${cls['sound-item']}  ${
                  mutedSound ? cls['disable-total-volume'] : ''
                }`}
              >
                <div className={cls['sound-item__icon']}>
                  <VolumeIcon />
                </div>
                <div className={cls['sound-item__title']}>
                  {t('volume-all-sounds')}
                </div>
                <div className={cls['sound-item__play']}>
                  <Switch
                    value={isActiveTotalVolume}
                    onChange={(val) => setIsActiveTotalVolume(val)}
                  />
                </div>
                <div className={cls['sound-item__slider']}>
                  {/* <div className={cls['sound-item__slider-disabled']}></div> */}
                  <div className={cls['sound-item__slider']}>
                    <input
                      type="range"
                      min="1"
                      max="100"
                      value={totalVolume}
                      onChange={(e) => setTotalVolume(+e.target.value)}
                      disabled={!isActiveTotalVolume}
                    />
                  </div>
                </div>
                <div className={cls['sound-item__value']}>
                  {totalVolume.toFixed(0)}
                </div>
                <div className={cls['sound-item__percent']}>%</div>
              </div>
              <div className="relative">
                <div
                  className={`${cls['sound-items']} ${
                    mutedSound ? cls['disable-volume'] : ''
                  }`}
                >
                  {sounds.map((item, idx) => (
                    <SoundItem
                      key={idx}
                      title={item.title}
                      icon={item.icon}
                      value={
                        isActiveTotalVolume ? totalVolume : item.value || 50
                      }
                      src={item?.src ?? ''}
                      onChange={onChangeSoundVolume(item.type)}
                    />
                  ))}
                </div>
                <div className={mutedSound ? cls['sound-off'] : 'd-none'}>
                  {t('sound-off')}
                </div>
              </div>
              <div className={cls['no-sound-mode__wrapper']}>
                <div className={cls['no-sound-mode']}>{t('no-sound-mode')}</div>
                <Switch
                  value={mutedSound}
                  onChange={(val) => setMutedSound(val)}
                />
              </div>
            </>
          )}
        </div>

        <div className={cls['save-button-wrapper']}>
          <Button onClick={onUpdateUserSettings} theme={ButtonTheme.PRIMARY}>
            {t('button')}
          </Button>
        </div>
      </div>
      {showInfoPopup && (
        <InfoPopup
          message={t('user-settings-save-success-message')}
          show={showInfoPopup}
          close={() => {
            setShowInfoPopup(false);
            close();
          }}
        />
      )}
    </Popup>
  );
};

export default UserSettingsPopup;
