import React, {CSSProperties, MutableRefObject, ReactNode, useEffect, useMemo} from 'react';
import './Table.scss';
import {
  IOption,
  SelectProps,
} from '../Select/Select';
import CSS from 'csstype';
import { IUseTableReturned } from 'src/hooks/useTable';

import {
  BaseQueryFn,
  QueryActionCreatorResult,
  QueryDefinition,
} from '@reduxjs/toolkit/query';
import { DatePickerComponentProps } from '../DatePickerComponent/DatePickerComponent';
import PageDataLoader from '../Loaders/PageDataLoader/PageDataLoader';
import { InputProps } from '../Input/Input';
import TableDataElement from './components/TableDataElement';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import selectors from 'src/redux/selectors';
import { TableVirtuoso } from 'react-virtuoso';
import TableHeader from './components/TableHeader';
import TableDataRow from './components/TableDataRow';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';
import { useTranslation } from 'react-i18next';
import {UseAsyncSelectProps} from "src/hooks/useAsyncSelect";
export interface AsyncSelectEvent {
  key?: string,
  value?: string | number | null
}
export interface IPageTable {
  useTableReturned: IUseTableReturned;
  refetch?: () => QueryActionCreatorResult<
    QueryDefinition<unknown, BaseQueryFn<unknown>, string, unknown>
  >;
  tableRef?: MutableRefObject<any>;
  dataLoading?: boolean;
}
export interface ITableDataItemElement {
  element?: ReactNode;
  text?: string;
  className?: string;
  filterFunction?: (value: any) => boolean;
  sortValue?: any;
  disableComputedTooltip?: boolean;
}
export interface ITableDataItemElements {
  [key: string]: ITableDataItemElement;
}
export type ITableDataItem = {
  data: {
    id: string | number;
    value?: any;
    isDisabled?: boolean;
    style?: CSS.Properties;
    className?: string;
    onDoubleClick?: () => void;
    onClick?: () => void;
  };
  elements: ITableDataItemElements;
};
export enum FilterTypes {
  TEXT = 'text',
  SELECT = 'select',
  DATE = 'date',
  DATE_RANGE = 'date-range',
}
export enum IFilterSelectType {
  COUNTRY = 'country',
  ASYNC = 'async'
}
interface SelectConfig extends Partial<SelectProps>{
  style?: CSSProperties
}
export interface IFilter {
  type: FilterTypes;
  parameterName?: string;
  parameterType?: 'number';
  innerOptionsId?: string;
  selectType?: IFilterSelectType;
  config?: SelectConfig;
  dataConfig?: DatePickerComponentProps;
  inputConfig?: InputProps;
}

export interface IThConfig {
  element: ReactNode | string;
  columnId: string;
  filter?: IFilter | IFilter[];
  icon?: ReactNode;
  width?: number | string;
  minWidth?: number | string;
}
export interface IConfig {
  th: IThConfig[];
  disableFilters?: boolean;
  tableName?: string;
}
export interface IFilterOptions {
  [key: string]: IOption[] | IOption[][];
}
export enum SortFieldTypes {
  ASC = 'asc',
  DESC = 'desc',
}
export interface ISortField {
    columnId: string;
    type: null | SortFieldTypes;
}
export interface ISortFields{
  [key: string]: ISortField | null
}
export interface AsyncFilters {
  [key:string]: UseAsyncSelectProps
}
export interface ITableProps {
  config: IConfig;
  tableData: ITableDataItem[];
  dataLoading?: boolean;
  filterOptions?: IFilterOptions;
  asyncFilters?: AsyncFilters;
  className?: string;
  useTableReturned?: IUseTableReturned;
  disableNoWrap?: boolean;
  tableRef?: MutableRefObject<any>;
  hasSort?: boolean;
  rowHeight?: 'disabled' | number;
}
export const baseRowHeight = 22;
export const defaultTableName = 'main'
const errorListener = (e: ErrorEvent) => {
  if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
    );
    const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
}
export const Table = ({
  config,
  tableData,
  filterOptions,
  asyncFilters,
  className = '',
  useTableReturned,
  disableNoWrap,
  tableRef,
  hasSort,
  dataLoading,
  rowHeight,
}: ITableProps) => {
  if(!config.tableName){
    config.tableName = 'main'
  };
  const { params } = useSearchQueryParams({});
  const { t: tg } = useTranslation();
  const sortFields = useTypedSelector(selectors.getSortFields);
  useEffect(() => {
    window.addEventListener('error', errorListener);
    return () => {
      window.removeEventListener('error', errorListener);
    }
  }, []);
  const tableDataSorted = useMemo(() => {
    const sortField = sortFields?.[config.tableName || defaultTableName];
    if (!hasSort || !sortField) return tableData;
    const result = [...tableData].sort((a, b) => {
      const aData = a?.elements[sortField.columnId]?.sortValue;
      const bData = b?.elements[sortField.columnId]?.sortValue;
      let res = 0;
      if ( !aData ){
        return 1
      }
      if( !bData ){
        return -1
      }
      if (aData < bData) {
        res = -1;
      } else if (aData > bData) {
        res = 1;
      }
      return sortField.type === SortFieldTypes.ASC ? res : res * -1;
    });
    return result;
  }, [tableData, hasSort, sortFields]);

  useEffect(() => {
    useTableReturned?.setTableDataIds(tableDataSorted.map((item) => item.data.id));
  }, [tableDataSorted, useTableReturned?.setTableDataIds]);
  const keyRowHeightRecalc = useMemo(
    () =>
      (rowHeight ? rowHeight.toString() : '0') + (disableNoWrap ? '0' : '1'),
    [rowHeight, disableNoWrap]
  );
  const tableComponents = useMemo(() => {
    return {
      TableRow: (props: any) => (
        <TableDataRow
          props={props}
          rowHeight={rowHeight}
          tableDataSorted={tableDataSorted}
          toggleTableSelectedRowsId={
            useTableReturned?.toggleTableSelectedRowsId
          }
        />
      ),
    };
  }, [tableDataSorted, useTableReturned?.toggleTableSelectedRowsId]);
  return (
    <div
      className={`table-wrapper ${className} table ${
        disableNoWrap ? 'table-disable-nowrap' : ''
      }`}
      ref={tableRef}
    >
      <TableVirtuoso
        key={keyRowHeightRecalc}
        totalCount={
          dataLoading || !tableData?.length ? 1 : tableDataSorted.length
        }
        increaseViewportBy={1000}
        // {...(disableNoWrap || rowHeight === 'disabled'
        //   ? {}
        //   : { fixedItemHeight: rowHeight || baseRowHeight })}
        // {...(disableNoWrap || rowHeight === 'disabled'
        //   ? {}
        //   : { defaultItemHeight: rowHeight || baseRowHeight })}
        fixedHeaderContent={() => {
          return (
            <TableHeader
              config={config}
              tableData={tableData}
              filterOptions={filterOptions}
              asyncFilters={asyncFilters}
              useTableReturned={useTableReturned}
              hasSort={hasSort}
            />
          )
        }}
        components={tableComponents}
        itemContent={(index) => {
          if (dataLoading) {
            return  (
              <td colSpan={config.th.length}>
                <PageDataLoader />
              </td>
            )
          } else if (!tableData.length) {
            return (
              <td colSpan={config.th.length}>
                {Object.values(params || {}).length
                  ? tg('table-no-data-filter')
                  : tg('table-no-data')}
              </td>
            );
          } else {
            const tableDataItem = tableDataSorted[index];
            return (
              <>
                {config.th.map((thConfig, thConfigIndex) => (
                  <TableDataElement
                    key={thConfigIndex}
                    thConfig={thConfig}
                    tableDataItem={tableDataItem}
                    thConfigIndex={thConfigIndex}
                  />
                ))}
              </>
            );
          }
        }}
      />
    </div>
  );
};
