import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import {ListResult} from "src/models/types";
import {ClientGroup, ClientGroupBody, ClientsGroupCount, Client, ClientBody} from "src/models/clients";

export const clientsApi = createApi({
  reducerPath: 'clients',
  baseQuery,
  tagTypes: ['Clients', 'ClientGroups'],
  endpoints: (builder) => ({
    getClientGroups: builder.query<ListResult<ClientGroup>, any>({
      query: (params) => ({
        url: '/clientGroups/',
        params,
      }),
      providesTags: ['ClientGroups'],
    }),

    createClientGroup: builder.mutation<any, ClientGroupBody>({
      query: (body) => ({
        url: '/clientGroups/',
        body,
        method: 'POST',
      }),
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),
    updateClientGroup: builder.mutation<any, { id:number, body: ClientGroupBody }>({
      query: ({id, body}) => ({
        url: `/clientGroups/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),
    deleteClientGroup: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/clientGroups/delete',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),

    getClientList: builder.query<ListResult<Client>, unknown>({
      query: (params: object) => ({ url: '/clients', params }),
      providesTags: ['ClientGroups', 'Clients'],
    }),

    createClient: builder.mutation<any, ClientBody>({
      query: ( body ) => ({
        url: '/clients/',
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation<any, {id: number, body: ClientBody}>({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Clients'],
    }),
    deleteClient: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/client/delete/',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['Clients'],
    }),
    postSMSClients: builder.mutation({
      query: (params) => ({
        url: 'client/sendSms',
        method: 'POST',
        body: params,
      }),
    }),
    getClientGroupsCount: builder.query<ListResult<ClientsGroupCount>, any>({
      query: (params) => ({
        url: '/clientGroups/count',
        params,
      }),
      providesTags: ['ClientGroups', 'Clients'],
    }),
  }),
});

export const {
  useGetClientGroupsQuery,
  useCreateClientGroupMutation,
  useUpdateClientGroupMutation,
  useDeleteClientGroupMutation,
  useGetClientListQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  usePostSMSClientsMutation,
  useGetClientGroupsCountQuery,
} = clientsApi;
