import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import { Product } from 'src/models/products';
import { PagListResult } from 'src/models/types';

export type ProductParams = {
  name: string;
  full_name: string;
  model: string;
  vendorCode: string;
  category_id: number;
  consignment_price: number;
  price: number;
  currency_id: number;
  country: number;
  purchase_price: number;
  provider_id: number;
  description: string | null;
  sending_description: string;
  discount_percent: string;
  colors_ids: Array<string>;
  offices_ids: Array<string>;
  attributes_ids: Array<string>;
  image?: File | string | null;
  available_amount: number;
  producer_id: number;
  isDescriptionHide: boolean;
  available?: boolean;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;
};

export const productsSlice = createApi({
  reducerPath: 'products',
  baseQuery: baseQuery,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProductFromDeletedOrders: builder.query<PagListResult<Product>, any>({
      query: () => '/products/deleted/all',
    }),
    postProduct: builder.mutation({
      query: (params: any) => ({
        url: 'products',
        method: 'POST',
        body: params,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, params }) => ({
        url: `products/${id}/`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: 'products/delete/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  usePostProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetProductFromDeletedOrdersQuery,
} = productsSlice;
