import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/utils/baseQuery';
import {
  UserSettingsParams,
  UserUpdatePasswordPayload,
} from 'src/models/IAuth';

type RoleParams = {
  description: null | string;
  id: number;
  name: string;
  offices_ids: Array<number>;
};

export type RegistrationParams = {
  first_name: string;
  last_name: string;
  email: string;
  crm_language: string;
  cabinet_name: string;
  domain_prefix: string;
  logo: File;
  password: string;
  password_confirmation: string;
  phone: string;
};

export const usersSlice = createApi({
  reducerPath: 'users',
  baseQuery: baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    postUser: builder.mutation({
      query: (params: RoleParams) => ({
        url: 'register/user',
        method: 'POST',
        body: params,
      }),
    }),
    postNewCabinet: builder.mutation({
      query: (params: any) => ({
        url: 'register',
        method: 'POST',
        body: params,
      }),
    }),
    updateUser: builder.mutation({
      query: (params) => ({
        url: `users/${params.id}/`,
        method: 'PUT',
        body: params,
      }),
    }),
    deleteUser: builder.mutation({
      query: (params: { ids: Array<number | string> }) => ({
        url: '/users/multi',
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['User'],
    }),
    sendResetPasswordLink: builder.mutation({
      query: (params: { email: string }) => ({
        url: 'password/forget',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['User'],
    }),
    passwordUpdate: builder.mutation({
      query: (params: UserUpdatePasswordPayload) => ({
        url: 'password/update',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['User'],
    }),

    updateUserSettings: builder.mutation<any, UserSettingsParams>({
      query: (body) => ({
        url: 'users/settings/',
        method: 'PUT',
        body,
      }),
      // invalidatesTags: ['User'],
    }),
  }),
});

export const {
  usePostUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserSettingsMutation,
  useSendResetPasswordLinkMutation,
  usePasswordUpdateMutation,
} = usersSlice;
