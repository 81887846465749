import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={32}
    fill="none"
    viewBox="0 0 33 32"
    {...props}
  >
    <path
      fill="#000"
      d="M15.94 3.983a11.3 11.3 0 0 0-5.032 1.201c-2.67 1.332-4.815 3.399-5.998 6.143-.088.204-.14.395-.245.734-.21.688-.48 1.723-1.487 1.382-.707-.24-.607-1.189-.39-1.754q.405-1.058 1.124-2.456a11.2 11.2 0 0 1 1.512-2.26 13.7 13.7 0 0 1 3.762-3.004q2.316-1.22 4.221-1.552 5.426-.94 10.175 1.855 1.486.876 2.646 2.084c.415.429.361 1.217-.286 1.429q-.626.204-1.097-.253c-2.304-2.238-5.659-3.588-8.904-3.549"
    />
    <path
      fill="#3152E7"
      d="M29.595 21.507q-.22.471-.45.93a13.1 13.1 0 0 1-2.666 3.62 19 19 0 0 1-2.852 2.276l-.745-1.224a.11.11 0 0 1 .003-.125c.062-.094.16-.132.13-.24.51-.261.921-.582 1.223-.857 1.32-1.201 2.226-2.128 3.04-3.579q1.65-2.945 1.638-6.398-.105-1.738-.64-3.424-.219-.697-.706-1.618-.413-.78-.819-1.563c-.29-.56.02-1.124.636-1.095q.521.022.852.519a14.6 14.6 0 0 1 1.824 3.921q1.356 4.506-.468 8.856"
    />
    <path
      fill="#000"
      d="M18.833 8.487q2.459.79 3.983 2.694 1.968 2.46 1.777 5.7c-.11 1.867-1.039 3.612-2.216 5.069-.354.439-.88.704-1.441.39-1.105-.615.248-1.814.668-2.383q.403-.543.605-.996c1.005-2.27.724-4.983-1.001-6.817q-1.891-2.01-4.548-2.271c-.869-.086-.99-1.232-.283-1.614.598-.322 1.838.041 2.456.228M8.482 14.743c.252-1.376.77-2.77 1.652-3.82.467-.556.944-1.021 1.626-1.306.726-.303 1.203-.143 1.449.633.124.391-.136.63-.472.848-1.543 1-2.52 2.885-2.598 4.71q-.129 2.995 2.405 5.035c1.102.887 2.3 1.436 3.69 1.506.56.029 1.687-.163 1.642.723-.034.665-.437 1.069-1.19 1.052q-.531-.012-1.617-.202-2.771-.487-4.657-2.522-2.629-2.832-1.93-6.657"
    />
    <path
      fill="#3152E7"
      d="M23.014 26.742a.35.35 0 0 1-.046.302c-.027.04-.033.045-.008.087l.666 1.2a12.4 12.4 0 0 1-4.204 1.524q-.665.112-1.399.216-.624.02-1.283.021-.425 0-1.129-.081c-.506-.058-1.028-.05-1.504-.154-1.316-.288-2.606-.698-3.8-1.32-.526-.272-1.049-.535-1.533-.85q-1.465-.948-2.389-1.883-.768-.775-1.794-2.29a12.7 12.7 0 0 1-1.375-2.635 13.8 13.8 0 0 1-1.087-4.755c-.025-.467-.076-1.014.158-1.385.242-.383.613-.548 1.155-.475.104.014.191.057.25.168.206.382.24.811.263 1.313q-.087 1.713.364 3.153c.282.899.546 1.805.973 2.596q.735 1.353 1.56 2.385.532.666 1.446 1.38.992.777 2.225 1.51a10.7 10.7 0 0 0 2.37 1.054q2.856.87 5.74.465a11 11 0 0 0 3.412-1.033c.289-.143.608-.232.97-.513"
    />
  </svg>
);
export default SvgLogoIcon;
