import React from 'react';
import {
  AsyncFilters,
  IConfig,
  IFilterOptions,
  ITableDataItem,
} from '../Table';
import { IUseTableReturned } from 'src/hooks/useTable';

import TableHeaderTitles from './TableHeaderTitles';
import TableFilters from './TableFilters';
interface IProps {
  config: IConfig;
  filterOptions?: IFilterOptions;
  useTableReturned?: IUseTableReturned;
  hasSort?: boolean;
  tableData: ITableDataItem[];
  asyncFilters?: AsyncFilters;
}

const TableHeader = function ({ config, filterOptions, useTableReturned, hasSort, tableData, asyncFilters }: IProps) {
  return (
    <>
      <TableHeaderTitles
        config={config}
        hasSort={hasSort}
        tableData={tableData}
      />
      { !config.disableFilters &&
        <TableFilters
          config={config}
          filterOptions={filterOptions}
          useTableReturned={useTableReturned}
          asyncFilters={asyncFilters}
        />
      }
    </>
  );
}
export default TableHeader