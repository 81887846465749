import { useLocation, useParams } from 'react-router-dom';

const useOrderCredentials = () => {
  const location = useLocation();
  const { id } = useParams();
  const isCopy = !!location.hash;
  const isUpdate = !!id && !isCopy;

  return { isCopy, orderId: Number(id), isUpdate };
};

export default useOrderCredentials;
