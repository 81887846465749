import React, { useEffect } from 'react';
import cls from './InfoPopup.module.scss';
import { useTranslation } from 'react-i18next';

import { Button, ButtonTheme } from 'src/components/UI/Button/Button';
import { IPopupPropsExport, Popup } from 'src/components/UI/Popup/Popup';
import useSounds from 'src/hooks/useSounds';
export interface IProp extends IPopupPropsExport {}
export const InfoPopup = ({ show, close, message, title }: IProp) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'settings-info-popup',
  });

  const { playInfoSound } = useSounds();

  useEffect(() => {
    playInfoSound();
  }, []);

  return (
    <Popup
      show={show}
      close={close}
      title={title || t('title')}
      width="360px"
      className={cls['info-popup']}
    >
      <div className={cls['wrapper']}>
        <div className={cls['info__wrapper']}>
          <div className={cls['info__image']}>
            <img src={require('src/assets/img/info.png')} />
          </div>
          <div className={cls['info__text']}>{message || t('message')}</div>
        </div>

        <div className="flex-center mt-15">
          <Button
            onClick={close}
            className={cls['button']}
            theme={ButtonTheme.PRIMARY}
          >
            {t('button')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};
